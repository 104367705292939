import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nodeData: {},
  nodeProps: {},
};
const nodeSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    addNodeData: (state, action) => {
      const { nodeId, data } = action.payload;
      const newData = { ...state.nodeData };
      if (!newData[nodeId]) newData[nodeId] = { data: {} };
      newData[nodeId].data = {
        ...newData[nodeId].data,
        ...data,
      };
      state.nodeData = newData;
    },
    addNodeProp: (state, action) => {
      const { nodeId, prop } = action.payload;
      const newProp = { ...state.nodeProps };
      if (!newProp[nodeId]) newProp[nodeId] = { prop: {} };
      newProp[nodeId].prop = {
        ...newProp[nodeId].prop,
        ...prop,
      };
      state.nodeProps = newProp;
    },
    setNodeData: (state, action) => {
      state.nodeData = action.payload;
    },
    setNodeProps: (state, action) => {
      state.nodeProps = action.payload;
    },
    updataNodeProp: (state, action) => {
      const { nodeId, key, data } = action.payload;
      const newProp = { ...state.nodeProps };
      if (!newProp[nodeId]) newProp[nodeId] = { prop: {} };
      newProp[nodeId].prop[key] = data;
      state.nodeProps = newProp;
    },
    clearNodeData: (state, action) => {
      state.nodeData = [];
      state.nodeProps = [];
    },

    //delete a node prop
    deleteNodeProp: (state, action) => {
      const { key } = action.payload;
      const newProp = { ...state.nodeProps };
      const newData = { ...state.nodeData };
      delete newData[key];
      delete newProp[key];
      state.nodeProps = newProp;
      state.nodeData = newData;
    },
  },
});

export const NodesActions = nodeSlice.actions;
export default nodeSlice.reducer;

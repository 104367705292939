import React, { useEffect, useState } from "react";
import "./NavBar.scss";
import Button from "../Button/Button";
import logo from "../../Assets/Images/logo.svg";
import arrow from "../../Assets/Images/arrow.svg";
import home from "../../Assets/Images/home.svg";
import emoji from "../../Assets/Images/emoji.svg";
import notification from "../../Assets/Images/notification.svg";
import help from "../../Assets/Images/help.svg";
import search from "../../Assets/Images/search.svg";
import profile from "../../Assets/Images/profile.svg";
import add from "../../Assets/Images/Add.svg";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import HomePopup from "../../Pages/Home/HomeComponents/HomePopup/HomePopup";
import side_bar from "../../Assets/Images/side-bar.svg";
import inner_add from "../../Assets/Images/inner_add.svg";
import bot from "../../Assets/Images/bot.svg";
import play_bot from "../../Assets/Images/play_bot.svg";
import uppwards_arrow from "../../Assets/Images/uppwards_arrow.svg";
import RunPopup from "../../PageComponents/RunPopup/RunPopup";
import { useDispatch, useSelector } from "react-redux";
import { editBot } from "../../Redux/Home/HomeActions";
import Loader from "../Loader/Loader";

function NavBar() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showRun, setShowRun] = useState(false);

  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const flow = useSelector((state) => state.flow);
  const nodes = useSelector((state) => state.nodes);

  const [saveObject, setSaveObject] = useState({
    nodes: nodes,
    flow: flow,
  });

  useEffect(() => {
    setSaveObject({
      nodes: nodes,
      flow: flow,
    });
  }, [flow, nodes]);

  const { editBotLoading } = useSelector((state) => state.HomeReducer);

  const singleBot = useSelector((state) => state.HomeReducer.bot);

  return (
    <>
      {pathname != "/" && pathname != "/login" ? (
        <div className="navbar_main">
          <div className="left">
            <div className="logo">
              <img src={logo} alt="logo" />
              <img src={arrow} alt="arrow" />
            </div>
            <div
              className="home"
              onClick={() => {
                navigate("/home?tab=bots");
              }}
            >
              <img src={home} alt="home" />
              <div className="text">Home</div>
            </div>
            {pathname != "/home" && pathname != "/" && (
              <>
                <div
                  className="home"
                  onClick={() => {
                    setSearchParams({ show: true });
                  }}
                >
                  <img src={side_bar} alt="side_bar" />
                </div>
                <div className="bot">
                  <img src={bot} alt="bot" style={{ padding: "0 0 0 1rem" }} />
                  <div className="text">Default Bot</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0.5rem ",
                      borderLeft: "2px solid #242424",
                    }}
                  >
                    <img src={inner_add} alt="inner_add" />
                  </div>
                </div>
              </>
            )}
            {pathname == "/home" && (
              <div className="coming_soon">
                <div className="community">
                  <img src={emoji} alt="emoji" className="emoji" />
                  <div className="text">Community</div>
                  <div className="comming_soon">Soon</div>
                </div>
                <div className="activity">
                  <img
                    src={notification}
                    alt="notification"
                    className="notification"
                  />
                  <div className="text">Activity</div>
                  <div className="comming_soon">Soon</div>
                </div>
                <div className="learn">
                  <img src={help} alt="help" className="help" />
                  <div className="text">Learn</div>

                  <div className="comming_soon">Soon</div>
                </div>
              </div>
            )}
          </div>
          {pathname != "/home" && pathname != "/" && (
            <div
              className="middle"
              onDoubleClick={(e) => {
                e.target.contentEditable = true;
                e.target.focus();
              }}
              onBlur={(e) => {
                e.target.contentEditable = false;
                if (e.target.innerText.length == 0) {
                  dispatch(
                    editBot(localStorage.getItem("bot_id"), {
                      name: "Untitled Bot",
                    })
                  );
                } else {
                  dispatch(
                    editBot(localStorage.getItem("bot_id"), {
                      name: e.target.innerText,
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.target.contentEditable = false;
                  // if (e.target.innerText.length == 0) {
                  //   editBot(localStorage.getItem("bot_id"), {
                  //     name: "Untitled Bot",
                  //   });
                  // } else {
                  //   dispatch(
                  //     editBot(localStorage.getItem("bot_id"), {
                  //       name: e.target.innerText,
                  //     })
                  //   );
                  // }
                }
              }}
              style={{
                cursor: "pointer",
                padding: "0.5rem 1rem",
                fontFamily: "Graphie_Bold",
                fontSize: "1.5rem",
              }}
            >
              {singleBot?.name}
            </div>
          )}
          <div className="right">
            <img
              src={pathname == "/home" ? search : play_bot}
              alt="search"
              className="search"
              style={{ cursor: "pointer" }}
              onClick={() => {
                pathname !== "/home" && setShowRun(true);
              }}
            />
            {pathname != "/home" &&
              pathname != "/" &&
              (editBotLoading ? (
                <div
                  style={{
                    padding: "0rem 1rem",
                  }}
                >
                  <Loader width={"5rem"} />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    // setShow(true);
                    dispatch(
                      editBot(localStorage.getItem("bot_id"), {
                        bot: JSON.stringify(saveObject),
                      })
                    );
                  }}
                  id="new_button"
                >
                  {
                    <div
                      style={{
                        fontFamily: "Techead_Bold",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <img
                        src={uppwards_arrow}
                        alt="add"
                        style={{
                          width: "0.75rem",
                        }}
                      />{" "}
                      Save
                    </div>
                  }
                </Button>
              ))}
            <img src={profile} alt="profile" className="profile" />
            {pathname == "/home" && (
              <Button
                onClick={() => {
                  setShow(true);
                }}
                id="new_button"
              >
                <div
                  style={{
                    fontFamily: "Techead_Bold",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <img src={add} alt="add" /> New
                </div>
              </Button>
            )}
          </div>
          <HomePopup show={show} setShow={setShow} />
          <RunPopup show={showRun} setShow={setShowRun} />
        </div>
      ) : null}
    </>
  );
}

export default NavBar;

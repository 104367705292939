import React, { useEffect, useState } from "react";
import "./FaqBox.scss";
import arrow from "../../../../Assets/Images/arrowDown.svg";
import gsap from "gsap";
function FaqBox({ id, title, subTile, clicked, setClicked }) {
  useEffect(() => {
    if (clicked == id) {
      gsap.to(`.faqBox_main_div_${id}`, {
        duration: 0,
        border: "2px solid var(--orange) ",
      });
      gsap.to(`.faqBox_title_${id}`, {
        duration: 0,
        color: "var(--orange)",
        opacity: 1,
        marginBottom: "1.2rem",
      });
      gsap.to(`.arrow_down_${id}`, {
        duration: 0.5,
        transform: "rotateX(180deg)",
      });
      gsap.to(`.faqBox_subTitle_${id}`, {
        duration: 0.5,
        height: "auto",
        visibility: "visible",
      });
    } else {
      gsap.to(`.faqBox_main_div_${id}`, {
        duration: 0,
        border: "2px solid transparent",
      });
      gsap.to(`.faqBox_title_${id}`, {
        duration: 0,
        color: "white",
        opacity: 0.75,
        marginBottom: "0",
      });
      gsap.to(`.arrow_down_${id}`, {
        duration: 0.5,
        transform: "rotateX(0deg)",
      });

      gsap.to(`.faqBox_subTitle_${id}`, {
        duration: 0.5,
        height: "0vh",
        visibility: "hidden",
        padding: "0px 0px",
        margin: "0px 0px",
      });
    }
  }, [clicked]);

  return (
    <div
      className={`faqBox_main_div faqBox_main_div_${id}`}
      onClick={() => {
        clicked == id ? setClicked(-1) : setClicked(id);
      }}
    >
      <div className={`faqBox_title faqBox_title_${id}`}>
        {title}
        <img
          src={arrow}
          alt="arrow"
          className={`arrow_down arrow_down_${id}`}
        />
      </div>
      {<div className={`faqBox_subTitle faqBox_subTitle_${id}`}>{subTile}</div>}
    </div>
  );
}

export default FaqBox;

import { applyEdgeChanges } from "reactflow";

import {
  addNewEdge,
  updateEdges,
  updateNodes,
  removeEdge,
  onUpdateEdge,
  onAddNode,
  addConnectionList,
  removeConnectionList,
  CleanNodesAndEdges,
  addOrder,
  changeAccess,
} from "./FlowReducer";
import { deleteNodeProp } from "../Nodes/NodesActions";

export const handleNodesChange = (changes) => async (dispatch) => {
  changes.forEach((el) => {
    if (el.type == "remove") {
      dispatch(deleteNodeProp(el.id));
    }
  });
  dispatch(updateNodes(changes));
};

export const handleEdgesChange = (changes, edges) => async (dispatch) => {
  changes.forEach((change) => {
    if (change.type == "remove") {
      const newchanges = [{ ...change, type: "selected" }];
      const newEdge = applyEdgeChanges(newchanges, edges);
      const { source, target } = newEdge.filter(
        (el) => el.id == newchanges[0].id
      )[0];
      dispatch(removeConnectionList({ source, target }));
    } else {
      const newchanges = [{ ...change, type: "selected" }];
      if (newchanges[0] && newchanges[0].item) {
        const newEdge = applyEdgeChanges(newchanges, edges);
        const { source, target } = newchanges[0]?.item;
        dispatch(addConnectionList({ source, target }));
      }
    }
  });

  dispatch(updateEdges(changes));
};

export const handleConnect = (connection) => async (dispatch) => {
  const { source, target } = connection;
  dispatch(addNewEdge(connection));
  dispatch(addConnectionList({ source, target }));
};

export const handleNodesUpdate = (changes) => async (dispatch) => {
  dispatch(onAddNode(changes));
};

export const handleEdgesUpdate =
  (oldEdge, newConnection) => async (dispatch) => {
    const { source, target } = oldEdge;
    dispatch(removeConnectionList({ source, target }));

    const { source: newSource, target: newTarget } = newConnection;
    dispatch(addConnectionList({ source: newSource, target: newTarget }));
    dispatch(onUpdateEdge({ oldEdge, newConnection }));
  };

export const handleEdgesDelete = (changes) => async (dispatch) => {
  const { source, target } = changes;
  dispatch(removeConnectionList({ source, target }));

  dispatch(removeEdge(changes));
};

export const handleCleanFlow = () => async (dispatch) => {
  dispatch(CleanNodesAndEdges());
};

export const addOrders = (order) => async (dispatch) => {
  dispatch(addOrder(order));
};

export const changeAccesss = (password,navigate) => async (dispatch) => {
  if (password === "hg1dc91h") {
    dispatch(changeAccess(true));
    navigate("/panel");
  } else dispatch(changeAccess(false));
};

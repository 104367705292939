//create a custome made checkbox that look like an empty box when not selecterd and a orange box when selected
//
const Checkbox = ({ checked, onChange }) => {
  return (
    <div
      style={{
        width: "1rem",
        height: "1rem",
        border: checked ? "2px solid var(--red)" : "2px solid #707070",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: checked ? "#FFA500" : "transparent",
      }}
      onClick={onChange}
    >
      {checked && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "var(--red)",
          }}
        ></div>
      )}
    </div>
  );
};

export default Checkbox;

import React from "react";
import "./Button.scss";

function Button({ children, onClick, id }) {
  return (
    <button id={id} className="button_main" onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;

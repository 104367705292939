import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //execute order
  executeOrderLoading: false,
  executeOrderError: "",
  executeOrderSuccess: false,
};

const OrderNodeSlice = createSlice({
  name: "Order Node",
  initialState,
  reducers: {
    //execute order
    executeOrderRequest: (state) => {
      state.executeOrderLoading = true;
      state.executeOrderError = "";
      state.executeOrderSuccess = false;
    },

    executeOrderSuccess: (state, action) => {
      state.executeOrderLoading = false;
      state.executeOrderError = "";
      state.executeOrderSuccess = true;
    },

    executeOrderError: (state, action) => {
      state.executeOrderLoading = false;
      state.executeOrderError = action.payload;
      state.executeOrderSuccess = false;
    },
  },
});

export const OrderNodeActions = OrderNodeSlice.actions;
export default OrderNodeSlice.reducer;

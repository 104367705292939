import React, { useState } from "react";
import "./Home.scss";
import NavBar from "../../Components/NavBar/NavBar";
import SideBar from "../../Components/SideBar/SideBar";
import Bots from "./HomeComponents/Bots/Bots";
import HomePopup from "./HomeComponents/HomePopup/HomePopup";
import { useSearchParams } from "react-router-dom";
import Trades from "./HomeComponents/Trades/Trades";

function Home() {
  const [show, setShow] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="home_main">
      <div className="home_main_inner">
        <SideBar />
        {searchParams.get("tab") === "bots" ? <Bots /> : <Trades />}
      </div>
    </div>
  );
}

export default Home;

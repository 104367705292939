import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import "./BBNode.scss";
import { BBData } from "./BBData";
import { getBBLiveValues, getBBValues } from "../../Redux/BBNode/BBNodeActions";
import { socketIo } from "../../App";
function BBNode() {
  const [period, setPeriod] = useState();
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const [title, setTitle] = useState("BB Node");
  const [stdv, setStdv] = useState();
  const nodeId = useNodeId();
  const dispatch = useDispatch();
  const node = getNode(nodeId);
  const [pricesDisabled, setPricesDisabled] = useState(false);
  const [periodDisabled, setPeriodDisabled] = useState(false);
  const [stdvDisabeled, setStdvDisabeled] = useState(false);
  const [upper, setUpper] = useState();
  const [lower, setLower] = useState();
  const [middle, setMiddle] = useState();
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const [connectedEdges, setConnectedEdges] = useState([]);
  const { edges } = useSelector((state) => state.flow);
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const [source, setSource] = useState();
  const [assetId, setAssetId] = useState();
  const [sourceId, setSourceId] = useState();

  const [lowerArray, setLowerArray] = useState([]);
  const [middleArray, setMiddleArray] = useState([]);
  const [upperArray, setUpperArray] = useState([]);
  const { bbValues, bbLiveValue } = useSelector((state) => state.BBNodeReducer);
  const { historicalData } = useSelector((state) => state.assetNodeReducer);

  //step 1 setting the props
  useEffect(() => {
    var periodHtml = document.getElementById("bb_period");
    var stdvHtml = document.getElementById("stdv_value");
    var upperHtml = document.getElementById("bb_upper");
    var lowerHtml = document.getElementById("bb_lower");
    var middleHtml = document.getElementById("bb_middle");

    var periodHtmlValue = periodHtml?.outerHTML;

    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          period: periodHtmlValue,
          upper: upperHtml?.outerHTML,
          lower: lowerHtml?.outerHTML,
          middle: middleHtml?.outerHTML,
          stdv: stdvHtml?.outerHTML,
        },
        period: period,
        stdv: stdv,
        upper: upper?.toFixed(2),
        middle: middle?.toFixed(2),
        lower: lower?.toFixed(2),
        label: title,
        info: {
          ...BBData,
        },
      })
    );
    // dispatch(
    //   nodeDataUpdate(nodeId, {
    //     [nodeId + "_rsi_output_1/number"]: RSIFinal?.toFixed(2),
    //   })
    // );
  }, [period, title, stdv, upper, lower, middle]);

  //step 2 getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.period != period) {
        setPeriod(nodeProps[nodeId].prop.period);
      }
      if (nodeProps[nodeId]?.prop?.stdv != stdv) {
        setStdv(nodeProps[nodeId].prop.stdv);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }

      if (nodeProps[nodeId]?.prop?.upper != upper) {
        setUpper(parseFloat(nodeProps[nodeId].prop.upper));
      }

      if (nodeProps[nodeId]?.prop?.lower != lower) {
        setLower(parseFloat(nodeProps[nodeId].prop.lower));
      }

      if (nodeProps[nodeId]?.prop?.middle != middle) {
        setMiddle(parseFloat(nodeProps[nodeId].prop.middle));
      }
    }
  }, [nodeProps]);
  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);
  //step 3 get connected nodes data to disable the inputs and get the data
  useEffect(() => {
    const foundPrice = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_bb_input_1/array"
    );
    if (foundPrice) {
      let priceTarget = foundPrice.target;

      let sourceNode = foundPrice.source;

      setSourceId(sourceNode);
      let priceHandle = foundPrice.targetHandle;
      if (nodeData[priceTarget]) {
        setAssetId(nodeData[sourceNode]?.data?.assetBotId);
        setSource(nodeData[priceTarget].data[priceHandle]);
      }
      setPricesDisabled(true);
    } else {
      setPricesDisabled(false);
    }

    const foundPeriod = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_bb_input_period/int"
    );
    if (foundPeriod) {
      let periodTarget = foundPeriod.target;
      let periodHandle = foundPeriod.targetHandle;
      if (nodeData[periodTarget]) {
        setPeriod(parseInt(nodeData[periodTarget].data[periodHandle]));
      }
      setPeriodDisabled(true);
    } else {
      setPeriodDisabled(false);
    }

    const foundStdv = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_bb_input_stdv/int"
    );
    if (foundStdv) {
      let stdvTarget = foundStdv.target;
      let stdvHandle = foundStdv.targetHandle;
      if (nodeData[stdvTarget]) {
        setStdv(parseFloat(nodeData[stdvTarget].data[stdvHandle]));
      }
      setStdvDisabeled(true);
    } else {
      setStdvDisabeled(false);
    }
  }, [connectedEdges, nodeData]);

  //step 4 set data to connected nodes
  useEffect(() => {
    const findPeriod = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_period/number"
    );
    if (findPeriod && findPeriod.length > 0) {
      findPeriod.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: period,
          })
        );
      });
    }

    const findStdv = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_stdv/number"
    );
    if (findStdv && findStdv.length > 0) {
      findStdv.map((el) => {
        let StdvHandle = el.targetHandle;
        let StdvTarget = el.target;

        dispatch(
          nodeDataUpdate(StdvTarget, {
            [StdvHandle]: stdv,
          })
        );
      });
    }

    const findUpper = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_upper/number"
    );
    if (findUpper && findUpper.length > 0) {
      findUpper.map((el) => {
        let upperHandle = el.targetHandle;
        let upperTarget = el.target;

        dispatch(
          nodeDataUpdate(upperTarget, {
            [upperHandle]: upper,
          })
        );
      });
    }

    const findMiddle = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_middle/number"
    );
    if (findMiddle && findMiddle.length > 0) {
      findMiddle.map((el) => {
        let middleHandle = el.targetHandle;
        let middleTarget = el.target;

        dispatch(
          nodeDataUpdate(middleTarget, {
            [middleHandle]: middle,
          })
        );
      });
    }

    const findLower = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_lower/number"
    );
    if (findLower && findLower.length > 0) {
      findLower.map((el) => {
        let lowerHandle = el.targetHandle;
        let lowerTarget = el.target;

        dispatch(
          nodeDataUpdate(lowerTarget, {
            [lowerHandle]: lower,
          })
        );
      });
    }

    const findUpperArray = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_upper/array"
    );
    if (findUpperArray && findUpperArray.length > 0) {
      findUpperArray.map((el) => {
        let upperHandle = el.targetHandle;
        let upperTarget = el.target;

        dispatch(
          nodeDataUpdate(upperTarget, {
            [upperHandle]: [upperArray],
          })
        );
      });
    }

    const findMiddleArray = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_middle/array"
    );
    if (findMiddleArray && findMiddleArray.length > 0) {
      findMiddleArray.map((el) => {
        let middleHandle = el.targetHandle;
        let middleTarget = el.target;

        dispatch(
          nodeDataUpdate(middleTarget, {
            [middleHandle]: [middle],
          })
        );
      });
    }

    const findLowerArray = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bb_output_lower/array"
    );
    if (findLowerArray && findLowerArray.length > 0) {
      findLowerArray.map((el) => {
        let lowerHandle = el.targetHandle;
        let lowerTarget = el.target;

        dispatch(
          nodeDataUpdate(lowerTarget, {
            [lowerHandle]: [lowerArray],
          })
        );
      });
    }
  }, [
    connectedEdges,
    upper,
    lower,
    middle,
    stdv,
    period,
    lowerArray,
    middleArray,
    upperArray,
  ]);

  //step 5 calculate the data
  useEffect(() => {
    if (period && assetId && source) {
      dispatch(
        getBBValues(
          assetId,
          { period: period, inputSource: source, stdv: stdv },
          nodeId
        )
      );
    }
  }, [period, assetId, source, historicalData[sourceId], sourceId, stdv]);

  //update the states

  useEffect(() => {
    if (
      bbLiveValue?.[nodeId]?.upper &&
      bbLiveValue?.[nodeId]?.lower &&
      bbLiveValue?.[nodeId]?.middle
    ) {
      setUpper(bbLiveValue?.[nodeId]?.upper);
      setLower(bbLiveValue?.[nodeId]?.lower);
      setMiddle(bbLiveValue?.[nodeId]?.middle);
    } else if (bbValues?.[nodeId]) {
      const value = bbValues[nodeId]?.[bbValues[nodeId]?.length - 1]?.value;
      setUpper(parseFloat(JSON.parse(value)?.upper));
      setMiddle(parseFloat(JSON.parse(value)?.middle));
      setLower(parseFloat(JSON.parse(value)?.lower));
    }
  }, [bbValues?.[nodeId], bbLiveValue?.[nodeId]]);
  useEffect(() => {
    socketIo.on("sendLiveCandle", (msg) => {
      if (msg?.botAssetId == assetId && period && source) {
        dispatch(
          getBBLiveValues(
            assetId,
            { period: period, inputSource: source, stdv: stdv },
            nodeId,
            msg?.candle
          )
        );
      }
    });
    return () => {
      socketIo.off("sendLiveCandle");
    };
  }, [assetId, period, source, nodeId, historicalData[sourceId], stdv]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--purple)"
      selected={node?.selected}
      body={
        <div className="rsi_body">
          <div style={{ position: "relative" }}>
            <ArrayHandle
              type="target"
              id={nodeId + "_bb_input_1/array"}
              style={{
                left: "-25px",
                top: "10px",
              }}
              isConnectable={!pricesDisabled}
              required={true}
            />
            <div className="rsi_body_inner_title_main">
              <p className="rsi_body_inner_title_p_main">Array</p>
            </div>
          </div>

          <div className="rsi_body_inner_title">
            <img src={arrowDown} />
            <p className="rsi_body_inner_title_p">BB</p>
          </div>
          <div className="rsi_body_inner_body">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  type="target"
                  id={nodeId + "_bb_input_period/int"}
                  style={{
                    left: "-25px",
                  }}
                  isConnectable={!periodDisabled}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Period</p>
                <input
                  id="bb_period"
                  type="number"
                  className={
                    periodDisabled
                      ? "input_field input_field_disabled"
                      : "input_field"
                  }
                  value={period}
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  disabled={periodDisabled}
                />
              </div>

              <NumberHandle
                type="source"
                id={nodeId + "_bb_output_period/number"}
                style={{ right: "-25px" }}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <NumberHandle
                  type="target"
                  id={nodeId + "_bb_input_stdv/int"}
                  style={{
                    left: "-25px",
                  }}
                  isConnectable={!stdvDisabeled}
                />
                <NumberHandle
                  type="source"
                  id={nodeId + "_bb_output_stdv/number"}
                  style={{ right: "-25px", top: "17px" }}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">STDV</p>
                <input
                  id="stdv_value"
                  type="number"
                  value={stdv}
                  className={
                    stdvDisabeled
                      ? "input_field input_field_disabled"
                      : "input_field"
                  }
                  onChange={(e) => setStdv(parseFloat(e.target.value))}
                  disabled={stdvDisabeled}
                />
              </div>
            </div>
          </div>
          <div className="rsi_body_inner_title">
            <img src={arrowDown} />
            <p className="rsi_body_inner_title_p">Bands</p>
          </div>
          <div className="rsi_body_inner_body">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Upper</p>
                <input
                  id="bb_upper"
                  disabled={true}
                  type="number"
                  className="input_field input_field_disabled"
                  value={upper?.toFixed(2)}
                />
              </div>

              <NumberHandle
                type="source"
                id={nodeId + "_bb_output_upper/number"}
                style={{ right: "-25px", top: "5px" }}
              />
              <ArrayHandle
                type="source"
                id={nodeId + "_bb_output_upper/array"}
                style={{
                  right: "-25px",
                  top: "25px",
                }}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_bb_output_middle/number"}
                  style={{ right: "-25px", top: "5px" }}
                />
                <ArrayHandle
                  type="source"
                  id={nodeId + "_bb_output_middle/array"}
                  style={{
                    right: "-25px",
                    top: "25px",
                  }}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Middle</p>
                <input
                  id="bb_middle"
                  type="number"
                  value={middle?.toFixed(2)}
                  disabled={true}
                  className="input_field input_field_disabled"
                />
              </div>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_bb_output_lower/number"}
                  style={{ right: "-25px", top: "5px" }}
                />
                <ArrayHandle
                  type="source"
                  id={nodeId + "_bb_output_lower/array"}
                  style={{
                    right: "-25px",
                    top: "25px",
                  }}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Lower</p>
                <input
                  id="bb_lower"
                  type="number"
                  value={lower?.toFixed(2)}
                  className="input_field input_field_disabled"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default BBNode;

import React from "react";
import "./Footer.scss";
import logo from "../../Assets/Images/logoBig.svg";
import tikTok from "../../Assets/Images/tiktok.png";
import twitter from "../../Assets/Images/twitter.png";
import youtube from "../../Assets/Images/youtube.png";
import instagram from "../../Assets/Images/instagram.png";
import linkedIn from "../../Assets/Images/linkedin.png";
function Footer() {
  return (
    <div className="footer_main_div">
      <div className="footer_div">
        <img src={logo} alt="logo" className="footer_logo" />
        <div className="footer_links">
          <img src={tikTok} alt="tiktok" className="footer_link" />
          <img src={twitter} alt="twitter" className="footer_link" />
          <img src={youtube} alt="youtube" className="footer_link" />
          <img src={instagram} alt="instagram" className="footer_link" />
          <img src={linkedIn} alt="linkedIn" className="footer_link" />
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { useEffect } from "react";
import "./RunSummary.scss";
import bot_visual from "../../Assets/Images/bot_visual.svg";
import lock from "../../Assets/Images/lock.svg";
import red_arrow from "../../Assets/Images/redArrow.svg";
import { Chart } from "../../Components/Chart/Chart";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { getAllTrades } from "../../Redux/Home/HomeActions";

function RunSummary() {
  const data = [
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
  ];

  const { trades, getAllTradesLoading } = useSelector(
    (state) => state.HomeReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTrades());
    let a = setInterval(() => {
      dispatch(getAllTrades());
    }, 15000);
    return () => {
      clearInterval(a);
    };
  }, []);

  return (
    <div className="run_summary_main">
      <div className="div_1">
        <img src={bot_visual} alt="bot_visual" className="bot_visual" />
        <div className="div_1_bottom_data">
          <div className="div_1_bottom_data_left">
            <div className="div_1_description">
              Your bot executes trades by analyzing the Relative Strength Index
              (RSI) of gold against the US dollar (XAUUSD). By using historical
              price data to calculate the RSI over a 14-period span, it
              determines overbought conditions. When the RSI exceeds a threshold
              of 70, indicating an overbought market, the bot automatically
              places a sell order, thus capitalizing on potential price
              reversals.
            </div>
            <div className="run_summary_bot_status">
              <div className="title">
                <div className="left">Bot Status</div>
                <div className="right">Working</div>
              </div>
              <div className="steps">
                {Array.from({ length: 36 }).map((_, index) => (
                  <div key={index} className="step"></div>
                ))}
              </div>
            </div>
          </div>
          <table className="div_1_table_main">
            <tbody className="div_1_table_body">
              <tr className="div_1_table_row">
                <td>Assets</td>
                <td>XAUUSD</td>
              </tr>
              <tr className="div_1_table_row">
                <td>Indicators</td>
                <td>Relative Strength Index</td>
              </tr>
              <tr className="div_1_table_row">
                <td>Order Type</td>
                <td>Buy, Sell</td>
              </tr>
              <tr className="div_1_table_row">
                <td>Order Size</td>
                <td>70 Lots</td>
              </tr>
              <tr className="div_1_table_row">
                <td># of Nodes</td>
                <td>8 Nodes</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="locked">
          <img src={lock} alt="lock" className="lock_img" />
          <div className="text">Locked</div>
        </div>
      </div>
      <div className="div_2">
        <div className="div_2_title">
          <div className="left">Oriqon Monitor</div>
          <div className="right">+ Add Widget</div>
        </div>
        <div className="div_2_grid">
          <div className="sub_div_1">
            <div className="left">
              <div className="asset_name">XAUUSD</div>
              <div className="asset_full_name">Gold Spot</div>
              <div className="asset_price">
                $2,334.21{" "}
                <span className="red_text">
                  <img src={red_arrow} alt="red_arrow" className="red_arrow" />
                  -0.33% (0.00348)
                </span>
              </div>
            </div>
            <div className="right">
              <Chart height={75} />
            </div>
          </div>
          <div className="sub_div_2">
            <div className="top">
              <div className="left">
                <div className="left_left">
                  <div className="text_1">Relative Strength Index</div>
                  <div className="text_2">14 Period - Close Data</div>
                </div>
                <div className="right_left">71.23</div>
              </div>
              <div className="right">Indicator</div>
            </div>
            <div className="bottom">
              <Chart
                height={75}
                lineColor={"#C501F3"}
                areaTopColor={"#C501F3"}
                areaBottomColor={"transparent"}
              />
            </div>
          </div>
          <div className="sub_div_3">
            <div className="div_3_top">
              <div className="text_1">Bigger Node</div>
              <div className="text_2">Logic</div>
            </div>
            <div className="div_3_steps">
              {Array.from({ length: 38 }).map((_, index) => (
                <div
                  key={index}
                  className={index % 18 === 0 ? "step purple_step" : "step"}
                ></div>
              ))}
            </div>
            <table className="sub_div_3_table_main">
              <tbody className="sub_div_3_table_body">
                <tr className="sub_div_3_table_row">
                  <td>Today</td>
                  <td>5 Times (18.5%)</td>
                </tr>
                <tr className="sub_div_3_table_row">
                  <td>Last Week</td>
                  <td>33 Times (6.3%)</td>
                </tr>
                <tr className="sub_div_3_table_row">
                  <td>STDV (5%) - Today</td>
                  <td>3 Times (78%)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sub_div_4">+ Add Widget</div>
        </div>
      </div>
      <div className="div_3">
        <div className="table_container">
          <div className="table_title">
            <div className="left">Trades</div>
            <div className="right">{trades?.length} Total Trades</div>
          </div>
          {getAllTradesLoading ? (
            <div
              style={{
                padding: "2rem",
              }}
            >
              <Loader width={"5rem"} />
            </div>
          ) : trades?.length > 0 ? (
            <table className="table_trades_main">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Ticket</th>
                  <th>Time</th>
                  <th>Type</th>
                  <th>Volume</th>
                  <th>Price</th>
                  <th>TP</th>
                  <th>SL</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {trades?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.symbol || "-"}</td>
                      <td>{item.ticket || "-"}</td>
                      <td>{item.time || "-"}</td>
                      <td>{item.type || "-"}</td>
                      <td>{item.volume || "-"}</td>
                      <td>{item.price || "-"}</td>
                      <td>{item.takeProfit || "-"}</td>
                      <td>{item.stopLoss || "-"}</td>
                      <td>
                        {item.profit.toLocaleString("currency", {
                          style: "currency",
                          currency: "USD",
                        }) || "-"}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="table_total">Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="table_total_number">
                    {" "}
                    {trades
                      ?.reduce((sum, item) => sum + (item.profit || 0), 0)
                      .toLocaleString("currency", {
                        style: "currency",
                        currency: "USD",
                      }) || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="no_data">No Current Trades</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RunSummary;

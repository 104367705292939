import React, { useEffect, useState } from "react";
import "./Password.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeAccesss } from "../../Redux/FlowPanel/FlowActions";
import { useNavigate } from "react-router-dom";

function Password() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const { accessError } = useSelector((state) => state.flow);

  const handlePassword = (e) => {
    e.preventDefault();
    if (password == "hg1dc91h") {
      window.localStorage.setItem("user", "logged");
      navigate("/home");
    }
  };

  return (
    <div className="password_main">
      <form className="password_form" onSubmit={(e) => handlePassword(e)}>
        <div class="typewriter">
          <h1>Enter Your Password ...</h1>
        </div>
        <input
          className="password_input"
          placeholder="Password"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {accessError && <div className="error">{accessError}</div>}
        <button className="password_button">Enter</button>
      </form>
    </div>
  );
}

export default Password;

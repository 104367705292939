import React, { useEffect, useState } from "react";
import "./ConditionNode.scss";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NodeBox from "../../Components/NodeBox/NodeBox";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";

function ConditionNode() {
  const [connectedEdges, setConnectedEdges] = useState([]);
  const { getNode, setNodes, getNodes } = useReactFlow();
  const [number, setNumber] = useState();
  const [title, setTitle] = useState("IF THEN ELSE");

  const { edges } = useSelector((state) => state.flow);
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);

  const nodeId = useNodeId();

  const node = getNode(nodeId);

  const dispatch = useDispatch();

  const [then, setThen] = useState();
  const [elsee, setElsee] = useState();
  const [condition, setcondition] = useState(null);
  const [conditionDisabled, setConditionDisabled] = useState(false);

  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //get the number from the connected node
  useEffect(() => {
    const foundCondition = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_condition_input/boolean"
    );
    if (foundCondition) {
      let conditionTarget = foundCondition.target;
      let conditionHandle = foundCondition.targetHandle;
      if (nodeData[conditionTarget]) {
        {
          setcondition(nodeData[conditionTarget].data[conditionHandle]);
        }
      }
      setConditionDisabled(true);
    } else {
      setConditionDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    if (condition != null) {
      const findThen = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "_condition_then/boolean"
      );

      if (findThen && findThen.length > 0) {
        findThen.map((el) => {
          let ThenHandle = el.targetHandle;
          let ThenTarget = el.target;

          dispatch(
            nodeDataUpdate(ThenTarget, {
              [ThenHandle]: condition,
            })
          );
        });
      }

      const findElse = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "_condition_else/boolean"
      );
      if (findElse && findElse.length > 0) {
        findElse.map((el) => {
          let ElseHandle = el.targetHandle;
          let ElseTarget = el.target;

          dispatch(
            nodeDataUpdate(ElseTarget, {
              [ElseHandle]: !condition,
            })
          );
        });
      }
    }
  }, [connectedEdges, condition]);

  //setting the props
  useEffect(() => {
    dispatch(
      nodePropUpdate(nodeId, {
        label: title,
        hide: true,
        condition: condition,
      })
    );
  }, [title]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--blue)"
      selected={node?.selected}
      body={
        <div className="condition_body">
          <div style={{ position: "relative" }}>
            <BooleanHandle
              id={nodeId + "_condition_input/boolean"}
              type="target"
              isConnectable={!conditionDisabled}
              style={{
                left: "-22px",
              }}
              required={true}
            />
            <div className="condition_body_title">
              <p className="condition_body_title_P">Condition</p>
            </div>
          </div>
          <div className="condition_body_options">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <BooleanHandle
                  id={nodeId + "_condition_then/boolean"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-23px",
                  }}
                />
              </div>
              <p className="HandleDivP">Then</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <BooleanHandle
                  id={nodeId + "_condition_else/boolean"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-23px",
                  }}
                />
              </div>
              <p className="HandleDivP">Else</p>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ConditionNode;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectedEdges,
  useNodeId,
  useReactFlow
} from "reactflow";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import ObjectHandle from "../../Components/CustomHandles/ObjectHandle/ObjectHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import "./AssetData.scss";

function AssetData() {
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const node = getNode(nodeId && nodeId);
  const [title, setTitle] = useState("Asset Data");
  const [outPutConnect, setOutPutConnect] = useState(true);
  const [attr, setAttr] = useState({});

  const [connectedEdges, setConnectedEdges] = useState([]);
  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);

  const reduxNodes = useSelector((state) => state.flow?.nodes);

  //getting and setting the connected edges
  useEffect(() => {
    if (node) setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //to get attribute data
  useEffect(() => {
    const foundAttr = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "attr_input/object"
    );
    if (foundAttr) {
      let attrTarget = foundAttr.target;
      let attrHandle = foundAttr.targetHandle;
      if (nodeData[attrTarget]) {
        {
          setAttr(nodeData[attrTarget].data[attrHandle]);
        }
      }
      setOutPutConnect(true);
    } else {
      setOutPutConnect(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    var close = [];
    var open = [];
    var low = [];
    var high = [];

    var newData;
    if (attr?.data) {
      const data = attr?.data;
      newData = data;
      newData.forEach((item) => {
        close.push(item.close);
        open.push(item.open);
        low.push(item.low);
        high.push(item.high);
      });

      // find if there is a connection to the open output
      const findOpen = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "open_input/array"
      );
      // find if there is a connection to the high output
      const findHigh = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "high_input/array"
      );
      // find if there is a connection to the low output
      const findLow = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "low_input/array"
      );
      // find if there is a connection to the close output
      const findClose = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "close_input/array"
      );

      if (findOpen && findOpen.length > 0) {
        findOpen.map((el) => {
          let openHandle = el.targetHandle;
          let openTarget = el.target;
          dispatch(
            nodeDataUpdate(openTarget, {
              [openHandle]: open,
            })
          );
        });
      }

      if (findHigh && findHigh.length > 0) {
        findHigh.map((el) => {
          let highHandle = el.targetHandle;
          let highTarget = el.target;

          dispatch(
            nodeDataUpdate(highTarget, {
              [highHandle]: high,
            })
          );
        });
      }
      if (findLow && findLow.length > 0) {
        findLow.map((el) => {
          let lowHandle = el.targetHandle;
          let lowTarget = el.target;

          dispatch(
            nodeDataUpdate(lowTarget, {
              [lowHandle]: low,
            })
          );
        });
      }

      if (findClose && findClose.length > 0) {
        findClose.map((el) => {
          let closeHandle = el.targetHandle;
          let closeTarget = el.target;

          dispatch(
            nodeDataUpdate(closeTarget, {
              [closeHandle]: close,
            })
          );
        });
      }
    }
  }, [connectedEdges, attr]);

  //setting the props
  useEffect(() => {
    dispatch(
      nodePropUpdate(nodeId, {
        label: title,
        dataTable: attr?.data,
      })
    );
  }, [title, attr]);

  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      selected={node?.selected}
      color="var(--orange)"
      middle={
        <ObjectHandle
          id={nodeId + "attr_input"}
          isConnectable={outPutConnect}
          type="target"
          style={{
            left: "-7px",
            top: "33px",
          }}
          required={true}
        />
      }
      body={
        <div className="AssetMainBody">
          <div className="AssetMainInner">
            <img src={arrowDown} />
            <p className="MainLowerCustomDivOHLC">OHLC</p>
          </div>

          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <ArrayHandle
                id={nodeId + "open_input/array"}
                type="source"
                isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Open</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <ArrayHandle
                id={nodeId + "high_input/array"}
                type="source"
                isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">High</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <ArrayHandle
                id={nodeId + "low_input/array"}
                type="source"
                isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Low</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <ArrayHandle
                id={nodeId + "close_input/array"}
                type="source"
                isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Close</p>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default AssetData;

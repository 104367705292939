import React, { FC, useCallback } from "react";
import "./NumberHandle.scss";

import {
  Handle,
  Position,
  HandleType,
  Connection,
  useNodeId,
  useStore,
} from "reactflow";

function NumberHandle({
  id,
  type,
  onConnect,
  isValidConnection,
  isConnectable,
  style,
  required,
}) {
  return (
    <Handle
      type={type}
      position={type === "target" ? Position.Left : Position.Right}
      id={id}
      onConnect={onConnect}
      isValidConnection={(connection) => {
        return (
          (connection.targetHandle?.includes("/number") ||
            connection.targetHandle?.includes("/int")) ??
          false
        );
      }}
      isConnectable={isConnectable}
      isConnectableStart={type === "target" ? false : true}
      isConnectableEnd={type === "source" ? false : true}
      style={style}
      className="number_handle"
    >
      <div className="numberHandle_background_Div">
        <div
          className={
            required == true
              ? "NumberHandle_circleDivRequired"
              : "NumberHandle_circleDiv"
          }
        ></div>
      </div>
    </Handle>
  );
}

export default NumberHandle;

import React, { useState } from "react";
import "./RunPopup.scss";
import Popup from "../../Components/Popup/Popup";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Button from "../../Components/Button/Button";
import add from "../../Assets/Images/Add.svg";
import { useNavigate } from "react-router-dom";

function RunPopup({ show, setShow }) {
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  return (
    <Popup show={show} setShow={setShow}>
      <div className="run_popup_main">
        <div className="run_popup_main_title">
          Are You Sure You Want To Deploy Your Bot?
        </div>
        <div className="run_popup_main_sub_title">
          Here's a summary of your bot
        </div>
        <div className="run_popup_main_description">
          Your bot executes trades by analyzing the Relative Strength Index
          (RSI) of gold against the US dollar (XAUUSD). By using historical
          price data to calculate the RSI over a 14-period span, it determines
          overbought conditions. When the RSI exceeds a threshold of 70,
          indicating an overbought market, the bot automatically places a sell
          order, thus capitalizing on potential price reversals.
        </div>
        <table className="run_table_main">
          <tbody className="run_table_body">
            <tr className="run_table_row">
              <td>Assets</td>
              <td>XAUUSD</td>
            </tr>
            <tr className="run_table_row">
              <td>Indicators</td>
              <td>Relative Strength Index</td>
            </tr>
            <tr className="run_table_row">
              <td>Order Type</td>
              <td>Buy, Sell</td>
            </tr>
            <tr className="run_table_row">
              <td>Order Size</td>
              <td>70 Lots</td>
            </tr>
            <tr className="run_table_row">
              <td># of Nodes</td>
              <td>8 Nodes</td>
            </tr>
          </tbody>
        </table>
        <hr style={{ width: "85%", border: "2px solid #2D2D2D" }} />
        <div className="checkbox">
          <div
            className="inner_checkbox"
            onClick={() => {
              setChecked(!checked);
            }}
          >
            <Checkbox checked={checked} />
            <div className="label">Deploy Test Bot</div>
          </div>
        </div>
        <div className="actions">
          <div
            className="cancel"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </div>
          <Button
            onClick={() => {
              navigate("/run/1");
              setShow(false);
            }}
          >
            <div className="run_button_main">
              <img src={add} alt="add" />
              <div className="run_text">Deploy Bot</div>
            </div>
          </Button>
        </div>
      </div>
    </Popup>
  );
}

export default RunPopup;

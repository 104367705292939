import React, { useEffect, useState } from "react";
import "./Testimonials.scss";
import logo_small from "../../../Assets/Images/logo_small.svg";
import { ReactComponent as Images } from "../../../Assets/Images/image2Test.svg";
import images from "../../../Assets/Images/image2Test.svg";
import arrowL from "../../../Assets/Images/arrowLeft.svg";
import arrowR from "../../../Assets/Images/arrowRight.svg";
import { useEdges } from "reactflow";

function Testimonials() {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step == 3) {
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step == 1) {
      setStep(3);
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (step == 1) {
      document.getElementById("image_test_1").style.opacity = 1;
      document.getElementById("image_test_2").style.opacity = 0.3;
      document.getElementById("image_test_3").style.opacity = 0.3;
    }
    if (step == 2) {
      document.getElementById("image_test_2").style.opacity = 1;
      document.getElementById("image_test_1").style.opacity = 0.3;
      document.getElementById("image_test_3").style.opacity = 0.3;
    }

    if (step == 3) {
      document.getElementById("image_test_3").style.opacity = 1;
      document.getElementById("image_test_1").style.opacity = 0.3;
      document.getElementById("image_test_2").style.opacity = 0.3;
    }
  }, [step]);

  return (
    <div className="testimonials_main_div">
      <div className="testimonials_main_div_main">
        <div className="testimonials_sec1">
          <div className="testimonials_sec1_title">TESTIMONIALS</div>
          <div className="testimonials_sec1_subTitle">
            What Traders Are Saying{" "}
            <img src={logo_small} className="testimonails_logo" />
            <br/>
            About Oriqon?
          </div>
        </div>
        <div className="testimonials_sec2">
          <div className="testimonials_sec2_header">
            <Images className="testmonials_images" />
            <div className="tedtimonials_header_arrows">
              <img
                src={arrowL}
                className="testimonials_arrow"
                onClick={handleBack}
              />
              <img
                src={arrowR}
                className="testimonials_arrow"
                onClick={handleNext}
              />
            </div>
          </div>
          <div className="testimonials_sec2_body">
            <div className="testimonials_sec2_body_text">
              {step == 1 && (
                <q>
                  Having experimented with numerous trading bot platforms, I
                  can confidently say this is a game-changer. The node-based
                  system simplifies the complex, making bot creation intuitive
                  and surprisingly fun. I've been able to fine-tune my
                  strategies like never before. Highly recommended for both
                  beginners and pros1!
                </q>
              )}

              {step == 2 && (
                <q>
                  Having experimented with numerous trading bot platforms, I
                  can confidently say this is a game-changer. The node-based
                  system simplifies the complex, making bot creation intuitive
                  and surprisingly fun. I've been able to fine-tune my
                  strategies like never before. Highly recommended for both
                  beginners and pros2!
                </q>
              )}
              {step == 3 && (
                <q>
                  Having experimented with numerous trading bot platforms, I
                  can confidently say this is a game-changer. The node-based
                  system simplifies the complex, making bot creation intuitive
                  and surprisingly fun. I've been able to fine-tune my
                  strategies like never before. Highly recommended for both
                  beginners and pros3!
                </q>
              )}
            </div>
            <div className="testimonials_sec2_body_name">
              {step ==1 && "Oliver L. - Algorithmic Trader1"}
              {step == 2 && "Oliver L. - Algorithmic Trader2"}
              {step == 3 && "Oliver L. - Algorithmic Trader2"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;

import React, { useEffect, useState } from "react";
import "./LowerNode.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  Edge,
  Handle,
  Position,
  getConnectedEdges,
  useNodeId,
  useReactFlow,
} from "reactflow";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
function LowerNode({ data }) {
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();

  useEffect(() => {
    data && setValue2(data?.value);
  }, [data]);

  const [valueDisabled, setValueDisabled] = useState(false);
  const [valueDisabled2, setValueDisabled2] = useState(false);
  const [title, setTitle] = useState("Lower Node");
  const [outPut, setOutPut] = useState(false);
  const [connectedEdges, setConnectedEdges] = useState([]);
  const nodeId = useNodeId();
  const node = getNode(nodeId && nodeId);
  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const dispatch = useDispatch();
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);
  function lower(value, compare) {
    if (value < compare) {
      return true;
    } else {
      return false;
    }
  }
  //get the number from the connected node
  useEffect(() => {
    const foundNumber1 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_lowerNodeValue1/number"
    );
    if (foundNumber1) {
      let numberTarget1 = foundNumber1.target;
      let numberHandle1 = foundNumber1.targetHandle;
      if (nodeData[numberTarget1]) {
        {
          setValue(nodeData[numberTarget1].data[numberHandle1]);
        }
      }
      setValueDisabled(true);
    } else {
      setValueDisabled(false);
    }
    const foundNumber2 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_lowerNodeValue2/number"
    );
    if (foundNumber2) {
      let numberTarget2 = foundNumber2.target;
      let numberHandle2 = foundNumber2.targetHandle;
      if (nodeData[numberTarget2]) {
        {
          setValue2(nodeData[numberTarget2].data[numberHandle2]);
        }
      }
      setValueDisabled2(true);
    } else {
      setValueDisabled2(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    const findNumber = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "lower_output/boolean"
    );
    if (findNumber && findNumber.length > 0) {
      findNumber.map((el) => {
        let numberHandle = el.targetHandle;
        let numberTarget = el.target;

        dispatch(
          nodeDataUpdate(numberTarget, {
            [numberHandle]: { value: outPut, number: value },
          })
        );
      });
    }
  }, [connectedEdges, outPut, value]);

  useEffect(() => {
    if (value && value2) {
      setOutPut(lower(value, value2));
    }
  }, [value, value2]);

  //setting the props
  useEffect(() => {
    var element = document.getElementById("value");
    var html = element?.outerHTML;
    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          value: html,
        },
        value: value2,
        label: title,
      })
    );
  }, [value2, title]);
  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.value != value2) {
        setValue2(nodeProps[nodeId].prop.value);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      selected={node?.selected}
      color="var(--dark-purple)"
      body={
        <div className="SmallerLowerCustomDiv">
          <div className="SmallerLowerDiv">
            <div className="SmallerLowerDivImgP">
              <img src={arrowDown} />
              <p className="SmallerLowerDivP">Input</p>
            </div>
            <NumberHandle
              id={nodeId + "_lowerNodeValue1/number"}
              type="target"
              isConnectable={!valueDisabled}
              style={{
                top: "82px",
              }}
              required={true}
            />
          </div>
          <div className="SmallerLowerDivInner">
            <div
              className="SmallerValueHandleInputDIv"
              style={{ position: "relative" }}
            >
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  id={nodeId + "_lowerNodeValue2/number"}
                  type="target"
                  isConnectable={!valueDisabled2}
                  style={{
                    left: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Value</p>
              <input
                id="value"
                type="number"
                className="input_field"
                value={value2}
                onChange={(e) => setValue2(parseInt(e.target.value))}
                disabled={valueDisabled2}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <BooleanHandle
                  id={nodeId + "lower_output/boolean"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Trigger</p>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default LowerNode;

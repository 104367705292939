import React, { useEffect, useState } from "react";
import "./BiggerNode.scss";
import {
  Edge,
  Handle,
  Position,
  getConnectedEdges,
  useNodeId,
  useReactFlow,
} from "reactflow";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import { useDispatch, useSelector } from "react-redux";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NodeBox from "../../Components/NodeBox/NodeBox";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";

function BiggerNode({ data }) {
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [title, setTitle] = useState("GREATER THAN");

  useEffect(() => {
    data && setValue2(data?.value);
  }, [data]);

  const [valueDisabled, setValueDisabled] = useState(false);
  const [valueDisabled2, setValueDisabled2] = useState(false);
  const [outPut, setOutPut] = useState(false);
  const [connectedEdges, setConnectedEdges] = useState([]);
  const nodeId = useNodeId();
  const node = getNode(nodeId && nodeId);
  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);

  const reduxNodes = useSelector((state) => state.flow.nodes);
  const dispatch = useDispatch();
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);
  function bigger(value, compare) {
    if (value > compare) {
      return true;
    } else {
      return false;
    }
  }
  //get the number from the connected node
  useEffect(() => {
    const foundNumber1 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_biggerNodeValue1/number"
    );
    if (foundNumber1) {
      let numberTarget1 = foundNumber1.target;
      let numberHandle1 = foundNumber1.targetHandle;
      if (nodeData[numberTarget1]) {
        {
          setValue(nodeData[numberTarget1].data[numberHandle1]);
        }
      }
      setValueDisabled(true);
    } else {
      setValueDisabled(false);
    }
    const foundNumber2 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_biggerNodeValue2/number"
    );
    if (foundNumber2) {
      let numberTarget2 = foundNumber2.target;
      let numberHandle2 = foundNumber2.targetHandle;
      if (nodeData[numberTarget2]) {
        {
          setValue2(nodeData[numberTarget2].data[numberHandle2]);
        }
      }
      setValueDisabled2(true);
    } else {
      setValueDisabled2(false);
    }
  }, [connectedEdges, nodeData]);
  useEffect(() => {
    const findNumber = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "open_output/boolean"
    );
    if (findNumber && findNumber.length > 0) {
      findNumber.map((el) => {
        let numberHandle = el.targetHandle;
        let numberTarget = el.target;

        dispatch(
          nodeDataUpdate(numberTarget, {
            [numberHandle]: outPut,
          })
        );
      });
    }
  }, [connectedEdges, outPut, value]);

  useEffect(() => {
    setOutPut(bigger(value, value2));
  }, [value, value2]);

  //setting the props
  useEffect(() => {
    dispatch(
      nodePropUpdate(nodeId, {
        value: value,
        value2: value2,
        hide: true,
        label: title,
      })
    );
  }, [value, value2, title]);
  // //getting the props
  // useEffect(() => {
  //   if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
  //     if (nodeProps[nodeId]?.prop?.value != value2) {
  //       setValue2(nodeProps[nodeId].prop.value);
  //     }
  //     if (nodeProps[nodeId]?.prop?.label != title) {
  //       setTitle(nodeProps[nodeId].prop.label);
  //     }
  //   }
  // }, [nodeProps]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--dark-purple)"
      selected={node?.selected}
      body={
        <div className="BiggerLowerCustomDiv">
          <div className="BiggerLowerDiv"></div>
          <div className="BiggerLowerDivInner">
            <div
              className="BiggerValueHandleInputDIv"
              style={{ position: "relative" }}
            >
              <div className="handleDivPHandle">
                {/* <div className="border_Div firstBorder_Div"></div> */}
                <NumberHandle
                  id={nodeId + "_biggerNodeValue1/number"}
                  type="target"
                  isConnectable={!valueDisabled}
                  style={{
                    top: "15px",
                    left: "-27px",
                  }}
                  required={true}
                />
              </div>
              <div className="HandleDivP1">Value 1</div>
              <input
                id="value"
                type="number"
                className="input_field"
                value={value}
                onChange={(e) => setValue(parseFloat(e.target.value))}
                disabled={valueDisabled}
              />
            </div>
            <div
              className="BiggerValueHandleInputDIv"
              style={{ position: "relative" }}
            >
              <div className="handleDivPHandle">
                {/* <div className="border_Div firstBorder_Div"></div> */}
                <NumberHandle
                  id={nodeId + "_biggerNodeValue2/number"}
                  type="target"
                  isConnectable={true}
                  style={{
                    left: "-28px",
                    top: "15px",
                  }}
                />
              </div>
              <div className="HandleDivP1">Value 2</div>
              <input
                id="value"
                type="number"
                className="input_field"
                value={value2}
                onChange={(e) => setValue2(parseFloat(e.target.value))}
                disabled={valueDisabled2}
              />
            </div>
            <div
              className="handleDiv"
              style={{ position: "relative", marginTop: "1rem" }}
            >
              <div className="handleDivPHandle">
                <div className="border_Div "></div>
                <BooleanHandle
                  id={nodeId + "open_output/boolean"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Status</p>
              <input
                id="value"
                type="text"
                className="input_field"
                value={outPut.toString()}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default BiggerNode;

import { axiosReq } from "../../Utils";
import { nodeDataUpdate } from "../Nodes/NodesActions";
import { AssetNodeActions } from "./AssetNodeReducer";

//get all assets
export const getAllAssets = () => async (dispatch) => {
  dispatch(AssetNodeActions.getAllAssetsRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/asset/allAssets", {}, {}, {})
  ).then((res) => {
    if (res?.success) {
      dispatch(AssetNodeActions.getAllAssetsSuccess(res.data));
    } else {
      dispatch(AssetNodeActions.getAllAssetsError(res?.data?.message));
    }
  });
};

//create bot asset
export const createBotAsset = (data) => async (dispatch) => {
  dispatch(AssetNodeActions.createBotAssetRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/bot-asset",
      {},
      {
        assetId: data.asset,
        timeFrame: data.timeFrame,
        botId: data.id,
      },
      {}
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(AssetNodeActions.createBotAssetSuccess(res.data));
      dispatch(getHistoricalData(res?.data?.id, data?.nodeId));

      dispatch(nodeDataUpdate(data.nodeId, { assetBotId: res?.data?.id }));
    } else {
      dispatch(AssetNodeActions.createBotAssetError(res?.data?.message));
    }
    dispatch(clearAllData());
  });
};

//update bot asset
export const updateBotAsset = (id, data) => async (dispatch) => {
  dispatch(AssetNodeActions.updateBotAssetRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/bot-asset/" + id,
      {},
      {
        assetId: data.asset,
        timeFrame: data.timeFrame,
        botId: data.id,
      },
      {}
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(AssetNodeActions.updateBotAssetSuccess(res.data));
      dispatch(getHistoricalData(res?.data?.id, data?.nodeId));
      dispatch(nodeDataUpdate(data.nodeId, { assetBotId: res?.data?.id }));
    } else {
      dispatch(AssetNodeActions.updateBotAssetError(res?.data?.message));
    }
    dispatch(clearAllData());
  });
};

//get historical data
export const getHistoricalData = (id, nodeId) => async (dispatch) => {
  dispatch(AssetNodeActions.getHistoricalDataRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/ohlc/byBotAsset/" + id,
      {},
      {},
      {}
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(
        AssetNodeActions.getHistoricalDataSuccess({
          nodeId: nodeId,
          data: res.data,
        })
      );
    } else {
      dispatch(AssetNodeActions.getHistoricalDataError(res?.data?.message));
    }
    dispatch(clearAllData());
  });
};

//clear all data
export const clearAllData = () => async (dispatch) => {
  setTimeout(() => {
    dispatch(AssetNodeActions.clearAllData());
  }, 3000);
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rsiValues: {},
  rsiValuesLoading: false,
  rsiValuesError: "",
  rsiValuesSuccess: false,

  rsiLiveLoading: false,
  rsiLiveError: "",
  rsiLiveSuccess: false,
  rsiLiveValue: {},
};

const RSINodeSlice = createSlice({
  name: "RSI Node",
  initialState,
  reducers: {
    //get rsi values
    getRsiValuesRequest: (state) => {
      state.rsiValuesLoading = true;
      state.rsiValuesError = "";
      state.rsiValuesSuccess = false;
    },

    getRsiValuesSuccess: (state, action) => {
      state.rsiValuesLoading = false;
      state.rsiValuesError = "";
      state.rsiValuesSuccess = true;
      state.rsiValues[action.payload?.nodeId] = action.payload?.data;
      state.rsiLiveValue = {};
    },

    getRsiValuesError: (state, action) => {
      state.rsiValuesLoading = false;
      state.rsiValuesError = action.payload;
      state.rsiValuesSuccess = false;
    },

    //get rsi live values
    getRsiLiveValuesRequest: (state) => {
      state.rsiLiveLoading = true;
      state.rsiLiveError = "";
      state.rsiLiveSuccess = false;
    },

    getRsiLiveValuesSuccess: (state, action) => {
      state.rsiLiveLoading = false;
      state.rsiLiveError = "";
      state.rsiLiveSuccess = true;
      state.rsiLiveValue[action.payload.nodeId] = action.payload?.data;
    },

    getRsiLiveValuesError: (state, action) => {
      state.rsiLiveLoading = false;
      state.rsiLiveError = action.payload;
      state.rsiLiveSuccess = false;
    },
  },
});
export default RSINodeSlice.reducer;
export const RSINodeActions = RSINodeSlice.actions;

import React from "react";
import {
  Connection,
  Handle,
  HandleType,
  Position,
  useNodeId,
  useStore,
} from "reactflow";
import "./BooleanHandle.scss";
function BooleanHandle({
  id,
  type,
  onConnet,
  isValidConnection,
  isConnectable,
  style,
  required
}) {
  return (
    <Handle
      type={type}
      position={type === "target" ? Position.Left : Position.Right}
      id={id}
      onConnect={onConnet}
      isValidConnection={(connection) => {
        return connection.targetHandle?.includes("/boolean") ?? false;
      }}
      isConnectable={isConnectable}
      isConnectableStart={type === "target" ? false : true}
      isConnectableEnd={type === "source" ? false : true}
      style={style}
      className="boolean_handle"
    >
      <div className="booleanHandle_background_Div">
        <div
          className={
            required == true
              ? "booleanHandle_squareDivRequired"
              : "booleanHandle_squareDiv"
          }
        ></div>
      </div>
    </Handle>
  );
}

export default BooleanHandle;

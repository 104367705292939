import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bbValues: {},
  bbValuesLoading: false,
  bbValuesError: "",
  bbValuesSuccess: false,

  bbLiveLoading: false,
  bbLiveError: "",
  bbLiveSuccess: false,
  bbLiveValue: {},
};

const BBNodeSlice = createSlice({
  name: "BB Node",
  initialState,
  reducers: {
    //get bb values
    getbbValuesRequest: (state) => {
      state.bbValuesLoading = true;
      state.bbValuesError = "";
      state.bbValuesSuccess = false;
    },

    getbbValuesSuccess: (state, action) => {
      state.bbValuesLoading = false;
      state.bbValuesError = "";
      state.bbValuesSuccess = true;
      state.bbValues[action.payload?.nodeId] = action.payload?.data;
      state.bbLiveValue = {};
    },

    getbbValuesError: (state, action) => {
      state.bbValuesLoading = false;
      state.bbValuesError = action.payload;
      state.bbValuesSuccess = false;
    },

    //get bb live values
    getbbLiveValuesRequest: (state) => {
      state.bbLiveLoading = true;
      state.bbLiveError = "";
      state.bbLiveSuccess = false;
    },

    getbbLiveValuesSuccess: (state, action) => {
      state.bbLiveLoading = false;
      state.bbLiveError = "";
      state.bbLiveSuccess = true;
      state.bbLiveValue[action.payload.nodeId] = action.payload?.data;
    },

    getbbLiveValuesError: (state, action) => {
      state.bbLiveLoading = false;
      state.bbLiveError = action.payload;
      state.bbLiveSuccess = false;
    },

  },
});
export default BBNodeSlice.reducer;
export const BBNodeActions = BBNodeSlice.actions;

import { ReactFlowProvider } from "reactflow";
import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import Routes from "./Routes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { useEffect } from "react";

export const socketIo = io.connect(process.env.REACT_APP_API + "", {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999,
});

function App() {
  return (
    <ReactFlowProvider>
      <Routes />
      <ToastContainer stacked hideProgressBar autoClose={500} />
    </ReactFlowProvider>
  );
}

export default App;

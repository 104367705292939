import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAssets: [],
  allAssetsLoading: false,
  allAssetsError: "",
  allAssetsSuccess: false,

  //create bot asset
  createBotAssetLoading: false,
  createBotAssetError: "",
  createBotAssetSuccess: false,

  //update bot asset
  updateBotAssetLoading: false,
  updateBotAssetError: "",
  updateBotAssetSuccess: false,

  getHistoricalDataLoading: false,
  getHistoricalDataError: "",
  getHistoricalDataSuccess: false,
  historicalData: {},
};

const AssetNodeSlice = createSlice({
  name: "Asset Node",
  initialState,
  reducers: {
    //get all assets
    getAllAssetsRequest: (state) => {
      state.allAssetsLoading = true;
      state.allAssetsError = "";
      state.allAssetsSuccess = false;
    },

    getAllAssetsSuccess: (state, action) => {
      state.allAssetsLoading = false;
      state.allAssetsError = "";
      state.allAssetsSuccess = true;
      state.allAssets = action.payload;
    },

    getAllAssetsError: (state, action) => {
      state.allAssetsLoading = false;
      state.allAssetsError = action.payload;
      state.allAssetsSuccess = false;
    },

    //create bot asset
    createBotAssetRequest: (state) => {
      state.createBotAssetLoading = true;
      state.createBotAssetError = "";
      state.createBotAssetSuccess = false;
    },

    createBotAssetSuccess: (state, action) => {
      state.createBotAssetLoading = false;
      state.createBotAssetError = "";
      state.createBotAssetSuccess = true;
    },

    createBotAssetError: (state, action) => {
      state.createBotAssetLoading = false;
      state.createBotAssetError = action.payload;
      state.createBotAssetSuccess = false;
    },

    //update bot asset
    updateBotAssetRequest: (state) => {
      state.updateBotAssetLoading = true;
      state.updateBotAssetError = "";
      state.updateBotAssetSuccess = false;
    },
    updateBotAssetSuccess: (state, action) => {
      state.updateBotAssetLoading = false;
      state.updateBotAssetError = "";
      state.updateBotAssetSuccess = true;
      state.getHistoricalDataSuccess = false;
    },
    updateBotAssetError: (state, action) => {
      state.updateBotAssetLoading = false;
      state.updateBotAssetError = action.payload;
      state.updateBotAssetSuccess = false;
    },

    //get historical data
    getHistoricalDataRequest: (state) => {
      state.getHistoricalDataLoading = true;
      state.getHistoricalDataError = "";
      state.getHistoricalDataSuccess = false;
    },
    getHistoricalDataSuccess: (state, action) => {
      state.getHistoricalDataLoading = false;
      state.getHistoricalDataError = "";
      state.getHistoricalDataSuccess = true;
      state.historicalData[action.payload?.nodeId] = action.payload.data;
    },
    getHistoricalDataError: (state, action) => {
      state.getHistoricalDataLoading = false;
      state.getHistoricalDataError = action.payload;
      state.getHistoricalDataSuccess = false;
    },

    //clear all data
    clearAllData: (state) => {
      state.allAssetsError = "";
      state.allAssetsSuccess = false;

      state.createBotAssetError = "";
      state.createBotAssetSuccess = false;

      state.updateBotAssetError = "";
      state.updateBotAssetSuccess = false;
    },
  },
});

export default AssetNodeSlice.reducer;
export const AssetNodeActions = AssetNodeSlice.actions;

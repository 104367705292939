import React from "react";
import "./DropDown.scss";

function DropDown({ id, options, value, onChange, style, disabled }) {
  return (
    <select
      id={id}
      className="DropDownMain"
      style={style}
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      <option value="" disabled selected>
        Select
      </option>
      {options.map((option, index) => (
        <option className="DropDown_Options" key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default DropDown;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  smaValues: {},
  smaValuesLoading: false,
  smaValuesError: "",
  smaValuesSuccess: false,

  smaLiveLoading: false,
  smaLiveError: "",
  smaLiveSuccess: false,
  smaLiveValue: {},
};

const SMANodeSlice = createSlice({
  name: "SMA Node",
  initialState,
  reducers: {
    //get sma values
    getSmaValuesRequest: (state) => {
      state.smaValuesLoading = true;
      state.smaValuesError = "";
      state.smaValuesSuccess = false;
    },

    getSmaValuesSuccess: (state, action) => {
      state.smaValuesLoading = false;
      state.smaValuesError = "";
      state.smaValuesSuccess = true;
      state.smaValues[action.payload?.nodeId] = action.payload?.data;
      state.smaLiveValue = {};
    },

    getSmaValuesError: (state, action) => {
      state.smaValuesLoading = false;
      state.smaValuesError = action.payload;
      state.smaValuesSuccess = false;
    },

    //get sma live values
    getSmaLiveValuesRequest: (state) => {
      state.smaLiveLoading = true;
      state.smaLiveError = "";
      state.smaLiveSuccess = false;
    },

    getSmaLiveValuesSuccess: (state, action) => {
      state.smaLiveLoading = false;
      state.smaLiveError = "";
      state.smaLiveSuccess = true;
      state.smaLiveValue[action.payload.nodeId] = action.payload?.data;
    },

    getSmaLiveValuesError: (state, action) => {
      state.smaLiveLoading = false;
      state.smaLiveError = action.payload;
      state.smaLiveSuccess = false;
    },
  },
});
export default SMANodeSlice.reducer;
export const SMANodeActions = SMANodeSlice.actions;

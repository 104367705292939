import React, { Children, useEffect, useState } from "react";
import "./NodesPopUp.scss";
import search from "../../Assets/Images/search_icon.svg";
import star from "../../Assets/Images/star.svg";
import star2 from "../../Assets/Images/star2.svg";
import plus from "../../Assets/Images/plus.svg";
import { motion } from "framer-motion";
import man from "../../Assets/Images/man.png";
import { useReactFlow, useViewport } from "reactflow";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

let id = 0;
const getId = () => `dndnode_${id++}`;
export const setId = (newId) => (id = newId);

function NodesPopUp({ show, setShow }) {
  const { x, y, zoom } = useViewport();

  const [Xpos, setXpos] = useState(x / zoom);
  const [Ypos, setYpos] = useState(y / zoom);

  const [data, setData] = useState({});
  const { addNodes, addEdges } = useReactFlow();
  const [addedSucces, setAddedSucces] = useState(false);

  const [nodes, setNodes] = useState([
    {
      name: "Asset Nodes",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "Crypto Asset Node",
          fav: false,
          type: "Asset",
          description:
            "The asset node allows you to import asset prices into your node editor",
          img: man,
          inputs: [
            {
              name: "Asset Name",
              desc: "Chose what asset you want to import into your algorithm",
            },
            {
              name: "Timeframe",
              desc: "Chose the timeframe of the data you want to import ",
            },
            {
              name: "Exchange",
              desc: "From which exchange you would like to get the data from?",
            },
          ],
          outputs: [
            {
              name: "OHLC",
              desc: "Open, High, Low, Close Data Array",
            },
            {
              name: "Bid/Ask",
              desc: "Live Bid/Ask Data",
            },
          ],
        },
        {
          type: "Asset",

          name: "FX Asset Node",
          fav: true,
        },
        {
          type: "Asset",

          name: "CFD Asset Node",
          fav: false,
        },
      ],
    },
    {
      name: "Math",
      active: false,
      soon: false,
      subNodes: [
        { name: "Sum Node", fav: false, type: "Sum" },
        { name: "Subtract Node", fav: false, type: "Subtract" },
        { name: "Divide Node", fav: false, type: "Divide" },
        { name: "Multiply Node", fav: false, type: "Multiply" },
        { name: "Absolute Node", fav: false, type: "Absolute" },
        { name: "Condition Node", fav: false, type: "Condition" },
        { name: "Number Node", fav: false, type: "Number" },
        { name: "Greater Than", fav: false, type: "Bigger" },
        { name: "Equal Node", fav: false, type: "Equal" },
        { name: "Lower Node", fav: false, type: "Lower" },
        { name: "Boolean Node", fav: false, type: "Boolean_Node" },
      ],
    },
    {
      name: "Indicators",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "RSI Node",
          fav: false,
          img: man,

          type: "RSI",
          description:
            "This node allows you to calculate the relative strength index value of any given assets",
          inputs: [
            {
              name: "Asset",
              desc: "[Array] Chose what asset you want to import into your algorithm",
            },
            {
              name: "RSI Period",
              desc: "[Single Value] Chose the period of the RSI you wish to calculate",
            },
            {
              name: "Overbought",
              desc: "[Single Value] Set the overbought signal level for the RSI",
            },
            {
              name: "Oversold",
              desc: "[Single Value] Set the oversold signal level for the RSI",
            },
            {
              name: "Type",
              desc: "[String] Chose between 2 trigger types {When , While}",
            },
          ],
          outputs: [
            {
              name: "RSI Value - Live",
              desc: "[Single Value] Most recent RSI Value",
            },
            {
              name: "RSI Value - History",
              desc: "[Array] RSI Historical Data",
            },
            {
              name: "Trigger Oversold",
              desc: "[Boolean] When The Rsi Levels Are Below The Designated Oversold Levels",
            },
            {
              name: "Trigger Overbought",
              desc: "[Boolean]  When The Rsi Levels Are Above The Designated Overbought Levels",
            },
          ],
        },
        {
          name: "Simple Moving Average",
          fav: false,
          type: "SMA",
          img: man,
          description:
            "The Simple Moving Average (SMA) is a trend indicator that calculates the average price of an asset over a specific number of periods, smoothing out price data to identify trends.",
          inputs: [
            {
              name: "Asset",
              desc: "[String] Chose what asset you want to import into your algorithm",
            },
            {
              name: "SMA Period",
              desc: "[Single Value] Set the number of periods for the SMA calculation (default: 20)",
            },
          ],
          outputs: [
            {
              name: "SMA Value - Live	",
              desc: "[Single Value]	The most recent SMA value",
            },
            {
              name: "SMA Value - History",
              desc: "[Array]	Historical SMA Data",
            },
          ],
        },
        {
          name: "Bollinger Bands",
          fav: false,
          type: "BB",
          description:
            "Bollinger Bands (BB) are a volatility indicator that measures market volatility and provides relative definitions of high and low prices.",

          inputs: [
            {
              name: "Asset",
              desc: "[Array] Chose what asset you want to import into your algorithm",
            },
            {
              name: "BB Period",
              desc: "[Single Value] Set the period for the moving average calculation (default: 20)",
            },
            {
              name: "Standard Deviation",
              desc: "Set the number of standard deviations for the bands (default: 2)",
            },
          ],
          outputs: [
            {
              name: "Upper Band Value",
              desc: "[Single Value] The most recent upper band value",
            },
            {
              name: "Lower Band Value",
              desc: "[Single Value] The Most Recent Lower Band Value",
            },
            {
              desc: "[Single Value] The Most Recent Middle Band (Moving Average) ",
              name: "Middle Band Value",
            },
            {
              name: "Upper Band History",
              desc: "[Array] Historical Bollinger Bands data",
            },
            {
              name: "Lower Band History",
              desc: "[Array] Historical Bollinger Bands Data",
            },
            {
              name: "Middle Band History",
              desc: "[Array] Historical Bollinger Bands Data",
            },
          ],
        },
        {
          name: "Average True Range",
          fav: false,
          type: "ATR",
          img: man,
          description:
            "The Average True Range (ATR) is a volatility indicator that measures the degree of price movement for an asset.",
          inputs: [
            {
              name: "Asset",
              desc: "[Asset] Chose what asset you want to import into your algorithm",
            },
            {
              name: "ATR Period",
              desc: "Set the period for the ATR calculation (default: 14)",
            },
            {
              name: "Threshold Type",
              desc: "Do you want to set the threshold in % of the price? or an actual value?",
            },
            {
              name: "High Volatility Lvl",
              desc: "[Single Value] Set the High Volatility Level For The ATR - % of the asset price or Value",
            },
            {
              name: "Low Volatility Lvl",
              desc: "[Single Value] Set the Low Volatility Level For The ATR -% of the asset price or Value",
            },
            {
              name: "Type",
              desc: "[Boolean] Chose between 2 trigger types {When , While}",
            },
          ],
          outputs: [
            {
              name: "ATR Value - Live",
              desc: "[Single Value] The most recent ATR value",
            },
            {
              name: "RSI Value - History",
              desc: "[Array] Historical ATR Data",
            },
            {
              name: "Trigger High Vol",
              desc: "[Boolean] When The ATR Value Exceeds The Designated High Volatility Level",
            },
            {
              name: "Trigger Low Vol",
              desc: "[Boolean] When The ATR Value Falls Below The Designated Low Volatility Level",
            },
          ],
        },
      ],
    },
    {
      name: "Deformers",
      active: false,
      soon: true,
      subNodes: [],
    },
    {
      name: "Logic",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "And Node",
          fav: false,
          type: "And",
        },
      ],
    },
    {
      name: "Account",
      active: false,
      soon: false,
      subNodes: [],
    },
    {
      name: "Triggers",
      active: false,
      soon: true,
      subNodes: [],
    },
    {
      name: "Execution",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "Execute Order Node",
          fav: false,
          type: "Execute_Order",
        },
      ],
    },
    {
      name: "Alerts",
      active: false,
      soon: true,
      subNodes: [],
    },
    {
      name: "API",
      active: false,
      soon: true,
      subNodes: [],
    },
  ]);

  const [nodesMain, setNodesMain] = useState([
    {
      name: "Asset Nodes",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "Crypto Asset Node",
          fav: false,
          type: "Asset",
          description:
            "The asset node allows you to import asset prices into your node editor",
          img: man,
          inputs: [
            {
              name: "Asset Name",
              desc: "Chose what asset you want to import into your algorithm",
            },
            {
              name: "Timeframe",
              desc: "Chose the timeframe of the data you want to import ",
            },
            {
              name: "Exchange",
              desc: "From which exchange you would like to get the data from?",
            },
          ],
          outputs: [
            {
              name: "OHLC",
              desc: "Open, High, Low, Close Data Array",
            },
            {
              name: "Bid/Ask",
              desc: "Live Bid/Ask Data",
            },
          ],
        },
        {
          type: "Asset",

          name: "FX Asset Node",
          fav: true,
        },
        {
          type: "Asset",

          name: "CFD Asset Node",
          fav: false,
        },
      ],
    },
    {
      name: "Math",
      active: false,
      soon: false,
      subNodes: [
        { name: "Sum Node", fav: false, type: "Sum" },
        { name: "Subtract Node", fav: false, type: "Subtract" },
        { name: "Divide Node", fav: false, type: "Divide" },
        { name: "Multiply Node", fav: false, type: "Multiply" },
        { name: "Absolute Node", fav: false, type: "Absolute" },
        { name: "Condition Node", fav: false, type: "Condition" },
        { name: "Number Node", fav: false, type: "Number" },
        { name: "Greater Than", fav: false, type: "Bigger" },
        { name: "Equal Node", fav: false, type: "Equal" },
        { name: "Lower Node", fav: false, type: "Lower" },
        { name: "Boolean Node", fav: false, type: "Boolean_Node" },
      ],
    },
    {
      name: "Indicators",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "RSI Node",
          fav: false,
          img: man,

          type: "RSI",
          description:
            "This node allows you to calculate the relative strength index value of any given assets",
          inputs: [
            {
              name: "Asset",
              desc: "[Array] Chose what asset you want to import into your algorithm",
            },
            {
              name: "RSI Period",
              desc: "[Single Value] Chose the period of the RSI you wish to calculate",
            },
            {
              name: "Overbought",
              desc: "[Single Value] Set the overbought signal level for the RSI",
            },
            {
              name: "Oversold",
              desc: "[Single Value] Set the oversold signal level for the RSI",
            },
            {
              name: "Type",
              desc: "[String] Chose between 2 trigger types {When , While}",
            },
          ],
          outputs: [
            {
              name: "RSI Value - Live",
              desc: "[Single Value] Most recent RSI Value",
            },
            {
              name: "RSI Value - History",
              desc: "[Array] RSI Historical Data",
            },
            {
              name: "Trigger Oversold",
              desc: "[Boolean] When The Rsi Levels Are Below The Designated Oversold Levels",
            },
            {
              name: "Trigger Overbought",
              desc: "[Boolean]  When The Rsi Levels Are Above The Designated Overbought Levels",
            },
          ],
        },
        {
          name: "Simple Moving Average",
          fav: false,
          type: "SMA",
          img: man,
          description:
            "The Simple Moving Average (SMA) is a trend indicator that calculates the average price of an asset over a specific number of periods, smoothing out price data to identify trends.",
          inputs: [
            {
              name: "Asset",
              desc: "[String] Chose what asset you want to import into your algorithm",
            },
            {
              name: "SMA Period",
              desc: "[Single Value] Set the number of periods for the SMA calculation (default: 20)",
            },
          ],
          outputs: [
            {
              name: "SMA Value - Live	",
              desc: "[Single Value]	The most recent SMA value",
            },
            {
              name: "SMA Value - History",
              desc: "[Array]	Historical SMA Data",
            },
          ],
        },
        {
          name: "Bollinger Bands",
          fav: false,
          type: "BB",
          description:
            "Bollinger Bands (BB) are a volatility indicator that measures market volatility and provides relative definitions of high and low prices.",

          inputs: [
            {
              name: "Asset",
              desc: "[Array] Chose what asset you want to import into your algorithm",
            },
            {
              name: "BB Period",
              desc: "[Single Value] Set the period for the moving average calculation (default: 20)",
            },
            {
              name: "Standard Deviation",
              desc: "Set the number of standard deviations for the bands (default: 2)",
            },
          ],
          outputs: [
            {
              name: "Upper Band Value",
              desc: "[Single Value] The most recent upper band value",
            },
            {
              name: "Lower Band Value",
              desc: "[Single Value] The Most Recent Lower Band Value",
            },
            {
              desc: "[Single Value] The Most Recent Middle Band (Moving Average) ",
              name: "Middle Band Value",
            },
            {
              name: "Upper Band History",
              desc: "[Array] Historical Bollinger Bands data",
            },
            {
              name: "Lower Band History",
              desc: "[Array] Historical Bollinger Bands Data",
            },
            {
              name: "Middle Band History",
              desc: "[Array] Historical Bollinger Bands Data",
            },
          ],
        },
        {
          name: "Average True Range",
          fav: false,
          type: "ATR",
          img: man,
          description:
            "The Average True Range (ATR) is a volatility indicator that measures the degree of price movement for an asset.",
          inputs: [
            {
              name: "Asset",
              desc: "[Asset] Chose what asset you want to import into your algorithm",
            },
            {
              name: "ATR Period",
              desc: "Set the period for the ATR calculation (default: 14)",
            },
            {
              name: "Threshold Type",
              desc: "Do you want to set the threshold in % of the price? or an actual value?",
            },
            {
              name: "High Volatility Lvl",
              desc: "[Single Value] Set the High Volatility Level For The ATR - % of the asset price or Value",
            },
            {
              name: "Low Volatility Lvl",
              desc: "[Single Value] Set the Low Volatility Level For The ATR -% of the asset price or Value",
            },
            {
              name: "Type",
              desc: "[Boolean] Chose between 2 trigger types {When , While}",
            },
          ],
          outputs: [
            {
              name: "ATR Value - Live",
              desc: "[Single Value] The most recent ATR value",
            },
            {
              name: "RSI Value - History",
              desc: "[Array] Historical ATR Data",
            },
            {
              name: "Trigger High Vol",
              desc: "[Boolean] When The ATR Value Exceeds The Designated High Volatility Level",
            },
            {
              name: "Trigger Low Vol",
              desc: "[Boolean] When The ATR Value Falls Below The Designated Low Volatility Level",
            },
          ],
        },
      ],
    },
    {
      name: "Deformers",
      active: false,
      soon: true,
      subNodes: [],
    },
    {
      name: "Logic",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "And Node",
          fav: false,
          type: "And",
        },
      ],
    },
    {
      name: "Account",
      active: false,
      soon: false,
      subNodes: [],
    },
    {
      name: "Triggers",
      active: false,
      soon: true,
      subNodes: [],
    },
    {
      name: "Execution",
      active: false,
      soon: false,
      subNodes: [
        {
          name: "Execute Order Node",
          fav: false,
          type: "Execute_Order",
        },
      ],
    },
    {
      name: "Alerts",
      active: false,
      soon: true,
      subNodes: [],
    },
    {
      name: "API",
      active: false,
      soon: true,
      subNodes: [],
    },
  ]);

  const [nodesSelected, setNodesSelected] = useState(nodes[0]?.subNodes[0]);
  const addNode = (nodeType) => {
    if (nodeType.includes(" ")) {
      nodeType = nodeType.replace(" ", "_");
    }
    const node = [];
    const newNode = {
      id: getId(),
      type: nodeType,
      position: {
        x: Xpos,
        y: Ypos,
      },
      data: { label: `AssetData node` },
      zIndex: id,
    };
    node.push(newNode.id);
    setXpos(Xpos + 300);
    addNodes(newNode);
    toast(nodesSelected?.name + " Added");
  };
  useEffect(() => {
    setXpos(-x / zoom + 300);
    setYpos(-y / zoom + 300);
  }, [x, y, zoom]);
  useEffect(() => {
    if (!data?.searchInput) {
      setNodes(nodesMain);
    } else {
      const filteredNodes = nodesMain.map((node) => {
        return {
          ...node,
          subNodes: node.subNodes.filter((subNode) => {
            return subNode.name
              .toLowerCase()
              .includes(data?.searchInput.toLowerCase());
          }),
        };
      });
      setNodes(filteredNodes);
    }
  }, [data?.searchInput]);

  return (
    <>
      <motion.div
        className="nodepopUp_main"
        animate={{
          display: show ? "flex" : "none",
        }}
      >
        <div className="nodepopUp_main_bg" onClick={() => setShow(false)}></div>
        <motion.div
          initial={{
            opacity: 0,
            translateY: 80,
          }}
          animate={{
            filter: show ? "blur(0px)" : "blur(5px)",
            opacity: show ? 1 : 0,
            translateY: show ? 0 : 80,
          }}
          className="nodepopUp_main_div"
        >
          <div className="nodePopUp_left">
            <div className="nodePopUp_left_top">
              <div className="nodePopUp_left_top_left">Nodes</div>
              <div className="nodePopUp_left_top_right">Patches</div>
              <div className="soon_span">Soon</div>
            </div>
            <div className="nodePopUp_search">
              <img src={search} className="search_img" />
              <input
                placeholder={"Search Nodes"}
                className="search_input"
                value={data.searchInput}
                onChange={(e) =>
                  setData({
                    ...data,
                    searchInput: e.target.value,
                  })
                }
              />
            </div>
            <div className="nodePopUp_bottom">
              <div className="nodePopUp_bottom_left_text">
                <div
                  className={
                    data.selectedNodeType == null
                      ? "node_group_name selected_type"
                      : "node_group_name"
                  }
                  onClick={() => {
                    setData({
                      ...data,
                      selectedNodeType: null,
                    });
                    setNodesSelected(nodes[0]?.subNodes[0]);
                  }}
                >
                  All Nodes
                  <span>
                    <img
                      src={plus}
                      style={{
                        opacity: 0,
                      }}
                      className="plus_img"
                      alt="add"
                    />
                  </span>
                </div>

                {nodes?.map((node, index) => {
                  return (
                    <div
                      className={
                        data.selectedNodeType === index && !node.soon
                          ? "node_group_name selected_type"
                          : !node?.soon
                          ? "node_group_name"
                          : "node_group_name node_group_name_soon_div"
                      }
                      onClick={() => {
                        if (!node.soon) {
                          setData({
                            ...data,
                            selectedNodeType: index,
                          });
                          setNodesSelected(node.subNodes[0]);
                        }
                      }}
                      key={index}
                    >
                      {node.name}
                      {node?.soon && (
                        <div className="node_group_name_soon"></div>
                      )}

                      <span>
                        <img
                          src={plus}
                          style={{
                            opacity: 0,
                          }}
                          className="plus_img"
                          alt="add"
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
              <hr className="divider_nodes" />
              <div className="nodePopUp_bottom_right_text">
                {data?.selectedNodeType != null
                  ? nodes[data?.selectedNodeType]?.subNodes?.map(
                      (node, index) => {
                        return (
                          <div
                            onClick={(e) => {
                              if (nodesSelected?.name == node?.name) {
                                addNode(node?.type);
                              } else setNodesSelected(node);
                            }}
                            className={
                              nodesSelected?.name == node?.name
                                ? "sub_node_info sub_node_info_selected"
                                : "sub_node_info "
                            }
                          >
                            <div
                              className={"sub_node"}
                              key={index}
                              // onClick={(e) => addNode(e, node)}
                            >
                              {node?.name}
                              <span>
                                {node?.fav ? (
                                  <img
                                    src={star}
                                    className="star_img"
                                    alt="favourite"
                                  />
                                ) : null}
                              </span>
                            </div>

                            <span>
                              {nodesSelected?.name == node?.name ? (
                                <motion.img
                                  initial={{
                                    opacity: 0,
                                    scale: 0,
                                  }}
                                  whileHover={{
                                    scale: 1.1,
                                  }}
                                  animate={{
                                    opacity: 1,
                                    scale: 1,
                                  }}
                                  transition={{
                                    duration: 0.2,
                                    ease: "easeInOut",
                                  }}
                                  src={plus}
                                  className="plus_img"
                                  alt="add"
                                />
                              ) : (
                                <img
                                  src={plus}
                                  style={{
                                    opacity: 0,
                                  }}
                                  className="plus_img"
                                  alt="add"
                                />
                              )}
                            </span>
                          </div>
                        );
                      }
                    )
                  : nodes?.map((node, index) => {
                      return node.subNodes.map((subNode) => {
                        return (
                          <div
                            onClick={(e) => {
                              if (nodesSelected?.name == subNode?.name) {
                                addNode(subNode?.type);
                              } else setNodesSelected(subNode);
                            }}
                            className={
                              nodesSelected?.name == subNode?.name
                                ? "sub_node_info sub_node_info_selected"
                                : "sub_node_info "
                            }
                          >
                            <div className={"sub_node"} key={index}>
                              {subNode?.name}
                              <span>
                                {subNode?.fav ? (
                                  <img
                                    src={star}
                                    className="star_img"
                                    alt="favourite"
                                  />
                                ) : null}
                              </span>
                            </div>
                            <span>
                              {nodesSelected?.name == subNode?.name ? (
                                <motion.img
                                  initial={{
                                    opacity: 0,
                                    scale: 0,
                                  }}
                                  whileHover={{
                                    scale: 1.1,
                                  }}
                                  animate={{
                                    opacity: 1,
                                    scale: 1,
                                  }}
                                  transition={{
                                    duration: 0.2,
                                    ease: "easeInOut",
                                  }}
                                  src={plus}
                                  className="plus_img"
                                  alt="add"
                                />
                              ) : (
                                <img
                                  src={plus}
                                  style={{
                                    opacity: 0,
                                  }}
                                  className="plus_img"
                                  alt="add"
                                />
                              )}
                            </span>
                          </div>
                        );
                      });
                    })}
              </div>
            </div>
          </div>
          <div className="nodePopUp_right">
            <div className="nodePopUp_title">
              {nodesSelected?.name}
              <img src={nodesSelected?.fav ? star : star2} />
            </div>
            <div className="nodePopUp_right_body">
              <div className="nodePopUp_description">
                {nodesSelected?.description}
              </div>
              <img src={nodesSelected?.img} className="nodePopUp_img" />
              <div className="nodePopUp_inputs">
                <div className="nodePopUp_inputs_title">Node Inputs</div>
                {nodesSelected?.inputs?.map((input, index) => {
                  return (
                    <div className="nodePopUp_input" key={index}>
                      <div className="nodePopUp_input_name">{input.name}</div>
                      <div className="nodePopUp_input_desc">{input.desc}</div>
                    </div>
                  );
                })}
              </div>
              <div className="nodePopUp_inputs">
                <div className="nodePopUp_inputs_title">Node Outputs</div>
                {nodesSelected?.outputs?.map((output, index) => {
                  return (
                    <div className="nodePopUp_input" key={index}>
                      <div className="nodePopUp_input_name">{output.name}</div>
                      <div className="nodePopUp_input_desc">{output.desc}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}

export default NodesPopUp;

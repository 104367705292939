//get rsi values

import { axiosReq } from "../../Utils";
import { RSINodeActions } from "./RSINodeReducer";

export const getRsiValues = (id, data, nodeId) => async (dispatch) => {
  dispatch(RSINodeActions.getRsiValuesRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/indicator-values/rsiCalculation/" + id,
      {},
      {},
      { period: data?.period, inputSource: data?.inputSource }
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(
        RSINodeActions.getRsiValuesSuccess({
          nodeId: nodeId,
          data: res.data,
        })
      );
    } else {
      dispatch(RSINodeActions.getRsiValuesError(res?.data?.message));
    }
  });
};

export const getRsiLiveValues =
  (id, data, nodeId, prices) => async (dispatch) => {
    dispatch(RSINodeActions.getRsiLiveValuesRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/indicator-values/rsiLiveData/" + id,
        {},
        {
          ...prices,
        },
        { period: data?.period, inputSource: data?.inputSource }
      )
    ).then((res) => {
      if (res?.success) {
        dispatch(
          RSINodeActions.getRsiLiveValuesSuccess({
            nodeId: nodeId,
            data: res.data,
          })
        );
      } else {
        dispatch(RSINodeActions.getRsiLiveValuesError(res?.data?.message));
      }
    });
  };

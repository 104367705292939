import React, { useEffect } from "react";
import "./Loader.scss";
import { ReactComponent as Logo } from "../../Assets/Images/logo_big.svg";
import gsap from "gsap";

function Loader({ width }) {
  useEffect(() => {
    let tl = gsap.timeline({ repeat: -1 });

    tl.to("#Path_311", {
      y: "-=10",
    })
      .add([
        gsap.to("#Path_312", {
          y: "+=10",
        }),
        gsap.fromTo(".loader_text", { opacity: 0 }, { opacity: 1 }),
      ])
      .to("#main_svg", {
        rotate: 180,
      })
      .add([
        gsap.fromTo(
          ".loader_text",
          { opacity: 1 },
          {
            opacity: 0,
          }
        ),
        gsap.to("#Path_311", {
          y: "+=10",
        }),
      ])
      .to("#Path_312", {
        y: "-=10",
      })
      .to("#Path_311", {
        y: "-=10",
      })
      .add([
        gsap.to("#Path_312", {
          y: "+=10",
        }),
        gsap.fromTo(".loader_text", { opacity: 0 }, { opacity: 1 }),
      ])
      .to("#main_svg", {
        rotate: 0,
      })
      .add([
        gsap.fromTo(
          ".loader_text",
          { opacity: 1 },
          {
            opacity: 0,
          }
        ),
        gsap.to("#Path_311", {
          y: "+=10",
        }),
      ])
      .to("#Path_312", {
        y: "-=10",
      });
  }, []);

  return (
    <div className="loader_main">
      <Logo width={width || "100%"} />
    </div>
  );
}

export default Loader;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import { RSIData } from "./RSIData";
import "./RSINode.scss";
import {
  getRsiLiveValues,
  getRsiValues,
} from "../../Redux/RSINode/RSINodeActions";
import { socketIo } from "../../App";

function RSINode() {
  const [period, setPeriod] = useState();
  const [periodDisabled, setPeriodDisabled] = useState(false);
  const [title, setTitle] = useState("RSI Node");
  const [pricesDisabled, setPricesDisabled] = useState(false);

  const [RSIFinal, setRSIFinal] = useState(0);
  const { rsiValues, rsiLiveValue } = useSelector(
    (state) => state.RSINodeReducer
  );
  const { historicalData } = useSelector((state) => state.assetNodeReducer);

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();

  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const [oversold, setOversold] = useState(false);
  const [overbought, setOverbought] = useState(false);
  const [oversoldLevel, setOversoldLevel] = useState();
  const [overboughtLevel, setOverboughtLevel] = useState();
  const [source, setSource] = useState();
  const [assetId, setAssetId] = useState();
  const nodeId = useNodeId();
  const [type, setType] = useState("Live");
  const node = getNode(nodeId);
  const dispatch = useDispatch();
  const [sourceId, setSourceId] = useState();

  const [soldDisabled, setSoldDisabled] = useState(false);
  const [boughtDisabled, setBoughtDisabled] = useState(false);

  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  useEffect(() => {
    if (rsiLiveValue?.[nodeId]) {
      setRSIFinal(parseFloat(rsiLiveValue?.[nodeId]));
    } else if (rsiValues?.[nodeId]) {
      setRSIFinal(
        parseFloat(rsiValues?.[nodeId][rsiValues?.[nodeId].length - 1]?.value)
      );
    } else {
      setRSIFinal(0);
    }
  }, [rsiValues?.[nodeId], rsiLiveValue?.[nodeId]]);

  //get prices and period
  useEffect(() => {
    const foundPrice = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_rsi_input_1/array"
    );
    if (foundPrice) {
      let priceTarget = foundPrice.target;

      let sourceNode = foundPrice.source;

      setSourceId(sourceNode);
      let priceHandle = foundPrice.targetHandle;
      if (nodeData[priceTarget]) {
        setAssetId(nodeData[sourceNode]?.data?.assetBotId);
        setSource(nodeData[priceTarget].data[priceHandle]);
      }
      setPricesDisabled(true);
    } else {
      setPricesDisabled(false);
    }

    const foundPeriod = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_rsi_input_2/int"
    );
    if (foundPeriod) {
      let periodTarget = foundPeriod.target;
      let periodHandle = foundPeriod.targetHandle;
      if (nodeData[periodTarget]) {
        setPeriod(parseInt(nodeData[periodTarget].data[periodHandle]));
      }
      setPeriodDisabled(true);
    } else {
      setPeriodDisabled(false);
    }

    const foundOverbought = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_rsi_input_bought/int"
    );

    if (foundOverbought) {
      let overboughtTarget = foundOverbought.target;
      let overboughtHandle = foundOverbought.targetHandle;
      if (nodeData[overboughtTarget]) {
        setOverboughtLevel(
          parseInt(nodeData[overboughtTarget].data[overboughtHandle])
        );
      }
      setBoughtDisabled(true);
    } else {
      setBoughtDisabled(false);
    }

    const foundOversold = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_rsi_input_sold/int"
    );
    if (foundOversold) {
      let oversoldTarget = foundOversold.target;
      let oversoldHandle = foundOversold.targetHandle;
      if (nodeData[oversoldTarget]) {
        setOversoldLevel(
          parseInt(nodeData[oversoldTarget].data[oversoldHandle])
        );
      }
      setSoldDisabled(true);
    } else {
      setSoldDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  useEffect(() => {
    if (period && assetId && source) {
      dispatch(
        getRsiValues(assetId, { period: period, inputSource: source }, nodeId)
      );
    }
  }, [period, assetId, source, historicalData[sourceId], sourceId]);

  //to set data to the connected edges //STEP3
  useEffect(() => {
    const findRSI = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_rsi_output_1/number"
    );
    const oversold = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_rsi_output_oversold/boolean"
    );
    const overbought = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_rsi_output_overbought/boolean"
    );
    const overboughtLevel1 = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_rsi_output_bought/number"
    );
    const oversoldLevel1 = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_rsi_output_sold/number"
    );

    if (findRSI && findRSI.length > 0) {
      findRSI.map((el) => {
        let RSIHandle = el.targetHandle;
        let RSITarget = el.target;

        dispatch(
          nodeDataUpdate(RSITarget, {
            [RSIHandle]: parseFloat(RSIFinal),
          })
        );
      });
    }

    const findPeriod = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_rsi_output_period/number"
    );

    if (findPeriod && findPeriod.length > 0) {
      findPeriod.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: period,
          })
        );
      });
    }

    if (oversold && oversold.length > 0) {
      oversold.map((el) => {
        let boolHandle = el.targetHandle;
        let boolTarget = el.target;

        dispatch(
          nodeDataUpdate(boolTarget, {
            [boolHandle]: parseFloat(RSIFinal) < oversoldLevel,
          })
        );
      });
    }

    if (overbought && overbought.length > 0) {
      overbought.map((el) => {
        let boolHandle = el.targetHandle;
        let boolTarget = el.target;

        dispatch(
          nodeDataUpdate(boolTarget, {
            [boolHandle]: parseFloat(RSIFinal) > overboughtLevel,
          })
        );
      });
    }

    if (overboughtLevel1 && overboughtLevel1.length > 0) {
      overboughtLevel1.map((el) => {
        let levelHandle = el.targetHandle;
        let levelTarget = el.target;

        dispatch(
          nodeDataUpdate(levelTarget, {
            [levelHandle]: overboughtLevel,
          })
        );
      });
    }

    if (oversoldLevel1 && oversoldLevel1.length > 0) {
      oversoldLevel1.map((el) => {
        let levelHandle = el.targetHandle;
        let levelTarget = el.target;

        dispatch(
          nodeDataUpdate(levelTarget, {
            [levelHandle]: oversoldLevel,
          })
        );
      });
    }
  }, [
    connectedEdges,
    RSIFinal,
    period,
    overboughtLevel,
    oversoldLevel,
    overbought,
    oversold,
  ]);

  //setting the props //STEP 1
  useEffect(() => {
    var periodHtml = document.getElementById("period");
    var rsiHtml = document.getElementById("rsi_value");

    var periodHtmlValue = periodHtml?.outerHTML;
    var rsiHtmlValue = rsiHtml?.outerHTML;

    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          period: periodHtmlValue,
          RSI_Value: rsiHtmlValue,
        },
        period: period,
        RSI_Value: parseFloat(RSIFinal)?.toFixed(2),

        triggerType: type,
        overbought: overbought,
        oversold: oversold,
        oversoldValue: oversoldLevel,
        overboughtValue: overboughtLevel,
        label: title,
        info: {
          ...RSIData,
        },
      })
    );
    dispatch(
      nodeDataUpdate(nodeId, {
        [nodeId + "_rsi_output_1/number"]: parseFloat(RSIFinal)?.toFixed(2),
      })
    );
  }, [
    period,
    title,
    overbought,
    overboughtLevel,
    oversold,
    oversoldLevel,
    RSIFinal,
  ]);

  //getting the props //STEP 2
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.period != period) {
        setPeriod(nodeProps[nodeId].prop.period);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }

      if (nodeProps[nodeId]?.prop?.overbought !== overbought) {
        setOverbought(nodeProps[nodeId]?.prop?.overbought);
      }
      if (nodeProps[nodeId]?.prop?.oversold !== oversold)
        setOversold(nodeProps[nodeId]?.prop?.oversold);

      if (nodeProps[nodeId]?.prop?.triggerType !== type) {
        setType(nodeProps[nodeId]?.prop?.triggerType);
      }
      if (nodeProps[nodeId]?.prop?.overboughtValue !== overboughtLevel) {
        setOverboughtLevel(nodeProps[nodeId]?.prop?.overboughtValue);
      }
      if (nodeProps[nodeId]?.prop?.oversoldValue !== oversoldLevel) {
        setOversoldLevel(nodeProps[nodeId]?.prop?.oversoldValue);
      }
    }
  }, [nodeProps]);

  useEffect(() => {
    socketIo.on("sendLiveCandle", (msg) => {
      if (msg?.botAssetId == assetId && period && source) {
        dispatch(
          getRsiLiveValues(
            assetId,
            { period: period, inputSource: source },
            nodeId,
            msg?.candle
          )
        );
      }
    });
    return () => {
      socketIo.off("sendLiveCandle");
    };
  }, [assetId, period, source, nodeId, historicalData[sourceId]]);
  return (
    <>
      <NodeBox
        title={title}
        setTitle={setTitle}
        color="var(--purple)"
        selected={node?.selected}
        body={
          <div className="rsi_body">
            <div style={{ position: "relative" }}>
              <ArrayHandle
                type="target"
                id={nodeId + "_rsi_input_1/array"}
                style={{
                  left: "-25px",
                  top: "10px",
                }}
                isConnectable={!pricesDisabled}
                required={true}
              />
              <div className="rsi_body_inner_title_main">
                <p className="rsi_body_inner_title_p_main">Array</p>
              </div>
            </div>

            <div className="rsi_body_inner_title">
              <img src={arrowDown} />
              <p className="rsi_body_inner_title_p">RSI</p>
            </div>
            <div className="rsi_body_inner_body">
              <div className="handleDiv" style={{ position: "relative" }}>
                <div className="handleDivPHandle">
                  <div className="border_Div firstBorder_Div"></div>
                  <NumberHandle
                    type="target"
                    id={nodeId + "_rsi_input_2/int"}
                    style={{
                      left: "-25px",
                    }}
                    isConnectable={!periodDisabled}
                  />
                </div>
                <div className="inputs_div">
                  <p className="HandleDivP">Period</p>
                  <input
                    id="period"
                    type="number"
                    className={
                      periodDisabled
                        ? "input_field input_field_disabled"
                        : "input_field"
                    }
                    value={period}
                    onChange={(e) => setPeriod(parseInt(e.target.value))}
                    disabled={periodDisabled}
                  />
                </div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_rsi_output_period/number"}
                  style={{ right: "-25px" }}
                />
              </div>
              <div className="handleDiv" style={{ position: "relative" }}>
                <div className="handleDivPHandle">
                  <div className="border_Div"></div>

                  <NumberHandle
                    type="source"
                    id={nodeId + "_rsi_output_1/number"}
                    style={{ right: "-25px", top: "5px" }}
                  />
                  <ArrayHandle
                    type="source"
                    id={nodeId + "_rsi_output_2/array"}
                    style={{
                      right: "-25px",
                      top: "25px",
                    }}
                  />
                </div>
                <div className="inputs_div">
                  <p className="HandleDivP">Value</p>
                  <input
                    id="rsi_value"
                    type="number"
                    className="input_field input_field_disabled"
                    value={RSIFinal?.toFixed(2)}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            {oversold && (
              <div className="oversold_rsi">
                <div className="rsi_body_inner_title">
                  <img src={arrowDown} />
                  <p className="rsi_body_inner_title_p">Oversold</p>
                </div>
                <div className="rsi_body_inner_body">
                  <div className="handleDiv" style={{ position: "relative" }}>
                    <div className="handleDivPHandle">
                      <div className="border_Div firstBorder_Div"></div>
                      <NumberHandle
                        type="target"
                        id={nodeId + "_rsi_input_sold/int"}
                        style={{
                          left: "-25px",
                        }}
                        isConnectable={!soldDisabled}
                      />
                    </div>
                    <div className="inputs_div">
                      <p className="HandleDivP">Level</p>
                      <input
                        id="period"
                        type="number"
                        className={
                          soldDisabled
                            ? "input_field input_field_disabled"
                            : "input_field"
                        }
                        value={oversoldLevel}
                        onChange={(e) =>
                          setOversoldLevel(parseInt(e.target.value))
                        }
                        disabled={soldDisabled}
                      />
                    </div>

                    <NumberHandle
                      type="source"
                      id={nodeId + "_rsi_output_sold/number"}
                      style={{ right: "-25px" }}
                    />
                  </div>
                  <div className="handleDiv" style={{ position: "relative" }}>
                    <div className="handleDivPHandle">
                      <div className="border_Div"></div>

                      <BooleanHandle
                        type="source"
                        id={nodeId + "_rsi_output_oversold/boolean"}
                        style={{
                          right: "-25px",
                          top: "18px",
                        }}
                      />
                    </div>
                    <div className="inputs_div">
                      <p className="HandleDivP">Trigger</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {overbought && (
              <div className="overbought_rsi">
                <div className="rsi_body_inner_title">
                  <img src={arrowDown} />
                  <p className="rsi_body_inner_title_p">Overbought</p>
                </div>
                <div className="rsi_body_inner_body">
                  <div className="handleDiv" style={{ position: "relative" }}>
                    <div className="handleDivPHandle">
                      <div className="border_Div firstBorder_Div"></div>
                      <NumberHandle
                        type="target"
                        id={nodeId + "_rsi_input_bought/int"}
                        style={{
                          left: "-25px",
                        }}
                        isConnectable={!boughtDisabled}
                      />
                    </div>
                    <div className="inputs_div">
                      <p className="HandleDivP">Level</p>
                      <input
                        id="period"
                        type="number"
                        className={
                          boughtDisabled
                            ? "input_field input_field_disabled"
                            : "input_field"
                        }
                        value={overboughtLevel}
                        onChange={(e) =>
                          setOverboughtLevel(parseInt(e.target.value))
                        }
                        disabled={boughtDisabled}
                      />
                    </div>

                    <NumberHandle
                      type="source"
                      id={nodeId + "_rsi_output_bought/number"}
                      style={{ right: "-25px" }}
                    />
                  </div>
                  <div className="handleDiv" style={{ position: "relative" }}>
                    <div className="handleDivPHandle">
                      <div className="border_Div"></div>

                      <BooleanHandle
                        type="source"
                        id={nodeId + "_rsi_output_overbought/boolean"}
                        style={{
                          right: "-25px",
                          top: "18px",
                        }}
                      />
                    </div>
                    <div className="inputs_div">
                      <p className="HandleDivP">Trigger</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
}

export default RSINode;

import React, { useEffect, useState } from "react";
import "./TablePopup.scss";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
import { getHistoricalData } from "../../Redux/AssetNode/AssetNodeActions";
import { socketIo } from "../../App";

function TablePopup({ information, show, setShow }) {
  const [info, setInfo] = useState();
  useEffect(() => {
    if (!information) return;
    setInfo(information);
  }, [information]);

  const {
    allAssets,
    historicalData,
    getHistoricalDataLoading,
    updateBotAssetLoading,
    createBotAssetLoading,
  } = useSelector((state) => state.assetNodeReducer);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    if (!historicalData[info?.nodeId]) return;
    setPaginatedData(
      [...historicalData[info?.nodeId]].reverse().slice(offset, offset + limit)
    );
  }, [historicalData, info, offset, limit]);

  const dispatch = useDispatch();

  useEffect(() => {
    show && dispatch(getHistoricalData(info?.id, info?.nodeId));
  }, [show]);

  const [livePrice, setLivePrice] = useState();

  useEffect(() => {
    socketIo.on("sendLiveCandle", (msg) => {
      if (msg.botAssetId === info?.id) setLivePrice(msg?.candle?.close);
    });
    return () => {
      socketIo.off("sendLiveCandle");
    };
  }, [historicalData]);

  return (
    <motion.div
      animate={{
        opacity: show ? 1 : 0,
        x: show ? 0 : 100,
        display: show ? "block" : "none",
      }}
      initial={{ opacity: 0, x: 100 }}
      className="learn_more_pop"
    >
      {show && (
        <>
          <div className="learn_more_header">
            <div
              className="learn_more_title"
              style={{
                color: info?.color,
              }}
            >
              {allAssets.find((asset) => asset.id === info?.title)?.name ||
                info?.title}
            </div>
            <div
              style={{
                fontFamily: "Techead_Bold",
                fontSize: "1.1rem",
              }}
            >
              ${livePrice?.toLocaleString()}
            </div>
          </div>
          <div className="learn_more_body">
            {getHistoricalDataLoading ||
            updateBotAssetLoading ||
            createBotAssetLoading ? (
              <div style={{ width: "38rem", height: "calc( 100vh - 15rem)" }}>
                <Loader width={"100%"} />
              </div>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Open</th>
                      <th>Low</th>
                      <th>High</th>
                      <th>Close</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {new Date(data.time).toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            })}
                          </td>
                          <td>{data.open}</td>
                          <td>{data.low}</td>
                          <td>{data.high}</td>
                          <td>{data.close}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ marginTop: "2rem" }}>
                  <Pagination
                    hasPrev={offset > 0}
                    hasNext={offset < 1000}
                    pageNumber={pageNumber}
                    limit={limit}
                    offset={offset}
                    setOffset={setOffset}
                    count={1000}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </motion.div>
  );
}

export default TablePopup;

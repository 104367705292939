//get rsi values
import { axiosReq } from "../../Utils";
import { ATRNodeActions } from "./ATRNodeReducer";
export const getATRValues = (id, data, nodeId) => async (dispatch) => {
  dispatch(ATRNodeActions.getatrValuesRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/indicator-values/atrCalculation/" + id,
      {},
      {},
      { period: data?.period, inputSource: data?.inputSource }
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(
        ATRNodeActions.getatrValuesSuccess({
          nodeId: nodeId,
          data: res.data,
        })
      );
    } else {
      dispatch(ATRNodeActions.getatrValuesError(res?.data?.message));
    }
  });
};

export const getATRLiveValues =
  (id, data, nodeId, prices) => async (dispatch) => {
    dispatch(ATRNodeActions.getatrLiveValuesRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/indicator-values/atrLiveData/" + id,
        {},
        {
          ...prices,
        },
        { period: data?.period, inputSource: data?.inputSource }
      )
    ).then((res) => {
      if (res?.success) {
        dispatch(
          ATRNodeActions.getatrLiveValuesSuccess({
            nodeId: nodeId,
            data: res.data,
          })
        );
      } else {
        dispatch(ATRNodeActions.getatrLiveValuesError(res?.data?.message));
      }
    });
  };

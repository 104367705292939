import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import "./ATRNode.scss";
import { ATRData } from "./ATRData";
import ObjectHandle from "../../Components/CustomHandles/ObjectHandle/ObjectHandle";
import {
  getATRLiveValues,
  getATRValues,
} from "../../Redux/ATRNode/ATRNodeActions";
import { socketIo } from "../../App";

function ATRNode() {
  const [period, setPeriod] = useState();
  const { atrValues, atrLiveValue } = useSelector(
    (state) => state.ATRNodeReducer
  );
  const [periodDisabled, setPeriodDisabled] = useState(false);
  const [title, setTitle] = useState("ATR Node");
  const [pricesDisabled, setPricesDisabled] = useState(false);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const nodeId = useNodeId();
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const node = getNode(nodeId);
  const [low, setLow] = useState(false);
  const [high, setHigh] = useState(false);
  const [highLevel, setHighLevel] = useState();
  const [lowLevel, setLowLevel] = useState();
  const [type, setType] = useState();
  const [levelType, setLevelType] = useState();
  const [connectedEdges, setConnectedEdges] = useState([]);
  const { edges } = useSelector((state) => state.flow);
  const [atrFinal, setAtrFinal] = useState();
  const dispatch = useDispatch();
  const [source, setSource] = useState();
  const [assetId, setAssetId] = useState();
  const [sourceId, setSourceId] = useState();
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const [lowDisabled, setLowDisabled] = useState(false);
  const [highDisabled, setHighDisabled] = useState(false);
  const { historicalData } = useSelector((state) => state.assetNodeReducer);

  //step 1 setting the props
  useEffect(() => {
    var periodHtml = document.getElementById("atr_period");
    var atrHtml = document.getElementById("atr_value");

    var periodHtmlValue = periodHtml?.outerHTML;
    var atrValueHtml = atrHtml?.outerHTML;
    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          period: periodHtmlValue,
          ATR_Value: atrValueHtml,
        },
        period: period,
        ATR_Value: parseFloat(atrFinal)?.toFixed(2),
        lowLevel: lowLevel,
        highLevel: highLevel,
        low: low,
        high: high,
        levelType: levelType,
        triggerType: type,
        label: title,
        info: {
          ...ATRData,
        },
      })
    );
    // dispatch(
    //   nodeDataUpdate(nodeId, {
    //     [nodeId + "_rsi_output_1/number"]: RSIFinal?.toFixed(2),
    //   })
    // );
  }, [
    period,
    title,
    low,
    high,
    highLevel,
    lowLevel,
    type,
    levelType,
    atrFinal,
  ]);

  //step 2 getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.period != period) {
        setPeriod(nodeProps[nodeId].prop.period);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
      if (nodeProps[nodeId]?.prop?.lowLevel != lowLevel) {
        setLowLevel(nodeProps[nodeId].prop.lowLevel);
      }
      if (nodeProps[nodeId]?.prop?.highLevel != highLevel) {
        setHighLevel(nodeProps[nodeId].prop.highLevel);
      }
      if (nodeProps[nodeId]?.prop?.low != low) {
        setLow(nodeProps[nodeId].prop.low);
      }
      if (nodeProps[nodeId]?.prop?.high != high) {
        setHigh(nodeProps[nodeId].prop.high);
      }
      if (nodeProps[nodeId]?.prop?.levelType != levelType) {
        setLevelType(nodeProps[nodeId].prop.levelType);
      }
      if (nodeProps[nodeId]?.prop?.triggerType != type) {
        setType(nodeProps[nodeId].prop.triggerType);
      }
    }
  }, [nodeProps]);

  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //step 3 get connected nodes data to disable the inputs and get the data
  useEffect(() => {
    const foundPrice = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_atr_input_asset/object"
    );
    if (foundPrice) {
      let priceTarget = foundPrice.target;
      let sourceNode = foundPrice.source;
      setSourceId(sourceNode);
      let priceHandle = foundPrice.targetHandle;
      if (nodeData[priceTarget]) {
        setAssetId(nodeData[sourceNode]?.data?.assetBotId);
        setSource(nodeData[priceTarget].data[priceHandle]);
      }
      setPricesDisabled(true);
    } else {
      setPricesDisabled(false);
    }
    const foundPeriod = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_atr_input_period/int"
    );
    if (foundPeriod) {
      let periodTarget = foundPeriod.target;
      let periodHandle = foundPeriod.targetHandle;
      if (nodeData[periodTarget]) {
        setPeriod(parseInt(nodeData[periodTarget].data[periodHandle]));
      }
      setPeriodDisabled(true);
    } else {
      setPeriodDisabled(false);
    }

    const foundHighLevel = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_atr_input_highLevel/int"
    );
    if (foundHighLevel) {
      let highLevelTarget = foundHighLevel.target;
      let highLevelHandle = foundHighLevel.targetHandle;
      if (nodeData[highLevelTarget]) {
        setHighLevel(parseInt(nodeData[highLevelTarget].data[highLevelHandle]));
      }
      setHighDisabled(true);
    } else {
      setHighDisabled(false);
    }

    const foundLowLevel = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_atr_input_lowLevel/int"
    );
    if (foundLowLevel) {
      let lowLevelTarget = foundLowLevel.target;
      let lowLevelHandle = foundLowLevel.targetHandle;
      if (nodeData[lowLevelTarget]) {
        setLowLevel(parseInt(nodeData[lowLevelTarget].data[lowLevelHandle]));
      }
      setLowDisabled(true);
    } else {
      setLowDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  //step 4 set data to connected nodes
  useEffect(() => {
    const findPeriod = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_atr_output_period/number"
    );
    if (findPeriod && findPeriod.length > 0) {
      findPeriod.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: period,
          })
        );
      });
    }
    const findValue = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_atr_output_value/number"
    );
    if (findValue && findValue.length > 0) {
      findValue.map((el) => {
        let ValueHandle = el.targetHandle;
        let ValueTarget = el.target;

        dispatch(
          nodeDataUpdate(ValueTarget, {
            [ValueHandle]: parseFloat(atrFinal)?.toFixed(2),
          })
        );
      });
    }
    const findHighLevel = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_atr_output_highLevel/number"
    );
    if (findHighLevel && findHighLevel.length > 0) {
      findHighLevel.map((el) => {
        let HighLevelHandle = el.targetHandle;
        let HighLevelTarget = el.target;

        dispatch(
          nodeDataUpdate(HighLevelTarget, {
            [HighLevelHandle]: highLevel,
          })
        );
      });
    }

    const findLowLevel = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_atr_output_lowLevel/number"
    );
    if (findLowLevel && findLowLevel.length > 0) {
      findLowLevel.map((el) => {
        let LowLevelHandle = el.targetHandle;
        let LowLevelTarget = el.target;

        dispatch(
          nodeDataUpdate(LowLevelTarget, {
            [LowLevelHandle]: lowLevel,
          })
        );
      });
    }

    const findHighTrigger = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_atr_output_highLevel/boolean"
    );
    if (findHighTrigger && findHighTrigger.length > 0 && high) {
      findHighTrigger.map((el) => {
        let HighTriggerHandle = el.targetHandle;
        let HighTriggerTarget = el.target;
        if (levelType == "Number") {
          dispatch(
            nodeDataUpdate(HighTriggerTarget, {
              [HighTriggerHandle]: parseFloat(atrFinal) > highLevel,
            })
          );
        } else if (levelType == "Percentage") {
          dispatch(
            nodeDataUpdate(HighTriggerTarget, {
              [HighTriggerHandle]:
                parseFloat(atrFinal) > (highLevel / 100) * parseFloat(atrFinal),
            })
          );
        }
      });
    }
    const findLowTrigger = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_atr_output_lowLevel/boolean"
    );
    if (findLowTrigger && findLowTrigger.length > 0 && low) {
      findLowTrigger.map((el) => {
        let LowTriggerHandle = el.targetHandle;
        let LowTriggerTarget = el.target;
        if (levelType == "Number") {
          dispatch(
            nodeDataUpdate(LowTriggerTarget, {
              [LowTriggerHandle]: parseFloat(atrFinal) < lowLevel,
            })
          );
        } else if (levelType == "Percentage") {
          dispatch(
            nodeDataUpdate(LowTriggerTarget, {
              [LowTriggerHandle]:
                parseFloat(atrFinal) < (lowLevel / 100) * parseFloat(atrFinal),
            })
          );
        }
      });
    }
  }, [connectedEdges, period, atrFinal, highLevel, lowLevel, low, high]);

  //step 5 calculate the data
  useEffect(() => {
    if (period && assetId && source) {
      dispatch(
        getATRValues(assetId, { period: period, inputSource: source }, nodeId)
      );
    }
  }, [period, assetId, source, historicalData[sourceId], sourceId]);

  //update the states
  useEffect(() => {
    if (atrLiveValue[nodeId] && atrLiveValue[nodeId]) {
      setAtrFinal(parseFloat(atrLiveValue?.[nodeId]));
    } else if (atrValues?.[nodeId]) {
      setAtrFinal(
        parseFloat(atrValues?.[nodeId][atrValues?.[nodeId].length - 1]?.value)
      );
    } else {
      setAtrFinal(0);
    }
  }, [atrValues?.[nodeId], atrLiveValue[nodeId]]);

  useEffect(() => {
    socketIo.on("sendLiveCandle", (msg) => {
      if (msg?.botAssetId == assetId && period && source) {
        dispatch(
          getATRLiveValues(
            assetId,
            { period: period, inputSource: source },
            nodeId,
            msg?.candle
          )
        );
      }
    });
    return () => {
      socketIo.off("sendLiveCandle");
    };
  }, [assetId, period, source, nodeId, historicalData[sourceId]]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--purple)"
      selected={node?.selected}
      body={
        <div className="rsi_body">
          <div style={{ position: "relative" }}>
            <ObjectHandle
              type="target"
              id={nodeId + "_atr_input_asset/object"}
              style={{
                left: "-25px",
                top: "10px",
              }}
              isConnectable={!pricesDisabled}
              required={true}
            />
            <div className="rsi_body_inner_title_main">
              <p className="rsi_body_inner_title_p_main">Asset</p>
            </div>
          </div>

          <div className="rsi_body_inner_title">
            <img src={arrowDown} />
            <p className="rsi_body_inner_title_p">ATR</p>
          </div>
          <div className="rsi_body_inner_body">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  type="target"
                  id={nodeId + "_atr_input_period/int"}
                  style={{
                    left: "-25px",
                  }}
                  isConnectable={!periodDisabled}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Period</p>
                <input
                  id="atr_period"
                  type="number"
                  className={
                    periodDisabled
                      ? "input_field input_field_disabled"
                      : "input_field"
                  }
                  value={period}
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  disabled={periodDisabled}
                />
              </div>

              <NumberHandle
                type="source"
                id={nodeId + "_atr_output_period/number"}
                style={{ right: "-25px" }}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_atr_output_value/number"}
                  style={{ right: "-25px", top: "5px" }}
                />
                <ArrayHandle
                  type="source"
                  id={nodeId + "_atr_output_value/array"}
                  style={{
                    right: "-25px",
                    top: "25px",
                  }}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Value</p>
                <input
                  id="atr_value"
                  type="number"
                  className="input_field input_field_disabled"
                  value={atrFinal?.toFixed(2)}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          {high && (
            <div className="oversold_rsi">
              <div className="rsi_body_inner_title">
                <img src={arrowDown} />
                <p className="rsi_body_inner_title_p">High Volatility</p>
              </div>
              <div className="rsi_body_inner_body">
                <div className="handleDiv" style={{ position: "relative" }}>
                  <div className="handleDivPHandle">
                    <div className="border_Div firstBorder_Div"></div>
                    <NumberHandle
                      type="target"
                      id={nodeId + "_atr_input_highLevel/int"}
                      style={{
                        left: "-25px",
                      }}
                      isConnectable={!highDisabled}
                    />
                  </div>
                  <div className="inputs_div">
                    <p className="HandleDivP">Level</p>
                    <input
                      id="period"
                      type="number"
                      className={
                        highDisabled
                          ? "input_field input_field_disabled"
                          : "input_field"
                      }
                      value={highLevel}
                      onChange={(e) => setHighLevel(parseInt(e.target.value))}
                      disabled={highDisabled}
                    />
                  </div>

                  <NumberHandle
                    type="source"
                    id={nodeId + "_atr_output_highLevel/number"}
                    style={{ right: "-25px" }}
                  />
                </div>
                <div className="handleDiv" style={{ position: "relative" }}>
                  <div className="handleDivPHandle">
                    <div className="border_Div"></div>

                    <BooleanHandle
                      type="source"
                      id={nodeId + "_atr_output_highLevel/boolean"}
                      style={{
                        right: "-25px",
                        top: "18px",
                      }}
                    />
                  </div>
                  <div className="inputs_div">
                    <p className="HandleDivP">Trigger</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {low && (
            <div className="overbought_rsi">
              <div className="rsi_body_inner_title">
                <img src={arrowDown} />
                <p className="rsi_body_inner_title_p">Low Volatility</p>
              </div>
              <div className="rsi_body_inner_body">
                <div className="handleDiv" style={{ position: "relative" }}>
                  <div className="handleDivPHandle">
                    <div className="border_Div firstBorder_Div"></div>
                    <NumberHandle
                      type="target"
                      id={nodeId + "_atr_input_lowLevel/int"}
                      style={{
                        left: "-25px",
                      }}
                      isConnectable={!lowDisabled}
                    />
                  </div>
                  <div className="inputs_div">
                    <p className="HandleDivP">Level</p>
                    <input
                      id="period"
                      type="number"
                      className={
                        lowDisabled
                          ? "input_field input_field_disabled"
                          : "input_field"
                      }
                      value={lowLevel}
                      onChange={(e) => setLowLevel(parseInt(e.target.value))}
                      disabled={lowDisabled}
                    />
                  </div>

                  <NumberHandle
                    type="source"
                    id={nodeId + "_atr_output_lowLevel/number"}
                    style={{ right: "-25px" }}
                  />
                </div>
                <div className="handleDiv" style={{ position: "relative" }}>
                  <div className="handleDivPHandle">
                    <div className="border_Div"></div>

                    <BooleanHandle
                      type="source"
                      id={nodeId + "_atr_output_lowLevel/boolean"}
                      style={{
                        right: "-25px",
                        top: "18px",
                      }}
                    />
                  </div>
                  <div className="inputs_div">
                    <p className="HandleDivP">Trigger</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

export default ATRNode;

import React, { useState } from "react";
import { useNodeId, useReactFlow } from "reactflow";
import "./AssetDumb.scss";
import NumberHandle from "../../CustomHandles/NumberHandle/NumberHandle";
import NodeBox from "../../NodeBox/NodeBox";
import ArrayHandle from "../../CustomHandles/ArrayHandle/ArrayHandle";
import arrowDown from "../../../Assets/Images/whiteArrowDown.svg";

function AssetDumb() {
  //get all the nodes, the node i am in, the edges and the id of the node i am in
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const nodeId = useNodeId();
  const [title, setTitle] = useState("Assets Node");
  const node = getNode(nodeId && nodeId);

  return (
    <div>
      <div className="asset_landing_label">
        <div className="asset_landing_label_img">⤹</div>
        <div>drag me</div>
      </div>
      <NodeBox
      color="var(--orange)"
      title={title}
      setTitle={setTitle}
      middle={
        <ArrayHandle
          id={nodeId + "attr_output/array"}
          type="source"
          isConnectable={true}
          style={{
            right: "-7px",
            top: "33px",
          }}
        />
      }
      selected={node?.selected}
      body={
        <div className="AssetMainBody">
          <div className="MainLowerCustomDivArrowTitle">
            <img src={arrowDown} className="arrowDown" />
            <p className="MainLowerCustomDivOHLC">OHLC</p>
          </div>
          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  id={nodeId + "open_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Open</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  id={nodeId + "high_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">High</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <NumberHandle
                  id={nodeId + "low_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Low</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  id={nodeId + "close_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Close</p>
            </div>
          </div>
          <div className="MainLowerCustomDivArrowTitle">
            <img src={arrowDown} className="arrowDown" />
            <p className="MainLowerCustomDivOHLC">Volume</p>
          </div>
          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  id={nodeId + "volune_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Value</p>
            </div>
          </div>
          <div className="MainLowerCustomDivArrowTitle">
            <img src={arrowDown} className="arrowDown" />
            <p className="MainLowerCustomDivOHLC">Quote</p>
          </div>
          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
              <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  id={nodeId + "bid_output"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Bid</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
              <div className="border_Div"></div>
                <NumberHandle
                  id={nodeId + "ask_output"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Ask</p>
            </div>
          </div>
        </div>
      }
    />
    </div>
  );
}

export default AssetDumb;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import {
  getSmaLiveValues,
  getSmaValues,
} from "../../Redux/SMANode/SMANodeActions";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import { SMAData } from "./SMAData";
import "./SMANode.scss";
import { socketIo } from "../../App";
function SMANode() {
  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const nodeId = useNodeId();
  const [title, setTitle] = useState("SMA (20)");
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const [pricesDisabled, setPricesDisabled] = useState(false);
  const [periodDisabled, setPeriodDisabled] = useState(false);
  const [period, setPeriod] = useState();
  const [smaFinal, setSmaFinal] = useState();
  const [connectedEdges, setConnectedEdges] = useState([]);
  const [assetId, setAssetId] = useState();
  const [sourceId, setSourceId] = useState();
  const [source, setSource] = useState();
  const { smaValues, smaLiveValue } = useSelector(
    (state) => state.smaNodeReducer
  );
  const { historicalData } = useSelector((state) => state.assetNodeReducer);

  const dispatch = useDispatch();
  const node = getNode(nodeId);
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);
  //setting the props
  useEffect(() => {
    var periodHtml = document.getElementById("sma_period");
    var smaHtml = document.getElementById("sma_value");

    var periodHtmlValue = periodHtml?.outerHTML;
    var smaHtmlValue = smaHtml?.outerHTML;

    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          period: periodHtmlValue,
          SMA_Value: smaHtmlValue,
        },
        period: period,
        SMA_Value: parseFloat(smaFinal)?.toFixed(2),
        label: title,
        info: {
          ...SMAData,
        },
      })
    );
    dispatch(
      nodeDataUpdate(nodeId, {
        [nodeId + "_sma_output_1/number"]: parseFloat(smaFinal)?.toFixed(2),
      })
    );
  }, [period, title, smaFinal]);

  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.period != period) {
        setPeriod(nodeProps[nodeId].prop.period);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);

  useEffect(() => {
    const findSma = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_sma_output_1/number"
    );
    if (findSma && findSma.length > 0) {
      findSma.map((el) => {
        let smaHandle = el.targetHandle;
        let smaTarget = el.target;

        dispatch(
          nodeDataUpdate(smaTarget, {
            [smaHandle]: parseFloat(smaFinal),
          })
        );
      });
    }

    const findPeriod = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_sma_output_period/number"
    );

    if (findPeriod && findPeriod.length > 0) {
      findPeriod.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: period,
          })
        );
      });
    }
  }, [smaFinal, period, connectedEdges]);

  //get prices and period
  useEffect(() => {
    const foundPrice = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_sma_input_1/array"
    );
    if (foundPrice) {
      let priceTarget = foundPrice.target;

      let sourceNode = foundPrice.source;

      setSourceId(sourceNode);
      let priceHandle = foundPrice.targetHandle;
      if (nodeData[priceTarget]) {
        setAssetId(nodeData[sourceNode]?.data?.assetBotId);
        setSource(nodeData[priceTarget].data[priceHandle]);
      }
      setPricesDisabled(true);
    } else {
      setPricesDisabled(false);
    }

    const foundPeriod = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_sma_input_2/int"
    );
    if (foundPeriod) {
      let periodTarget = foundPeriod.target;
      let periodHandle = foundPeriod.targetHandle;
      if (nodeData[periodTarget]) {
        setPeriod(parseInt(nodeData[periodTarget].data[periodHandle]));
      }
      setPeriodDisabled(true);
    } else {
      setPeriodDisabled(false);
    }
  }, [connectedEdges, nodeData]);
  useEffect(() => {
    if (period && assetId && source) {
      dispatch(
        getSmaValues(assetId, { period: period, inputSource: source }, nodeId)
      );
    }
  }, [period, assetId, source, historicalData[sourceId], sourceId]);

  useEffect(() => {
    if (smaLiveValue[nodeId]) {
      setSmaFinal(parseFloat(smaLiveValue[nodeId]));
    }
    if (smaValues?.[nodeId]) {
      setSmaFinal(
        parseFloat(smaValues?.[nodeId][smaValues?.[nodeId].length - 1]?.value)
      );
    } else {
      setSmaFinal(0);
    }
  }, [smaValues?.[nodeId], smaLiveValue[nodeId]]);

  useEffect(() => {
    socketIo.on("sendLiveCandle", (msg) => {
      if (msg?.botAssetId == assetId && period && source) {
        dispatch(
          getSmaLiveValues(
            assetId,
            { period: period, inputSource: source },
            nodeId,
            msg?.candle
          )
        );
      }
    });
    return () => {
      socketIo.off("sendLiveCandle");
    };
  }, [assetId, period, source, nodeId, historicalData[sourceId]]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--purple)"
      selected={node?.selected}
      body={
        <div className="rsi_body">
          <div style={{ position: "relative" }}>
            <ArrayHandle
              type="target"
              id={nodeId + "_sma_input_1/array"}
              style={{
                left: "-25px",
                top: "10px",
              }}
              isConnectable={!pricesDisabled}
              required={true}
            />
            <div className="rsi_body_inner_title_main">
              <p className="rsi_body_inner_title_p_main">Array</p>
            </div>
          </div>

          <div className="rsi_body_inner_title">
            <img src={arrowDown} />
            <p className="rsi_body_inner_title_p">SMA</p>
          </div>
          <div className="rsi_body_inner_body">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  type="target"
                  id={nodeId + "_sma_input_2/int"}
                  style={{
                    left: "-25px",
                  }}
                  isConnectable={!periodDisabled}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Period</p>
                <input
                  id="sma_period"
                  type="number"
                  className={
                    periodDisabled
                      ? "input_field input_field_disabled"
                      : "input_field"
                  }
                  value={period}
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  disabled={periodDisabled}
                />
              </div>

              <NumberHandle
                type="source"
                id={nodeId + "_sma_output_period/number"}
                style={{ right: "-25px" }}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_sma_output_1/number"}
                  style={{ right: "-25px", top: "5px" }}
                />
                <ArrayHandle
                  type="source"
                  id={nodeId + "_sma_output_2/array"}
                  style={{
                    right: "-25px",
                    top: "25px",
                  }}
                />
              </div>
              <div className="inputs_div">
                <p className="HandleDivP">Value</p>
                <input
                  id="sma_value"
                  type="number"
                  className="input_field input_field_disabled"
                  value={smaFinal?.toFixed(2)}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SMANode;

import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import deleteIcon from "../../Assets/Images/delete.svg";
import bot from "../../Assets/Images/bot.svg";
import trades from "../../Assets/Images/trades.svg";
import patches from "../../Assets/Images/patches.svg";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";

function SideBar() {
  const [hover, setHover] = useState(null);

  const data = [
    {
      name: "Bots",
      route: "bots",
      icon: bot,
    },
    {
      name: "Patches",
      icon: patches,
    },
    {
      name: "Trades",
      route: "trades",
      icon: trades,
    },
    {
      name: "Deleted",
      route: "deleted",
      icon: deleteIcon,
    },
  ];

  let [searchParams, setSearchParams] = useSearchParams();

  const [clicked, setClicked] = useState("");

  useEffect(() => {
    if (searchParams.get("tab")) {
      setHover(
        data.findIndex((item) => item.route === searchParams.get("tab"))
      );
    } else {
      setSearchParams({ tab: "bots" });
    }
  }, [searchParams]);

  return (
    <motion.div
      className="sidebar_main"
      onHoverEnd={() => {
        setHover(
          data.findIndex((item) => item.route === searchParams.get("tab"))
        );
      }}
    >
      {data.map((item, index) => (
        <motion.div
          key={index}
          className="sidebar_item"
          initial={{ backgroundColor: "var(--bg)", position: "relative" }}
          animate={{
            backgroundColor:
              hover == index ? "var(--grey_background)" : "var(--bg)",
            opacity: item?.hasOwnProperty("route") ? 1 : 0.4,
            cursor: item?.hasOwnProperty("route") ? "pointer" : "default",
          }}
          onHoverStart={() => {
            item?.hasOwnProperty("route") && setHover(index);
          }}
          onClick={() => {
            if (item?.hasOwnProperty("route")) {
              setSearchParams({ tab: item?.route });
              setClicked(item?.route);
            }
          }}
        >
          <motion.img
            animate={{
              backgroundColor:
                hover != index ? "var(--grey_background)" : "var(--bg)",
            }}
            src={item?.icon}
            alt="item_img"
            className="item_img"
          />
          <motion.div className="item_text">{item?.name}</motion.div>
          {!item?.hasOwnProperty("route") && <div className="soon">Soon</div>}
        </motion.div>
      ))}
    </motion.div>
  );
}

export default SideBar;

//get rsi values

import { axiosReq } from "../../Utils";
import { BBNodeActions } from "./BBNodeReducer";

export const getBBValues = (id, data, nodeId) => async (dispatch) => {
  dispatch(BBNodeActions.getbbValuesRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/indicator-values/bbCalculation/" + id,
      {},
      {},
      { period: data?.period, inputSource: data?.inputSource, stdv: data?.stdv }
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(
        BBNodeActions.getbbValuesSuccess({
          nodeId: nodeId,
          data: res.data,
        })
      );
    } else {
      dispatch(BBNodeActions.getbbValuesError(res?.data?.message));
    }
  });
};

export const getBBLiveValues =
  (id, data, nodeId, prices) => async (dispatch) => {
    dispatch(BBNodeActions.getbbLiveValuesRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/indicator-values/bbLiveData/" + id,
        {},
        {
          ...prices,
        },
        {
          period: data?.period,
          inputSource: data?.inputSource,
          stdv: data?.stdv,
        }
      )
    ).then((res) => {
      if (res?.success) {
        dispatch(
          BBNodeActions.getbbLiveValuesSuccess({
            nodeId: nodeId,
            data: res.data,
          })
        );
      } else {
        dispatch(BBNodeActions.getbbLiveValuesError(res?.data?.message));
      }
    });
  };

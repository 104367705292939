import React, { useEffect, useState } from "react";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import {
  Edge,
  Handle,
  Position,
  getConnectedEdges,
  useNodeId,
  useReactFlow,
} from "reactflow";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import { useDispatch, useSelector } from "react-redux";
import "./EqualNode.scss";
import NodeBox from "../../Components/NodeBox/NodeBox";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
function EqualNode() {
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [value3, setValue3] = useState();

  const [valueDisabled, setValueDisabled] = useState(false);
  const [valueDisabled2, setValueDisabled2] = useState(false);
  const [valueDisabled3, setValueDisabled3] = useState(false);
  const [title, setTitle] = useState("EQUALS");
  const [outPut, setOutPut] = useState(false);
  const [connectedEdges, setConnectedEdges] = useState([]);
  const nodeId = useNodeId();
  const node = getNode(nodeId && nodeId);
  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);

  const reduxNodes = useSelector((state) => state.flow.nodes);
  const dispatch = useDispatch();
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  function equal(value, compare, tolerance) {
    if (tolerance && tolerance != 0) {
      if (value <= compare + tolerance && value >= compare - tolerance) {
        return true;
      } else {
        return false;
      }
    } else {
      if (value == compare) {
        return true;
      } else {
        return false;
      }
    }
  }
  //get the number from the connected node
  useEffect(() => {
    const foundNumber1 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_equalNodeValue1/number"
    );
    if (foundNumber1) {
      let numberTarget1 = foundNumber1.target;
      let numberHandle1 = foundNumber1.targetHandle;
      if (nodeData[numberTarget1]) {
        {
          setValue(parseFloat(nodeData[numberTarget1].data[numberHandle1]));
        }
      }
      setValueDisabled(true);
    } else {
      setValueDisabled(false);
    }
    const foundNumber2 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_equalNodeValue2/number"
    );
    if (foundNumber2) {
      let numberTarget2 = foundNumber2.target;
      let numberHandle2 = foundNumber2.targetHandle;
      if (nodeData[numberTarget2]) {
        {
          setValue2(parseFloat(nodeData[numberTarget2].data[numberHandle2]));
        }
      }
      setValueDisabled2(true);
    } else {
      setValueDisabled2(false);
    }
    const foundNumber3 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_equalNodeValue3/number"
    );
    if (foundNumber3) {
      let numberTarget3 = foundNumber3.target;
      let numberHandle3 = foundNumber3.targetHandle;
      if (nodeData[numberTarget3]) {
        {
          setValue3(parseFloat(nodeData[numberTarget3].data[numberHandle3]));
        }
      }
      setValueDisabled3(true);
    } else {
      setValueDisabled3(false);
    }
  }, [connectedEdges, nodeData]);
  //to set data to the connected edges
  useEffect(() => {
    const findNumber = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "equal_output/boolean"
    );
    if (findNumber && findNumber.length > 0) {
      findNumber.map((el) => {
        let numberHandle = el.targetHandle;
        let numberTarget = el.target;

        dispatch(
          nodeDataUpdate(numberTarget, {
            [numberHandle]: outPut,
          })
        );
      });
    }
  }, [connectedEdges, outPut, value]);

  useEffect(() => {
    if (value && value2) {
      setOutPut(equal(value, value2, value3));
    }
  }, [value, value2, value3]);

  //setting the props
  useEffect(() => {
    dispatch(
      nodePropUpdate(nodeId, {
        value: value,
        hide: true,
        value2: value2,
        value3: value3,
        outPut: outPut.toString(),
        label: title,
      })
    );
  }, [value, value2, value3, outPut, title]);
  //getting the props
  // useEffect(() => {
  //   if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
  //     if (nodeProps[nodeId]?.prop?.value != value) {
  //       setValue(parseFloat(nodeProps[nodeId].prop.value));
  //     }
  //     if (nodeProps[nodeId]?.prop?.value2 != value2) {
  //       setValue2(parseFloat(nodeProps[nodeId].prop.value2));
  //     }
  //     if (nodeProps[nodeId]?.prop?.value3 != value3) {
  //       setValue3(parseFloat(nodeProps[nodeId].prop.value3));
  //     }
  //     if (nodeProps[nodeId]?.prop?.outPut != outPut) {
  //       setOutPut(nodeProps[nodeId].prop.outPut);
  //     }
  //     if (nodeProps[nodeId]?.prop?.label != title) {
  //       setTitle(nodeProps[nodeId].prop.label);
  //     }
  //   }
  // }, [nodeProps]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--dark-purple)"
      selected={node?.selected}
      body={
        <div className="EqualLowerCustomDiv">
          <div className="EqualLowerDiv">
            {/* <div className="EqualLowerDivTitleP">
              <img src={arrowDown} />
              <p className="EqualLowerNodeP">Input</p>
            </div> */}
            {/* <NumberHandle
              id={nodeId + "_equalNodeValue1/number"}
              type="target"
              isConnectable={!valueDisabled}
              style={{
                top: "82px",
              }}
              required={true}
            /> */}
          </div>
          <div className="EqualLowerDivInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                {/* <div className="border_Div firstBorder_Div"></div> */}
                <NumberHandle
                  id={nodeId + "_equalNodeValue1/number"}
                  type="target"
                  isConnectable={!valueDisabled}
                  style={{
                    left: "-25px",
                  }}
                />
              </div>
              <div className="handle_title">Value 1</div>
              <input
                id="value"
                type="number"
                className="input_field"
                value={value}
                onChange={(e) => setValue(parseFloat(e.target.value))}
                disabled={valueDisabled}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                {/* <div className="border_Div firstBorder_Div"></div> */}
                <NumberHandle
                  id={nodeId + "_equalNodeValue2/number"}
                  type="target"
                  isConnectable={!valueDisabled2}
                  style={{
                    left: "-25px",
                  }}
                />
              </div>
              <div className="handle_title">Value 2</div>
              <input
                id="value2"
                type="number"
                className="input_field"
                value={value2}
                onChange={(e) => setValue2(parseFloat(e.target.value))}
                disabled={valueDisabled2}
              />
            </div>
            <div
              className="handleDiv tolerence_handle"
              style={{ position: "relative" }}
            >
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  id={nodeId + "_equalNodeValue3/number"}
                  type="target"
                  isConnectable={!valueDisabled3}
                  style={{
                    left: "-25px",
                  }}
                />
              </div>
              <div className="handle_title">Tolerance</div>
              <input
                id="value3"
                type="number"
                className="input_field"
                value={value3}
                onChange={(e) => setValue3(parseFloat(e.target.value))}
                disabled={valueDisabled3}
              />
            </div>
            <div
              className="handleDiv source_handle"
              style={{ position: "relative" }}
            >
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <BooleanHandle
                  id={nodeId + "equal_output/boolean"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                    top: "15px",
                  }}
                />
              </div>
              <p className="HandleDivP handle_title">Status</p>
              <input
                id="valueOut"
                type=" "
                className="input_field"
                value={outPut.toString()}
                disabled={valueDisabled2}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default EqualNode;

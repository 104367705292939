import React, { useEffect, useState } from "react";
import "./InformationPopUp.scss";
import LearnMorePop from "../LearnMorePop/LearnMorePop";
import { motion } from "framer-motion";
import RadioButton from "../RadioButton/RadioButton";
import DropDown from "../DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { nodePropDataUpdate } from "../../Redux/Nodes/NodesActions";
import table from "../../Assets/Images/table.svg";
import TablePopup from "../TablePopup/TablePopup";

function InformationPopUp({
  show,
  setShow,
  title,
  desc,
  type,
  html,
  color,
  info,
  id,
}) {
  const [moreShow, setMoreShow] = useState(false);
  const allNodes = useSelector((state) => state?.flow?.nodes);

  const [ShowTablePop, setShowTablePop] = useState(false);

  useEffect(() => {
    if (!show) {
      setMoreShow(false);
      setShowTablePop(false);
    }
  }, [show]);

  const [node, setNode] = useState();
  const { nodeProps, nodeData } = useSelector((state) => state.nodes);
  useEffect(() => {
    // if (!title || !desc || !type || !html || !color || !info || !id) return;
    setNode({ title, desc, type, html, color, info });
  }, [title, desc, type, html, color, info, id, nodeProps, allNodes]);
  const dispatch = useDispatch();

  const options = [
    {
      value: "Live",
      label: "Live",
    },
    {
      value: "Historical",
      label: "Historical",
    },
  ];

  const options2 = [
    {
      value: "Percentage",
      label: "Percentage",
    },
    {
      value: "Number",
      label: "Number",
    },
  ];

  return (
    <>
      <LearnMorePop information={info} show={moreShow} setShow={setMoreShow} />
      <TablePopup
        information={{
          title: nodeProps[id]?.prop.asset,
          color: "#FFD8AB",
          id: nodeData[id]?.data.assetBotId,
          nodeId: id,
        }}
        show={ShowTablePop}
        setShow={setShowTablePop}
      />
      <motion.div
        className="info_pop_main_div"
        initial={{
          opacity: 0,
          y: -100,
          filter: "blur(5px)",
          display: "none",
        }}
        animate={{
          opacity: show ? 1 : 0,
          y: show ? 0 : -100,
          filter: show ? "blur(0px)" : "blur(5px)",
          display: show ? "block" : "none",
        }}
      >
        <div className="info_pop_sec1">
          <div className="info_pop_header">
            <div className="info_pop_title">
              <div className="title">{node?.title}</div>
              {node?.info?.node === "Assets Node" && (
                <img
                  src={table}
                  alt="table"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    nodeProps[id]?.prop.asset && setShowTablePop(!ShowTablePop)
                  }
                />
              )}
            </div>
            <div
              className="info_pop_type"
              style={{
                color: node?.color,
              }}
            >
              {node?.type}
            </div>
          </div>
          <div className="info_pop_desc">{node?.desc}</div>
        </div>
        <div className="info_pop_sec2">
          <div className="info_pop_html">
            {node?.html}
            {info?.node == "RSI" && (
              <>
                <div className="info_pop_sec2_sub">
                  <div className="info_pop_sec2_sub_sec_main">
                    <div className="info_pop_sec2_sub_sec">
                      <div className="info_pop_title">Oversold</div>
                      <RadioButton
                        checked={nodeProps[id]?.prop?.oversold}
                        setChecked={(e) => {
                          dispatch(
                            nodePropDataUpdate(
                              id,
                              "oversold",
                              !nodeProps[id]?.prop?.oversold
                            )
                          );
                        }}
                      />
                    </div>
                    {nodeProps[id]?.prop?.oversold && (
                      <div className="info_pop_sec2_sub_sec_2">
                        <div className="info_pop_title">Level</div>
                        <input
                          className="input_field"
                          type="number"
                          value={nodeProps[id]?.prop?.oversoldValue}
                          onChange={(e) => {
                            dispatch(
                              nodePropDataUpdate(
                                id,
                                "oversoldValue",
                                e.target.value
                              )
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="info_pop_sec2_sub_sec_main">
                    <div className="info_pop_sec2_sub_sec">
                      <div className="info_pop_title">Overbought</div>
                      <RadioButton
                        checked={nodeProps[id]?.prop?.overbought}
                        setChecked={(e) => {
                          dispatch(
                            nodePropDataUpdate(
                              id,
                              "overbought",
                              !nodeProps[id]?.prop?.overbought
                            )
                          );
                        }}
                      />
                    </div>
                    {nodeProps[id]?.prop?.overbought && (
                      <div className="info_pop_sec2_sub_sec_2">
                        <div className="info_pop_title">Level</div>
                        <input
                          className="input_field"
                          type="number"
                          value={nodeProps[id]?.prop?.overboughtValue}
                          onChange={(e) => {
                            dispatch(
                              nodePropDataUpdate(
                                id,
                                "overboughtValue",
                                e.target.value
                              )
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="info_pop_sec2_sub">
                  <div className="info_pop_sec2_sub_sec">
                    <div className="info_pop_title">Trigger Type</div>
                    <DropDown
                      id="asset"
                      options={options}
                      value={nodeProps[id]?.prop?.triggerType}
                      onChange={(e) => {
                        dispatch(
                          nodePropDataUpdate(id, "triggerType", e.target.value)
                        );
                      }}
                      style={{
                        width: "5rem",
                        padding: "0.2rem 0",
                        color: "var(--text-color2)",
                        textAlign: "center",
                        border: "none",
                        outline: "none",
                        background: "var(--background_input)",
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {info?.node == "ATR" && (
              <>
                <div className="info_pop_sec2_sub">
                  <div className="info_pop_sec2_sub_sec_main">
                    <div className="info_pop_sec2_sub_sec">
                      <div className="info_pop_title">Level Type</div>
                      <DropDown
                        id="asset"
                        options={options2}
                        value={nodeProps[id]?.prop?.levelType}
                        onChange={(e) => {
                          dispatch(
                            nodePropDataUpdate(id, "levelType", e.target.value)
                          );
                        }}
                        style={{
                          width: "5rem",
                          padding: "0.2rem 0",
                          color: "var(--text-color2)",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          background: "var(--background_input)",
                        }}
                      />
                    </div>
                    <div className="info_pop_sec2_sub_sec">
                      <div className="info_pop_title">High Volatility</div>
                      <RadioButton
                        checked={nodeProps[id]?.prop?.high}
                        setChecked={(e) => {
                          dispatch(
                            nodePropDataUpdate(
                              id,
                              "high",
                              !nodeProps[id]?.prop?.high
                            )
                          );
                        }}
                      />
                    </div>
                    {nodeProps[id]?.prop?.high && (
                      <div className="info_pop_sec2_sub_sec_2">
                        <div className="info_pop_title">Level</div>
                        <input
                          className="input_field"
                          type="number"
                          value={nodeProps[id]?.prop?.highLevel}
                          onChange={(e) => {
                            dispatch(
                              nodePropDataUpdate(
                                id,
                                "highLevel",
                                e.target.value
                              )
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="info_pop_sec2_sub_sec_main">
                    <div className="info_pop_sec2_sub_sec">
                      <div className="info_pop_title">Low Volatility</div>
                      <RadioButton
                        checked={nodeProps[id]?.prop?.low}
                        setChecked={(e) => {
                          dispatch(
                            nodePropDataUpdate(
                              id,
                              "low",
                              !nodeProps[id]?.prop?.low
                            )
                          );
                        }}
                      />
                    </div>
                    {nodeProps[id]?.prop?.low && (
                      <div className="info_pop_sec2_sub_sec_2">
                        <div className="info_pop_title">Level</div>
                        <input
                          className="input_field"
                          type="number"
                          value={nodeProps[id]?.prop?.lowLevel}
                          onChange={(e) => {
                            dispatch(
                              nodePropDataUpdate(id, "lowLevel", e.target.value)
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="info_pop_sec2_sub">
                  <div className="info_pop_sec2_sub_sec">
                    <div className="info_pop_title">Trigger Type</div>
                    <DropDown
                      id="asset"
                      options={options}
                      value={nodeProps[id]?.prop?.triggerType}
                      onChange={(e) => {
                        dispatch(
                          nodePropDataUpdate(id, "triggerType", e.target.value)
                        );
                      }}
                      style={{
                        width: "5rem",
                        padding: "0.2rem 0",
                        color: "var(--text-color2)",
                        textAlign: "center",
                        border: "none",
                        outline: "none",
                        background: "var(--background_input)",
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="info_pop_read" onClick={() => setMoreShow(!moreShow)}>
            Learn More
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default InformationPopUp;

import React from "react";
import "./Pagination.scss";
import enabledArrow from "../../Assets/Images/left_arrow.svg";

function Pagination({
  limit,
  offset,
  setOffset,
  pageNumber,
  setPageNumber,
  count,
  hasPrev,
  hasNext,
}) {
  const totalPages = Math.ceil(count / limit) ? Math.ceil(count / limit) : 1;
  return (
    <div className="pagination_main">
      <div className="paginationContainer">
        {hasPrev && (
          <img
            alt="back"
            src={enabledArrow}
            onClick={() => {
              setOffset(parseInt(offset) - parseInt(limit));
              setPageNumber(pageNumber - 1);
            }}
            className={`PaginationButton ${
              hasPrev ? "right" : "disabled_pagination"
            }`}
          />
        )}
        <span style={{ fontFamily: "Graphie_Regular" }}>
          Page {pageNumber} of {totalPages}
        </span>
        {hasNext && (
          <img
            alt="next"
            src={enabledArrow}
            onClick={() => {
              setOffset(parseInt(offset) + parseInt(limit));
              setPageNumber(pageNumber + 1);
            }}
            className={`PaginationButton ${
              hasNext
                ? "left"
                : " disabled_pagination disabled_pagination_right"
            }`}
          />
        )}
      </div>
    </div>
  );
}

export default Pagination;

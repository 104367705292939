import axios, { all } from "axios";
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import ObjectHandle from "../../Components/CustomHandles/ObjectHandle/ObjectHandle";
import DropDown from "../../Components/DropDown/DropDown";
import NodeBox from "../../Components/NodeBox/NodeBox";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import "./AssetsNode.scss";
import {
  createBotAsset,
  getAllAssets,
  getHistoricalData,
  updateBotAsset,
} from "../../Redux/AssetNode/AssetNodeActions";
import { html1, html2, timeframes } from "./AssetData";
import { useParams } from "react-router-dom";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import { color } from "framer-motion";

function AssetsNode() {
  //get all the nodes, the node i am in, the edges and the id of the node i am in
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const nodeId = useNodeId();
  const [title, setTitle] = useState("Assets Node");
  const node = getNode(nodeId && nodeId);
  const [newData2, setNewData2] = useState({});
  const [connectedEdges, setConnectedEdges] = useState([]);
  const { edges } = useSelector((state) => state?.flow);
  const reduxNodes = useSelector((state) => state?.flow?.nodes);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const [intervalId1, setIntervalId1] = useState(null);
  const [time1, setTime1] = useState(null);
  const [intervalId2, setIntervalId2] = useState(null);
  const [assets, setAssets] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [attr, setAttr] = useState({});
  const [first, setFirst] = useState({
    get: false,
    create: false,
    update: false,
  });
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAssets());
  }, []);

  const {
    allAssets,
    createBotAssetSuccess,
    getHistoricalDataSuccess,
    historicalData,
    updateBotAssetSuccess,
  } = useSelector((state) => state.assetNodeReducer);

  useEffect(() => {
    if (allAssets && allAssets.length > 0) {
      allAssets.map((el) => {
        setAssets((prev) => [...prev, { value: el.id, label: el.name }]);
      });
    }
  }, [allAssets]);

  useEffect(() => {
    if (
      attr?.asset &&
      attr?.timeFrame &&
      params.id &&
      !first.create &&
      !nodeData[nodeId]?.data?.assetBotId
    ) {
      dispatch(
        createBotAsset({
          asset: attr.asset,
          timeFrame: attr.timeFrame,
          id: params.id,
          nodeId: nodeId,
        })
      );
    } else if (
      attr?.asset &&
      attr?.timeFrame &&
      params.id &&
      !first.update &&
      nodeData[nodeId]?.data?.assetBotId
    ) {
      dispatch(
        updateBotAsset(nodeData[nodeId]?.data?.assetBotId, {
          asset: attr.asset,
          timeFrame: attr.timeFrame,
          id: params.id,
          nodeId,
        })
      );
    }
  }, [attr]);

  //getting and setting the connected edges
  useEffect(() => {
    if (node) setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  useEffect(() => {
    if (historicalData?.[nodeId] && historicalData?.[nodeId].length > 0) {
      if (connectedEdges && connectedEdges.length > 0) {
        // find if there is a connection to the open output
        const findOpen = connectedEdges.filter(
          (edge) => edge.sourceHandle === nodeId + "open_output/array"
        );
        // find if there is a connection to the high output
        const findHigh = connectedEdges.filter(
          (edge) => edge.sourceHandle === nodeId + "high_output/array"
        );
        // find if there is a connection to the low output
        const findLow = connectedEdges.filter(
          (edge) => edge.sourceHandle === nodeId + "low_output/array"
        );
        // find if there is a connection to the close output
        const findClose = connectedEdges.filter(
          (edge) => edge.sourceHandle === nodeId + "close_output/array"
        );
        const findNodeId = connectedEdges.filter(
          (edge) => edge.sourceHandle === nodeId + "attr_input/object"
        );

        // //find if there is a connection to the volume output
        // const findVolume = connectedEdges.filter(
        //   (edge) => edge.sourceHandle === nodeId + "volume_output/number"
        // );

        // *TODO add value, bid and ask here

        if (findOpen && findOpen.length > 0) {
          findOpen.map((el) => {
            let openHandle = el.targetHandle;
            let openTarget = el.target;
            dispatch(
              nodeDataUpdate(openTarget, {
                [openHandle]: "open",
              })
            );
          });
        }

        if (findHigh && findHigh.length > 0) {
          findHigh.map((el) => {
            let highHandle = el.targetHandle;
            let highTarget = el.target;

            dispatch(
              nodeDataUpdate(highTarget, {
                [highHandle]: "high",
              })
            );
          });
        }
        if (findLow && findLow.length > 0) {
          findLow.map((el) => {
            let lowHandle = el.targetHandle;
            let lowTarget = el.target;

            dispatch(
              nodeDataUpdate(lowTarget, {
                [lowHandle]: "low",
              })
            );
          });
        }
        if (findClose && findClose.length > 0) {
          findClose.map((el) => {
            let closeHandle = el.targetHandle;
            let closeTarget = el.target;

            dispatch(
              nodeDataUpdate(closeTarget, {
                [closeHandle]: "close",
              })
            );
          });
        }
        if (findNodeId && findNodeId.length > 0) {
          findNodeId.map((el) => {
            let attrHandle = el.targetHandle;
            let attrTarget = el.target;

            dispatch(
              nodeDataUpdate(attrTarget, {
                [attrHandle]: nodeId,
              })
            );
          });
        }
      }
    }
  }, [historicalData, connectedEdges]);

  useEffect(() => {
    const findAttr = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "attr_output/object"
    );

    if (findAttr && findAttr.length > 0) {
      findAttr.map((el) => {
        let attrHandle = el.targetHandle;
        let attrTarget = el.target;

        dispatch(
          nodeDataUpdate(attrTarget, {
            [attrHandle]: attr ? attr : null,
          })
        );
      });
    }
  }, [connectedEdges, attr]);

  //setting the props
  useEffect(() => {
    if (assets && assets.length > 0) {
      const jsxElement1 = html1(assets, attr, setAttr);

      const jsxElement2 = html2(timeframes, attr, setAttr);

      var html_1 = ReactDOMServer.renderToStaticMarkup(jsxElement1);
      var html_2 = ReactDOMServer.renderToStaticMarkup(jsxElement2);
      dispatch(
        nodePropUpdate(nodeId, {
          html: {
            asset: html_1,
            timeFrame: html_2,
          },
          info: {
            type: "",
            node: "Assets Node",
            desc: "This node is used to get the asset data",
            type: "Assets",
            color: "rgb(255, 205, 175)",
          },
          asset: attr.asset,
          timeFrame: attr.timeFrame,
          label: title,
        })
      );
    }
  }, [attr, title, assets]);

  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.asset != attr.asset) {
        setAttr(
          (prev) => (prev = { ...prev, asset: nodeProps[nodeId].prop.asset })
        );
      }
      if (nodeProps[nodeId]?.prop?.timeFrame != attr.timeFrame) {
        setAttr(
          (prev) =>
            (prev = { ...prev, timeFrame: nodeProps[nodeId].prop.timeFrame })
        );
      }
      if (
        nodeProps[nodeId]?.prop?.label != title &&
        nodeProps[nodeId]?.prop?.label
      ) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);

  return (
    <NodeBox
      color="var(--orange)"
      title={title}
      setTitle={setTitle}
      selected={node?.selected}
      middle={
        <ObjectHandle
          id={nodeId + "attr_input/object"}
          isConnectable={true}
          type="source"
          style={{
            right: "-7px",
            top: "30px",
          }}
        />
      }
      body={
        <div className="AssetMainBody">
          <div className="MainLowerCustomDivArrowTitle">
            <img src={arrowDown} className="arrowDown" alt="arrow" />
            <p className="MainLowerCustomDivOHLC">OHLC</p>
          </div>
          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <ArrayHandle
                  id={nodeId + "open_output/array"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Open</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <ArrayHandle
                  id={nodeId + "high_output/array"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">High</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <ArrayHandle
                  id={nodeId + "low_output/array"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Low</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <ArrayHandle
                  id={nodeId + "close_output/array"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Close</p>
            </div>
          </div>
          <div className="MainLowerCustomDivArrowTitle">
            <img src={arrowDown} className="arrowDown" />
            <p className="MainLowerCustomDivOHLC">Volume</p>
          </div>
          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <ArrayHandle
                  id={nodeId + "volune_output/array"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Value</p>
            </div>
          </div>
          <div className="MainLowerCustomDivArrowTitle">
            <img src={arrowDown} className="arrowDown" />
            <p className="MainLowerCustomDivOHLC">Quote</p>
          </div>
          <div className="MainLowerCustomDivOHLCInner">
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div firstBorder_Div"></div>
                <NumberHandle
                  id={nodeId + "bid_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Bid</p>
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <NumberHandle
                  id={nodeId + "ask_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">Ask</p>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default AssetsNode;

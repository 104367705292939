//get rsi values

import { axiosReq } from "../../Utils";
import { SMANodeActions } from "./SMANodeReducer";

export const getSmaValues = (id, data, nodeId) => async (dispatch) => {
  dispatch(SMANodeActions.getSmaValuesRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/indicator-values/smaCalculation/" + id,
      {},
      {},
      { period: data?.period, inputSource: data?.inputSource }
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(
        SMANodeActions.getSmaValuesSuccess({
          nodeId: nodeId,
          data: res.data,
        })
      );
    } else {
      dispatch(SMANodeActions.getSmaValuesError(res?.data?.message));
    }
  });
};

export const getSmaLiveValues =
  (id, data, nodeId, prices) => async (dispatch) => {
    dispatch(SMANodeActions.getSmaLiveValuesRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/indicator-values/smaLiveData/" + id,
        {},
        {
          ...prices,
        },
        { period: data?.period, inputSource: data?.inputSource }
      )
    ).then((res) => {
      if (res?.success) {
        dispatch(
          SMANodeActions.getSmaLiveValuesSuccess({
            nodeId: nodeId,
            data: res.data,
          })
        );
      } else {
        dispatch(SMANodeActions.getSmaLiveValuesError(res?.data?.message));
      }
    });
  };

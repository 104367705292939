import { axiosReq } from "../../Utils";
import { homeActions } from "./HomeReducer";

export const createBot = (navigate, setShow) => async (dispatch) => {
  dispatch(homeActions.createBotRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/bot", {}, {}, {})
  ).then((res) => {
    if (res?.success) {
      dispatch(homeActions.createBotSuccess());
      setShow(false);
      navigate("/flowpanel/" + res?.data?.id);
    } else {
      dispatch(homeActions.createBotError(res?.data?.message));
    }
  });
};

export const getBots = () => async (dispatch) => {
  dispatch(homeActions.getBotRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/bot", {}, {}, {})
  ).then((res) => {
    if (res?.success) {
      dispatch(homeActions.getBotSuccess(res?.data));
    } else {
      dispatch(homeActions.getBotError(res?.data?.message));
    }
  });
};

export const getSingleBot = (id) => async (dispatch) => {
  dispatch(homeActions.getSingleBotRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/bot/" + id, {}, {}, {})
  ).then((res) => {
    if (res?.success) {
      dispatch(homeActions.getSingleBotSuccess(res?.data));
    } else {
      dispatch(homeActions.getSingleBotError(res?.data?.message));
    }
  });
};

export const editBot = (id, data) => async (dispatch) => {
  dispatch(homeActions.editBotRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/bot/" + id,
      {},
      {
        bot: data?.bot,
        name: data?.name,
      },
      {}
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(homeActions.editBotSuccess());
    } else {
      dispatch(homeActions.editBotError(res?.data?.message));
    }
  });
};

export const getAllTrades = () => async (dispatch) => {
  dispatch(homeActions.getAllTradesRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/execute-trade", {}, {}, {})
  ).then((res) => {
    if (res?.success) {
      dispatch(homeActions.getAllTradesSuccess(res?.data));
    } else {
      dispatch(homeActions.getAllTradesError(res?.data?.message));
    }
  });
};

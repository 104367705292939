import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

export const ChartComponent = (props) => {
  const {
    data,
    colors: {
      backgroundColor = props.backgroundColor || "transparent",
      lineColor = props.lineColor || "#ff6604",
      textColor = "black",
      areaTopColor = props.areaTopColor || "#ff6604",
      areaBottomColor = props.areaBottomColor || "#ff660408",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
      });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
        fontFamily: "Graphie_Regular",
      },
      width: chartContainerRef.current.clientWidth,
      autoSize: true,
      handleScale: false,
      handleScroll: false,
      lastValueVisible: false,
      height: props.height || 150,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        visible: false,
      },
      crosshair: {
        mode: 2,
        horzLine: {
          visible: false,
        },
        vertLine: {
          visible: false,
        },
      },
      rightPriceScale: {
        visible: false,
      },
      leftPriceScale: {
        visible: false,
      },
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);

  return (
    <div
      ref={chartContainerRef}
      style={
        {
          // marginLeft: "-1rem",
        }
      }
    />
  );
};

const initialData = [
  { time: "2018-12-22", value: 32.51 },
  { time: "2018-12-23", value: 31.11 },
  { time: "2018-12-24", value: 27.02 },
  { time: "2018-12-25", value: 27.32 },
  { time: "2018-12-26", value: 25.17 },
  { time: "2018-12-27", value: 28.89 },
  { time: "2018-12-28", value: 25.46 },
  { time: "2018-12-29", value: 23.92 },
  { time: "2018-12-30", value: 22.68 },
  { time: "2019-01-01", value: 35.2 },
  { time: "2019-01-02", value: 28.9 },
  { time: "2019-01-03", value: 20 },
  { time: "2019-01-04", value: 38.2 },
  { time: "2019-01-05", value: 22 },
  { time: "2019-01-06", value: 27 },
  { time: "2019-01-07", value: 28 },
  { time: "2019-01-08", value: 28.5 },
  { time: "2019-01-09", value: 10.2 },
  { time: "2019-01-10", value: 10.2 },
  { time: "2019-01-11", value: 10.2 },
  { time: "2019-01-12", value: 10.2 },
  { time: "2019-01-13", value: 10.2 },
  { time: "2019-01-14", value: 10.2 },
  { time: "2019-01-15", value: 10.2 },
  { time: "2019-01-16", value: 10.2 },
  { time: "2019-01-17", value: 10.2 },
  { time: "2019-01-18", value: 10.2 },
  { time: "2019-01-19", value: 10.2 },
  { time: "2019-01-20", value: 10.2 },
  { time: "2019-01-21", value: 10.2 },
  { time: "2019-01-22", value: 10.2 },
  { time: "2019-01-23", value: 10.2 },
  { time: "2019-01-24", value: 10.2 },
  { time: "2019-01-25", value: 10.2 },
  { time: "2019-01-26", value: 10.2 },
  { time: "2019-01-27", value: 10.2 },
];

export function Chart(props) {
  return <ChartComponent {...props} data={initialData}></ChartComponent>;
}

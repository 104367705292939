import DropDown from "../../Components/DropDown/DropDown";

export const html1 = (assets, attr, setAttr) => {
  return (
    <DropDown
      id="asset"
      options={assets}
      value={attr.asset}
      onChange={(e) =>
        setAttr((prev) => (prev = { ...prev, asset: e.target.value }))
      }
      style={{
        width: "5rem",
        padding: "0.2rem 0",
        color: "var(--text-color2)",
        textAlign: "center",
        border: "none",
        outline: "none",
        background: "var(--background_input)",
      }}
    />
  );
};
export const html2 = (timeframes, attr, setAttr) => {
  return (
    <DropDown
      id="order"
      options={timeframes}
      value={attr.timeFrame}
      onChange={(e) =>
        setAttr((prev) => (prev = { ...prev, timeFrame: e.target.value }))
      }
      style={{
        width: "5rem",
        padding: "0.2rem 0",
        color: "var(--text-color2)",
        textAlign: "center",
        border: "none",
        outline: "none",
        background: "var(--background_input)",
      }}
    />
  );
};

export const timeframes = [
  {
    value: "1m",
    label: "1 min",
  },
  {
    value: "5m",
    label: "5 min",
  },
  {
    value: "15m",
    label: "15 min",
  },
  {
    value: "30m",
    label: "30 min",
  },
  {
    value: "1h",
    label: "1 hour",
  },
  {
    value: "4h",
    label: "4 hour",
  },
];

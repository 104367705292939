import man from "../../Assets/Images/man.png";

export const RSIData = {
  type: "Indicator",
  desc: "The Relative Strength Index (RSI) is a momentum indicator that measures the magnitude of recent price changes to analyze overbought or oversold conditions.",
  color: "rgb(239, 171, 255)",

  node: "RSI",
  label: "RSI Node",
  details:
    "Welcome to the RSI (Relative Strength Index) Node, your trusty sidekick in the thrilling world of trading! This nifty gadget helps you gauge the speed and change of price movements, oscillating between 0 and 100 to give you the lowdown on whether an asset is overbought or oversold. Think of it as your market mood ring, but way cooler.",
  img: man,

  inputs: [
    {
      name: "Asset:",
      desc: "Pick the asset you want to spy on. The RSI Node will dig up its secrets for you.",
    },
    {
      name: "Timeframe:",
      desc: "Set the timeframe for the data you want. Want the scoop on the past week? Month? Year? You got it!",
    },
    {
      name: "Overbought Level (default: 70):",
      desc: "This is the hot zone where the asset might be overbought. Translation: it's been hitting the gym too hard.",
    },
    {
      name: "Oversold Level (default: 30):",
      desc: "This is the cool zone where the asset might be oversold. Translation: it needs a vacation.",
    },
    {
      name: "Type:",
      desc: "Choose your adventure - ‘When’ or ‘While’. It's like selecting your own trading saga.",
    },
  ],

  outputs: [
    {
      name: "RSI Value - Live:",
      desc: "Get the freshest RSI value straight from the market’s oven, ranging between 0 and 100.",
    },
    {
      name: "RSI Value - History:",
      desc: "Dive into the archives with an array of historical RSI values. Perfect for data geeks who love a good trend story.",
    },
    {
      name: "Trigger Oversold:",
      desc: "When RSI drops below the cool zone (oversold level), it’s a bat signal for potential buying opportunities.",
    },
    {
      name: "Trigger Overbought:",
      desc: "When RSI rockets above the hot zone (overbought level), it’s a cue to consider selling. Think of it as your “sell high” reminder.",
    },
  ],
  examples: [
    {
      name: "Using the RSI Node is like having a superpower in your trading toolkit. Set it up with your preferred parameters, and let it help you uncover the best times to buy and sell. Here’s how you can become a trading superhero:",

      steps: [
        "Buy Signal: When RSI dips below the oversold level (e.g., below 30), it's like finding a rare comic book at a garage sale. Time to buy!",
        "Sell Signal: When RSI soars above the overbought level (e.g., above 70), it's like realizing your old video game console is now a collector's item. Time to sell!",
        "Neutral: When RSI is chilling between oversold and overbought, the market is in zen mode. No action needed – just sit back and relax.",
      ],
    },
    {
      name: "The RSI Node is your go-to gadget for making smarter, data-driven trading decisions. With this cool tech at your fingertips, you'll be navigating the markets like a pro, armed with the insights you need to boost your trading game. Get ready to geek out and trade on!",
    },
  ],
  oversold: false,
  overbought: false,
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import "./WaitList.scss";
import logo from "../../Assets/Images/logoBig.svg";
import arrow from "../../Assets/Images/arrow.svg";
import image1 from "../../Assets/Images/image2.png";
import img2 from "../../Assets/Images/img2_mob.png";
import { ReactComponent as Steps } from "../../Assets/Images/waitlistStep.svg";

import {
  Background,
  Controls,
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import RSINode from "../../Nodes/RSINode/RSINode";
import BiggerNode from "../../Nodes/BiggerNode/BiggerNode";
import AssetDumb from "../../Components/LandingNodes/Asset_Dumb/AssetDumb";
import {
  handleConnect,
  handleEdgesChange,
  handleNodesChange,
} from "../../Redux/FlowPanel/FlowActions";
import { useDispatch } from "react-redux";
import ConditionDumb from "../../Components/LandingNodes/ConditionDumb/CondtionDumb";
import UseInnerWidth from "../../Hooks/UseInnerWidth";
import MeetAi from "../../PageComponents/LandingComp/MeetAI/MeetAi";
import Testimonials from "../../PageComponents/LandingComp/Testimonials/Testimonials";
import FAQs from "../../PageComponents/LandingComp/FAQs/FAQs";
import Footer from "../../PageComponents/Footer/Footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const nodeTypes = {
  Asset: AssetDumb,
  RSI: RSINode,
  Bigger: BiggerNode,
  Condition: ConditionDumb,
};

function WaitList() {
  const scWidth = UseInnerWidth();

  const initialEdges = [
    {
      id: "rsi_1-2",
      source: "rsi_1",
      target: "rsi_2",
      sourceHandle: "rsi_1attr_output/array",
      targetHandle: "rsi_2_rsi_input_1/array",
      animated: true,
    },

    {
      id: "rsi_2-3",
      source: "rsi_2",
      target: "rsi_3",
      animated: true,
      sourceHandle: "rsi_2_rsi_output/number",
      targetHandle: "rsi_3_biggerNodeValue1/number",
    },

    {
      id: "rsi_3-4",
      source: "rsi_3",
      target: "rsi_4",
      animated: true,

      sourceHandle: "rsi_3open_output/boolean",
      targetHandle: "rsi_4_condition_input/boolean",
    },
  ];

  const initialNodes = [
    {
      id: "rsi_1",
      type: "Asset",

      data: { label: "Asset Node" },
      position: { x: scWidth > 550 ? 1050 : 1150, y: 260 },
    },
    {
      id: "rsi_2",
      type: "RSI",
      data: { label: "RSI Node", period: 14 },
      position: {
        x: scWidth > 550 ? 1310 : 1450,
        y: scWidth > 550 ? 334 : 260,
      },
    },
    {
      id: "rsi_3",
      type: "Bigger",
      data: { label: "Bigger Node", value: 70 },
      position: {
        x: scWidth > 550 ? 1570 : 1450,
        y: scWidth > 550 ? 334 : 450,
      },
    },
    {
      id: "rsi_4",
      type: "Condition",
      data: { label: "Condition Node" },
      position: {
        x: scWidth > 550 ? 1573 : 1450,
        y: scWidth > 550 ? 541 : 650,
      },
    },
  ];

  const ref = useRef();
  const ref2 = useRef();
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step == 1) {
      gsap.to("#connect_fist_second", {
        opacity: 0,
        duration: 1,
      });
      gsap.to("#first_group", {
        scale: 1.8,
        duration: 1,
      });
      gsap.to("#first_connection_handle", {
        fill: "#8B8B8B",
        duration: 1,
      });
      gsap.to("#second_group", {
        opacity: 0,
        duration: 1,
      });
      gsap.to("#order_node", {
        opacity: 0,
        duration: 1,
      });
      gsap.to("#connect_second_third", {
        opacity: 0,
        duration: 1,
      });
    }
    if (step == 2) {
      gsap.to("#connect_fist_second", {
        opacity: 1,
        duration: 1,
      });
      gsap.to("#first_group", {
        scale: 1,
        duration: 1,
      });
      gsap.to("#first_connection_handle", {
        fill: "#8fffa6",
        duration: 1,
      });
      gsap.to("#second_group", {
        opacity: 1,
        duration: 1,
      });
      gsap.to("#order_node", {
        opacity: 0,
        duration: 1,
      });
      gsap.to("#connect_second_third", {
        opacity: 0,
        duration: 1,
      });
    }
    if (step == 3) {
      gsap.to("#connect_fist_second", {
        opacity: 1,
        duration: 1,
      });
      gsap.to("#first_group", {
        scale: 1,
        duration: 1,
      });
      gsap.to("#second_group", {
        opacity: 1,
        duration: 1,
      });
      gsap.to("#first_connection_handle", {
        fill: "#8fffa6",
        duration: 1,
      });
      gsap.to("#order_node", {
        opacity: 1,
        duration: 1,
      });
      gsap.to("#connect_second_third", {
        opacity: 1,
        duration: 1,
      });
    }
  }, [step]);

  useEffect(() => {
    if (scWidth > 768) {
      document.getElementById("connect_fist_second").style.opacity = "0";
      document.getElementById("second_group").style.opacity = "0";
      document.getElementById("order_node").style.opacity = "0";
      document.getElementById("connect_second_third").style.opacity = "0";

      gsap.to(" .image_waitList", {
        scale: 1.5,
        scrollTrigger: {
          trigger: ".waitlist_sec3",
          start: "top top",
          end: "bottom top",
          pin: ".waitlist_sec3",
          scrub: true,
          // markers: true,
        },
      });
      gsap.to(ref2.current, {
        scrollTrigger: {
          trigger: ".waitlist_sec4",
          start: "top top",
          end: "bottom top",
          pin: ".waitlist_sec4",
          scrub: true,
          // markers: true,
          onUpdate: (self) => {
            if (self.progress * 100 <= 33) {
              setStep(1);
            } else if (self.progress * 100 > 33 && self.progress * 100 <= 66) {
              setStep(2);
            } else if (self.progress * 100 > 66) {
              setStep(3);
            }
          },
        },
      });
    } else return;
  }, []);

  const dispatch = useDispatch();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const proOptions = { hideAttribution: true };

  const onConnect = useCallback((params) => {
    setEdges((els) => addEdge({ ...params, animated: true }, els));
    dispatch(handleConnect(params));
  }, []);

  const handleClick = () => {
    if (step == 3) {
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };

  const [clicked, setClicked] = useState(0);

  const refWait = useRef();
  const executeScroll = () => refWait.current.scrollIntoView();
  return (
    <div className="waitlist_main_div">
      <div className="waitlist_sec1">
        <div className="waitList_navBar">
          <img src={logo} className="waitList_logo" />
          <div className="join_waitlist_btn_div">
            <form-widget
              mode="popup"
              ucid="dkH7H02VLNYfdwfaGQfqii9REgk"
            ></form-widget>
          </div>
        </div>
        <div className="waitlist_sec1_main">
          <div className="waitList_sec1_info">
            <div className="sec1_info_title">
              Build, Test, Trade:
              <span>
                <br className="br_1" /> All in Under 5 Minutes
              </span>
            </div>
            <div className="sec1_info_subTitle">
              A node-based platform tailored for traders to{" "}
              <br className="br_2" /> effortlessly build and deploy trading
              bots.
            </div>
            <div className="sec1_info_btns">
              <a
                href="#waitlist"
                style={{ textDecoration: "none" }}
                className="join_waitlist_btn1_div"
              >
                <button className="join_waitlist_btn1">
                  Join The Waitlist
                  <img className="arrow" src={arrow} />
                </button>
              </a>
              <button
                className="join_waitlist_btn2"
                onClick={() => executeScroll()}
              >
                How It Works?
              </button>
            </div>
          </div>{" "}
          <Background
            style={{
              background: "var(--bg)",
            }}
            size={3}
            color="#333333"
          />
          <div className="panel_reactflow_waitlist">
            <ReactFlow
              nodes={nodes}
              onNodesChange={(e) => {
                onNodesChange(e);
                dispatch(handleNodesChange(e));
              }}
              onEdgesChange={(e) => {
                onEdgesChange(e);
                dispatch(handleEdgesChange(e, edges));
              }}
              edges={edges}
              nodeTypes={nodeTypes}
              onConnect={onConnect}
              panOnScroll={false}
              zoomOnScroll={false}
              zoomOnPinch={false}
              preventScrolling={false}
              zoomOnDoubleClick={false}
              panActivationKeyCode={false}
              zoomActivationKeyCode={false}
              autoPanOnNodeDrag={false}
              fitViewOptions={{ maxZoom: 1 }}
              proOptions={proOptions}
              connectOnClick={true}
              fitView
            ></ReactFlow>{" "}
          </div>
        </div>
      </div>

      <div className="waitlist_sec2" />
      <div className="waitlist_sec3">
        <div id="sec_2_title" className="waitList_sec3_title">
          SUPERCHARGED
        </div>
        <div className="sec2_info_title">
          Blueprint Brilliance
          <span>
            <br /> Your Trading Canvas Awaits
          </span>
        </div>
        <div className="sec2_info_subTitle">
          With our drag-and-drop interface, building intricate algorithms <br />
          using nodes has never been this straightforward
        </div>
        {scWidth > 550 ? (
          <div className="waitlist_sec3_div">
            <img ref={ref} id="img_1" src={image1} className="image_waitList" />
            {scWidth > 768 && (
              <div className="orb_comp">
                <div className="orb"></div>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <img
              ref={ref}
              id="img_1"
              src={img2}
              className="image_waitList_mob"
            />
          </div>
        )}
      </div>
      <div className="waitlist_sec4" ref={refWait}>
        <div id="sec_4_title" className="waitList_sec3_title">
          HOW IT WORKS?
        </div>
        <div className="sec2_info_title">
          Transform <span>The Way You </span>
          Trade
        </div>
        <div className="sec2_info_subTitle">
          Experience a transformative journey where technology meets trading{" "}
          <br />
          acumen, propelling you to new heights of financial mastery.
        </div>
        <div className="waitlist_steps_div">
          <Steps ref={ref2} className="steps_Waitlist_img" />
          <div className="steps_Waitlist_div_info">
            <div
              className="steps_Waitlist_div_info_title"
              style={{ color: step == 3 && "var(--orange)" }}
            >
              {step == 1 && "Step 1: Import an asset node"}
              {step == 2 && "Step 2: Create your logic"}
              {step == 3 && "Step 3: Execute"}
            </div>
            <div className="steps_Waitlist_div_info_subTitle">
              {step == 1 &&
                "We provide a variety of assets, including forex, cryptocurrencies, and stocks"}
              {step == 2 &&
                "The platform supports more than 100 nodes, ranging from math nodes, logic nodes, executions nodes, etc..."}
              {step == 3 &&
                "Our platform comes pre-integrated with a variety of trading apps including Binance, OKX, and MT5. Just login, and deploy your bot."}
            </div>
          </div>
        </div>
      </div>
      <MeetAi />
      <Testimonials />
      <div className="join_waitlist_form" id="waitlist">
        <form-widget ucid="dkH7H02VLNYfdwfaGQfqii9REgk"></form-widget>
      </div>
      <FAQs />
      <Footer />
    </div>
  );
}

export default WaitList;

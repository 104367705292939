import React from "react";
import "./BotCard.scss";
import green_arrow from "../../../../../../Assets/Images/green_arrow.svg";
import red_arrow from "../../../../../../Assets/Images/red_arrow.svg";
import { Chart } from "../../../../../../Components/Chart/Chart";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function BotCard({ bot }) {
  const navigate = useNavigate();
  return (
    <div
      className="botcard_mian"
      onClick={() => {
        navigate("/flowpanel/" + bot?.bot?.id);
      }}
    >
      <div className="botcard_left">
        <div className="botcard_title">{bot?.bot?.name}</div>
        <div className="botcard_stats">
          <div className="total_stats">37 Total Trades</div>
          <div className="total_sub_stats">76% Success Rate</div>
          <div className="live">
            <div className="text">+$11,334.21</div>
            <img src={green_arrow} alt="green_arrow" className="green_arrow" />
            <span className="live_text">-0.33% (0.00348)</span>
          </div>
        </div>
        <div className="botcard_chart">
          <Chart />
        </div>
      </div>
      <div className="botcard_right">
        <div className="bot_status">
          <div className="status_title">
            <div className="text">Bot Status</div>
            <div className="status">Working</div>
          </div>
          <div className="status_boxes">
            {Array.from({ length: 24 }).map((_, index) => (
              <motion.div
                key={index}
                className="status_box"
                initial={{
                  opacity: 0.2,
                }}
                animate={{
                  opacity: bot?.botStatus[index]?.status === "up" ? 1 : 0.2,
                }}
              ></motion.div>
            ))}
          </div>
        </div>
        <table className="bot_info">
          <tbody>
            <tr>
              <td>Assets</td>
              <td>{bot?.bot?.assets?.map((asset) => asset.name).join(", ")}</td>
            </tr>
            <tr>
              <td>Indicators</td>
              <td>Relative Strength Index</td>
            </tr>
            <tr>
              <td>Order Type</td>
              <td>Buy, Sell</td>
            </tr>
            <tr>
              <td>Order Size</td>
              <td>70 Lots</td>
            </tr>
            <tr>
              <td># of Nodes</td>
              <td>8 Nodes</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BotCard;

import React, { useEffect, useState } from "react";
import "./Moving_Average.scss";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import ArrayHandle from "../../Components/CustomHandles/ArrayHandle/ArrayHandle";
import IntHandle from "../../Components/CustomHandles/IntHandle/IntHandle";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import NodeBox from "../../Components/NodeBox/NodeBox";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";

function Moving_Average({ data }) {
  const [period, setPeriod] = useState();
  const [periodDisabled, setPeriodDisabled] = useState(false);
  const [title, setTitle] = useState("Moving Average");

  const [avgData, setAvgData] = useState([]);
  const [dataDisabled, setDataDisabled] = useState(false);

  const [movingAvg, setMovingAvg] = useState();

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();

  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);

  const nodeId = useNodeId();

  const node = getNode(nodeId);
  const dispatch = useDispatch();

  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges]);
  useEffect(() => {
    data && setPeriod(data?.period);
  }, [data]);

  //to calculate Moving Average
  function calculateMovingAverage(arr, period) {
    if (arr.length < period || period <= 0) {
      // Handle this case, e.g., by returning an error message or an empty array.
      return [];
    }

    arr = [...arr].reverse();


    const movingAverages = [];
    let sum = 0;

    for (let i = 0; i < period; i++) {
      sum += parseFloat(arr[i]);
    }

    movingAverages.push(sum / period);

    for (let i = period; i < arr.length; i++) {
      sum = sum - parseFloat(arr[i - period]) + parseFloat(arr[i]);
      movingAverages.push(sum / period);
    }

    // Assuming setMovingAvg function is defined elsewhere
    setMovingAvg(movingAverages);

    return movingAverages;
  }

  //get prices and period
  useEffect(() => {
    const foundData = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_MA_input_1/array"
    );
    if (foundData) {
      let dataTarget = foundData.target;
      let dataHandle = foundData.targetHandle;
      if (nodeData[dataTarget]) {
        {
          setAvgData(nodeData[dataTarget].data[dataHandle]);
        }
      }
      setDataDisabled(true);
    } else {
      setDataDisabled(false);
    }

    const foundPeriod = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_MA_input_2/int"
    );
    if (foundPeriod) {
      let periodTarget = foundPeriod.target;
      let periodHandle = foundPeriod.targetHandle;
      if (nodeData[periodTarget]) {
        {
          setPeriod(parseInt(nodeData[periodTarget].data[periodHandle]));
        }
      }
      setPeriodDisabled(true);
    } else {
      setPeriodDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  useEffect(() => {
    period && setMovingAvg(calculateMovingAverage(avgData, period));
  }, [period, avgData]);

  //to set data to the connected edges
  useEffect(() => {
    if (!movingAvg) return;
    const findMA = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_MA_output/number"
    );

    if (findMA && findMA.length > 0) {
      findMA.map((el) => {
        let MAHandle = el.targetHandle;
        let MATarget = el.target;

        dispatch(
          nodeDataUpdate(MATarget, {
            [MAHandle]: movingAvg.pop()?.toFixed(2),
          })
        );
      });
    }

    const findPeriod = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_MA_output_period/number"
    );

    if (findPeriod && findPeriod.length > 0) {
      findPeriod.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: period,
          })
        );
      });
    }
  }, [connectedEdges, movingAvg, period]);

  //setting the props
  useEffect(() => {
    var element = document.getElementById("period");
    var html = element?.outerHTML;
    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          period: html,
        },
        period: period,
        label: title,
      })
    );
  }, [period, title]);
  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.period != period) {
        setPeriod(nodeProps[nodeId]?.prop?.period);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId]?.prop?.label);
      }
    }
  }, [nodeProps]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      selected={node?.selected}
      color="var(--purple)"
      body={
        <div className="MA_body">
          <div style={{ position: "relative" }}>
            <ArrayHandle
              type="target"
              id={nodeId + "_MA_input_1/array"}
              style={{
                left: "-22.5px",
                top: "10px",
              }}
              required={true}
              isConnectable={!dataDisabled}
            />
            <div className="MA_body_inner_title">
              <img src={arrowDown} />
              <p className="MA_body_inner_title_p">Input</p>
            </div>
          </div>
          <div className="MA_body_inner_body">
            <div className="STYLE_1">
              <div className="border_Div firstBorder_Div"></div>
              <NumberHandle
                type="target"
                id={nodeId + "_MA_input_2/int"}
                style={{
                  left: "-25px",
                }}
                isConnectable={!periodDisabled}
              />
              <p className="HandleDivP">Period</p>
              <input
                id="period"
                type="number"
                className="input_field"
                value={period}
                onChange={(e) => setPeriod(parseInt(e.target.value))}
                disabled={periodDisabled}
              />
              <NumberHandle
                type="source"
                id={nodeId + "_MA_output_period/number"}
                style={{ right: "-25px" }}
              />
            </div>
            <div className="handleDiv" style={{ position: "relative" }}>
              <div className="handleDivPHandle">
                <div className="border_Div"></div>
                <NumberHandle
                  id={nodeId + "_MA_output/number"}
                  type="source"
                  isConnectable={true}
                  style={{
                    right: "-25px",
                  }}
                />
              </div>
              <p className="HandleDivP">MA Value</p>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Moving_Average;

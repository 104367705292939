import React, { useEffect, useState } from "react";
import "./LearnMorePop.scss";
import { motion } from "framer-motion";

function LearnMorePop({ information, show, setShow }) {
  const [info, setInfo] = useState();
  useEffect(() => {
    if (!information) return;
    setInfo(information);
  }, [information]);
  return (
    <motion.div
      animate={{
        opacity: show ? 1 : 0,
        x: show ? 0 : 100,
        display: show ? "block" : "none",
      }}
      initial={{ opacity: 0, x: 100 }}
      className="learn_more_pop"
    >
      <div className="learn_more_header">
        <div className="learn_more_title">{info?.label}</div>
        <div
          className="learn_more_type"
          style={{
            color: info?.color,
          }}
        >
          {info?.type}
        </div>
      </div>
      <div className="learn_more_body">
        <div className="learn_more_sec">
          <div className="learn_more_sec_header">{info?.label} Description</div>
          <div className="learn_more_sec_desc">{info?.details}</div>
          <img className="learn_more_sec_img" src={info?.img} alt="video" />
        </div>
        <div className="learn_more_sec">
          <div className="learn_more_sec_header">
            How to Use the {info?.label}
          </div>
          <div className="learn_more_sec_subheader">1 - Input Parameters:</div>
          <div className="learn_more_sec_body">
            {info?.inputs?.map((input, index) => (
              <div key={index}>
                <span className="learn_more_sec_text">
                  {input.name} {input.desc}
                </span>
              </div>
            ))}
          </div>
          <div className="learn_more_sec_subheader">2 - Outputs:</div>
          <div className="learn_more_sec_body">
            {info?.outputs?.map((output, index) => (
              <div key={index}>
                <span className="learn_more_sec_text">
                  {output.name} {output.desc}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="learn_more_sec">
          <div className="learn_more_sec_header">Practical Application:</div>
          <div
            className="learn_more_sec_body"
            style={{
              marginBottom: "0",
            }}
          >
            {info?.examples?.map((example, index) => (
              <div key={index}>
                <span className="learn_more_sec_text">{example.name}</span>
                <div className="learn_more_sec_body2">
                  {example.steps?.map((step, index) => (
                    <div className="learn_more_sec_text" key={index}>
                      {step}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default LearnMorePop;

import React, { FC, useState, useEffect } from "react";
import "./NumberNode.scss";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NodeBox from "../../Components/NodeBox/NodeBox";

function NumberNode({ data }) {
  const [number, setNumber] = useState();
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [title, setTitle] = useState("Number Node");

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes } = useReactFlow();

  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);

  const nodeId = useNodeId();

  useEffect(() => {
    data?.value && setNumber(data?.value);
  }, [data]);
  const node = getNode(nodeId);

  const dispatch = useDispatch();
  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //get the number from the connected node
  useEffect(() => {
    const foundNumber = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_number_input/number"
    );
    if (foundNumber) {
      let numberTarget = foundNumber.target;
      let numberHandle = foundNumber.targetHandle;
      if (nodeData[numberTarget]) {
        {
          setNumber(nodeData[numberTarget].data[numberHandle]);
        }
      }
      setNumberDisabled(true);
    } else {
      setNumberDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    const findNumber = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_number_output/number"
    );
    if (findNumber && findNumber.length > 0) {
      findNumber.map((el) => {
        let numberHandle = el.targetHandle;
        let numberTarget = el.target;

        dispatch(
          nodeDataUpdate(numberTarget, {
            [numberHandle]: number,
          })
        );
      });
    }
  }, [connectedEdges, number, title]);

  //setting the props
  useEffect(() => {
    var element = document.getElementById("number");
    var html = element?.outerHTML;
    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          value: html,
        },
        hide: true,
        value: number,
        label: title,
      })
    );
  }, [number]);
  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.value != number) {
        setNumber(nodeProps[nodeId].prop.value);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color=" var(--dark-purple)"
      selected={node?.selected}
      body={
        <div className="numberNode_bottom ">
          <NumberHandle
            type="target"
            id={nodeId + "_number_input/number"}
            style={{ left: "-22px" }}
            isConnectable={!numberDisabled}
          />
          <div className="numberNode_bottom_inner">
            <div className="title">Value</div>
            <input
              id="number"
              className="input_field"
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              disabled={numberDisabled}
            />
          </div>

          <NumberHandle
            type="source"
            id={nodeId + "_number_output/number"}
            style={{ right: "-22px" }}
          />
        </div>
      }
    />
  );
}

export default NumberNode;

// flowSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  Edge,
  Node,
} from "reactflow";
import { NodeChange, EdgeChange, Connection, updateEdge } from "reactflow";

const initialNodes = []; // Replace with your initial nodes
const initialEdges = []; // Replace with your initial edges
const connectionList = {};
const flowSlice = createSlice({
  name: "flow",
  initialState: {
    nodes: initialNodes,
    edges: initialEdges,
    connectionList: connectionList,
    orders: [],
    access: false,
    accessError: "",
  },
  reducers: {
    updateNodes: (state, action) => {
      state.nodes = applyNodeChanges(action.payload, state.nodes);
      const node = action.payload[0];
      if (node?.type === "remove") {
        const conn = { ...state.connectionList };

        if (conn[node.id]) {
          delete conn[node.id];
        }

        for (const key in conn) {
          conn[key] = conn[key].filter((obj) => obj !== node.id);

          if (conn[key].length === 0) {
            delete conn[key];
          }
        }
        state.connectionList = conn;
      }
    },
    updateEdges: (state, action) => {
      state.edges = applyEdgeChanges(action.payload, state.edges);
    },
    addNewEdge: (state, action) => {
      state.edges = addEdge(action.payload, state.edges);
    },
    onUpdateEdge: (state, action) => {
      const { oldEdge, newConnection } = action.payload;

      state.edges = updateEdge(oldEdge, newConnection, state.edges);
    },
    onAddNode: (state, action) => {
      const newNode = action.payload;
      state.nodes = [...state.nodes, newNode];
    },

    removeEdge: (state, action) => {
      state.edges = state.edges.filter((edge) => edge.id !== action.payload.id);
    },
    addConnectionList: (state, action) => {
      const newlist = { ...state.connectionList };
      if (!newlist[action.payload.source]) {
        newlist[action.payload.source] = [];
      }
      newlist[action.payload.source].push(action.payload.target);
      state.connectionList = newlist;
    },

    removeConnectionList: (state, action) => {
      const newList = { ...state.connectionList };
      const children = newList[action.payload.source];
      if (children) {
        const index = children.indexOf(action.payload.target);
        if (index !== -1) {
          children.splice(index, 1);
        }
        if (children.length === 0) {
          delete newList[action.payload.source];
        }
      }
      state.connectionList = newList;
    },
    CleanNodesAndEdges: (state) => {
      state.nodes = [];
      state.edges = [];
      state.connectionList = {};
    },

    addOrder: (state, action) => {
      state.orders = [...state.orders, action.payload];
    },
    changeAccess: (state, action) => {
      state.access = action.payload;
      state.accessError = action.payload ? "" : "Wrong password!";
    },
  },
});

export const {
  updateNodes,
  updateEdges,
  addNewEdge,
  removeEdge,
  onUpdateEdge,
  onAddNode,
  removeConnectionList,
  addConnectionList,
  CleanNodesAndEdges,
  addOrder,
  changeAccess,
} = flowSlice.actions;
export default flowSlice.reducer;

import React, { FC, useCallback } from "react";
import "./ArrayHandle.scss";
import {
  Connection,
  Handle,
  HandleType,
  Position,
  useNodeId,
  useStore,
} from "reactflow";

function ArrayHandle({
  id,
  type,
  onConnet,
  isValidConnection,
  isConnectable,
  style,
  required
}) {
  return (
    <Handle
      type={type}
      position={type === "target" ? Position.Left : Position.Right}
      id={id}
      onConnect={onConnet}
      isValidConnection={(connection) => {
        return connection.targetHandle?.includes("/array") ?? false;
      }}
      isConnectable={isConnectable}
      isConnectableStart={type === "target" ? false : true}
      isConnectableEnd={type === "source" ? false : true}
      style={style}
      className="array_handle"
    >
      <div className="arrayHandle_background_Div">
        <div
          className={
            required == true
              ? "arrayHandle_tiltSquareDivRequired"
              : "arrayHandle_tiltSquareDiv"
          }
        ></div>
      </div>
    </Handle>
  );
}

export default ArrayHandle;

import { steps } from "framer-motion";
import man from "../../Assets/Images/man.png";

export const ATRData = {
  node: "ATR",
  type: "Indicator",
  desc: "The Average True Range (ATR) is a volatility indicator that measures the degree of price movement for an asset.",
  color: "rgb(239, 171, 255)",
  label: "ATR Node",
  img: man,
  details:
    "Meet the ATR (Average True Range) Node, your go-to buddy for gauging market volatility. This handy tool measures the range of price movements, helping you understand how much an asset typically moves in a given timeframe. With the ATR Node, you'll be able to spot those wild market swings and stay ahead of the game.",

  inputs: [
    {
      name: "Asset:",
      desc: "Select the asset you want to monitor. The ATR Node will keep an eye on its ups and downs.",
    },
    {
      name: "Timeframe:",
      desc: "Choose the timeframe for your data. Want a daily scoop or a monthly digest? It’s all up to you!",
    },
    {
      name: "Period (default: 14):",
      desc: "Set the number of periods for the ATR calculation. The standard is 14, but you can tweak it to fit your trading style.",
    },
    {
      name: "Source:",
      desc: "Pick the data source, typically the closing price, but you can experiment with other points too.",
    },
  ],

  outputs: [
    {
      name: "ATR Value - Live:",
      desc: "Get the latest ATR value, which shows the current volatility of your chosen asset.",
    },
    {
      name: "ATR Value - History:",
      desc: "An array of historical ATR values, perfect for analyzing trends and volatility over time.",
    },
    {
      name: "Trigger High Volatility:",
      desc: "A signal triggered when the ATR value exceeds a certain threshold, indicating increased market volatility.",
    },
    {
      name: "Trigger Low Volatility:",
      desc: "A signal triggered when the ATR value falls below a certain threshold, indicating decreased market volatility.",
    },
  ],

  examples: [
    {
      name: "The ATR Node is like your personal market weather forecaster, giving you insights into how stormy or calm the trading seas are. Here’s how you can use it to navigate the market:",
      steps: [
        "High Volatility Signal: When the ATR value rises above your set threshold, it’s like a storm warning. Brace yourself for bigger price swings.",
        "Low Volatility Signal: When the ATR value drops below your set threshold, it’s like a calm weather forecast. Expect quieter, more predictable market movements.",
        "Neutral: When the ATR value is stable, the market is in a steady state. Time to chill and watch the charts.",
      ],
    },

    {
      name: "By integrating the ATR Node into your trading strategy, you’ll be better equipped to handle the ebb and flow of the market. Whether you’re a thrill-seeker looking for big moves or a calm trader preferring steady waters, the ATR Node has got your back. Get ready to geek out on volatility and make smarter trades!",
    },
  ],
};

import React, { useEffect, useState } from "react";
import {
  Routes as ParentRoute,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import FlowPanel from "./Pages/FlowPanel/FlowPanel";
import Home from "./Pages/Home/Home";
import RunSummary from "./Pages/RunSummary/RunSummary";
import Password from "./Pages/Password/Password";
import WaitList from "./Pages/WaitList/WaitList";
import NavBar from "./Components/NavBar/NavBar";

function Routes() {
  const { pathname } = useLocation();
  const naviagte = useNavigate();
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("user") === "logged") {
      setLogged(true);
    }
  }, [window.localStorage.getItem("user")]);

  useEffect(() => {
    if (logged && (pathname === "/login" || pathname === "/logIn")) {
      naviagte("/home");
    }
  }, [pathname, logged]);
  return (
    <>
      <ParentRoute>
        <Route path="/" element={<WaitList />} />
      </ParentRoute>

      {logged ? (
        <>
          <NavBar />
          <ParentRoute>
            <Route path="/home" element={<Home />} />
            <Route path="/flowPanel/:id" element={<FlowPanel />} />
            <Route path="/run/:id" element={<RunSummary />} />
          </ParentRoute>
        </>
      ) : (
        <ParentRoute>
          <Route path="/login" element={<Password />} />
        </ParentRoute>
      )}
    </>
  );
}

export default Routes;

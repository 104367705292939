import React, { useEffect, useState } from "react";
import "./NodeInformation.scss";
import { useDispatch, useSelector } from "react-redux";
import { nodePropDataUpdate } from "../../Redux/Nodes/NodesActions";
import InformationPopUp from "../../Components/InformationPopUp/InformationPopUp";
function NodeInformation() {
  const allNodes = useSelector((state) => state?.flow?.nodes);

  const dispatch = useDispatch();
  const [selectedNode, setSelectedNode] = useState({});
  const [addedElements, setAddedElements] = useState([]);
  const { nodeProps } = useSelector((state) => state.nodes);
  useEffect(() => {
    if (allNodes.length > 0 && allNodes) {
      const newSel = allNodes?.filter((el) => el.selected == true);
      //if both are same then no need to update
      if (newSel?.length == 1) {
        setAddedElements([]);
        setSelectedNode(newSel[0]);
      } else {
        setSelectedNode([]);
      }
    }
  }, [allNodes]);

  const toHtml = (id, value, el, nodeId, text) => {
    if (!addedElements.includes(id) && document.getElementById(id)) {
      const itemDiv = document.createElement("div");
      if (itemDiv) {
        itemDiv.innerHTML = el; // Set the innerHTML first

        const firstChild = itemDiv.firstChild;

        // Check if the first child exists and set its value if necessary
        if (firstChild) {
          if (firstChild.type === "checkbox") {
            firstChild.checked = value; // Set the checked property for checkboxes
          } else if (firstChild.type === "select-one") {
            firstChild.value = value; // Set the value for select elements
          } else {
            firstChild.value = value; // Set the value for other input types
          }

          if (firstChild.type === "select-one") {
            firstChild.addEventListener("change", (e) => {
              dispatch(nodePropDataUpdate(nodeId, text, e.target.value));
            });
          } else if (firstChild.type === "checkbox") {
            firstChild.addEventListener("change", (e) => {
              dispatch(nodePropDataUpdate(nodeId, text, e.target.checked));
            });
          } else if (firstChild.type) {
            firstChild.addEventListener("input", (e) => {
              dispatch(nodePropDataUpdate(nodeId, text, e.target.value));
            });
          } else {
            firstChild.addEventListener("click", (e) => {
              dispatch(nodePropDataUpdate(nodeId, text, !value));
            });
          }
        }

        setAddedElements([...addedElements, id]);

        const container = document.getElementById(id);
        // Remove existing child elements before appending the new one
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }

        container.appendChild(itemDiv);
      }
    }
  };
  useEffect(() => {
    if (selectedNode && Object.keys(selectedNode)?.length > 0) {
      const nodeId = selectedNode?.id;
      const nodeProperties = nodeProps[nodeId]?.prop;

      if (
        nodeProperties &&
        Object.keys(nodeProperties).length > 0 &&
        addedElements.length > 0
      ) {
        addedElements.forEach((elementId) => {
          const element = document.getElementById(elementId);
          if (element) {
            const newEl = element.firstChild?.firstChild;
            if (newEl) {
              Object.keys(nodeProperties).forEach((propKey, i) => {
                if (propKey !== "label" && propKey !== "html" && propKey) {
                  const expectedElementId = nodeId + ":" + i;
                  if (
                    expectedElementId === elementId.split("main")[1] &&
                    nodeProperties[propKey]
                  ) {
                    newEl.value = nodeProperties[propKey];
                    if (typeof nodeProperties[propKey] === "boolean") {
                      newEl.checked = nodeProperties[propKey];
                    }
                  }
                }
              });
            }
          }
        });
      }
    }
  }, [nodeProps, addedElements, selectedNode]);

  const [showPop, setShowPop] = useState(false);
  useEffect(() => {
    if (
      Object.keys(selectedNode).length > 0 &&
      nodeProps?.[selectedNode?.id] &&
      !nodeProps?.[selectedNode?.id]?.prop?.hide &&
      nodeProps?.[selectedNode?.id]?.prop?.openSettings
    ) {
      setShowPop(true);
    } else {
      setShowPop(false);
    }
  }, [nodeProps, selectedNode]);

  return (
    <div className="nodeInformation_main">
      {!nodeProps?.[selectedNode?.id]?.prop?.hide && (
        <InformationPopUp
          show={showPop}
          setShow={setShowPop}
          title={nodeProps?.[selectedNode?.id]?.prop?.label}
          type={nodeProps?.[selectedNode?.id]?.prop?.info?.type}
          desc={nodeProps?.[selectedNode?.id]?.prop?.info?.desc}
          color={nodeProps?.[selectedNode?.id]?.prop?.info?.color}
          info={nodeProps?.[selectedNode?.id]?.prop?.info}
          id={selectedNode?.id}
          html={
            nodeProps &&
            nodeProps[selectedNode.id] &&
            Object.keys(nodeProps[selectedNode.id])?.length > 0 &&
            nodeProps[selectedNode?.id]?.prop &&
            // nodeProps[node?.id]?.prop?.length>0  &&

            Object.keys(nodeProps[selectedNode.id]?.prop)?.map((el, i) => {
              return (
                <>
                  {el !== "label" &&
                  el !== "html" &&
                  el !== "dataTable" &&
                  el !== "others" &&
                  el !== "info" &&
                  nodeProps[selectedNode.id]?.prop?.html[el]
                    ? el && (
                        <div className="node_html_data">
                          <p className="node_html_data_label">
                            {el.charAt(0).toUpperCase() +
                              el.slice(1).split("_").join(" ")}
                          </p>
                          <div id={"main" + selectedNode.id + ":" + i}>
                            {toHtml(
                              `main${selectedNode.id + ":" + i}`,
                              nodeProps[selectedNode.id]?.prop[el],
                              nodeProps[selectedNode.id]?.prop?.html[el],
                              selectedNode?.id,
                              el
                            )}
                          </div>
                        </div>
                      )
                    : el &&
                      el == "dataTable" && (
                        <table className="dataTable_asset">
                          <tr>
                            <th>O</th>
                            <th>H</th>
                            <th>L</th>
                            <th>C</th>
                            <th>Date</th>
                          </tr>
                          {nodeProps[selectedNode.id]?.prop[el]?.map(
                            (elem, i) => {
                              return (
                                <tr>
                                  <td>{elem?.open}</td>
                                  <td>{elem?.high}</td>
                                  <td>{elem?.low}</td>
                                  <td>{elem?.close}</td>
                                  {/* <td>{convertToBeirutTime(elem.date)}</td> */}
                                </tr>
                              );
                            }
                          )}
                        </table>
                      )}
                </>
              );
            })
          }
        />
      )}
    </div>
  );
}

export default NodeInformation;

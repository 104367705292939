import React, { FC, useState, useEffect } from "react";
import "./MultiplyNode.scss";
import {
  Edge,
  Handle,
  Position,
  getConnectedEdges,
  useNodeId,
  useReactFlow,
} from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NodeBox from "../../Components/NodeBox/NodeBox";

function MultiplyNode() {
  const dispatch = useDispatch();

  const [number, setNumber] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [title, setTitle] = useState("Multiply");
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [numberDisabled2, setNumberDisabled2] = useState(false);

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();

  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);

  const nodeId = useNodeId();

  const nodes = getNodes();

  const node = getNode(nodeId);
  //get the connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //get the number from the connected node
  useEffect(() => {
    const foundNumber1 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_multiply_input1/number"
    );
    if (foundNumber1) {
      let numberTarget1 = foundNumber1.target;
      let numberHandle1 = foundNumber1.targetHandle;
      if (nodeData[numberTarget1]) {
        {
          setNumber(nodeData[numberTarget1].data[numberHandle1]);
        }
      }
      setNumberDisabled(true);
    } else {
      setNumberDisabled(false);
    }

    const foundNumber2 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_multiply_input2/number"
    );
    if (foundNumber2) {
      let numberTarget2 = foundNumber2.target;
      let numberHandle2 = foundNumber2.targetHandle;
      if (nodeData[numberTarget2]) {
        {
          setNumber2(nodeData[numberTarget2].data[numberHandle2]);
        }
      }
      setNumberDisabled2(true);
    } else {
      setNumberDisabled2(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    if (number >= 0 && number2 >= 0) {
      const findNumber = connectedEdges.filter(
        (edge) => edge.sourceHandle === nodeId + "_multiply_output/number"
      );
      if (findNumber && findNumber.length > 0) {
        findNumber.map((el) => {
          let numberHandle = el.targetHandle;
          let numberTarget = el.target;

          dispatch(
            nodeDataUpdate(numberTarget, {
              [numberHandle]: parseFloat(number) * parseFloat(number2) || 0,
            })
          );
        });
      }
    }
  }, [connectedEdges, number, number2]);

  //setting the props
  useEffect(() => {
    var element1 = document.getElementById("value1");
    var html1 = element1?.outerHTML;
    var element2 = document.getElementById("value2");
    var html2 = element2?.outerHTML;
    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          value1: html1,
          value2: html2,
        },
        hide: true,
        value1: number,
        value2: number2,
        label: title,
      })
    );
    dispatch(
      nodeDataUpdate(nodeId, {
        [nodeId + "_multiply_output/number"]: (
          parseFloat(number) + parseFloat(number2)
        )?.toFixed(2),
      })
    );
  }, [number, number2, title]);
  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.value1 != number) {
        setNumber(nodeProps[nodeId].prop.value1);
      }
      if (nodeProps[nodeId]?.prop?.value2 != number2) {
        setNumber2(nodeProps[nodeId].prop.value2);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      selected={node?.selected}
      color="var(--fituki)"
      body={
        <div className="sumNode_bottom ">
          <div className="sumNode_bottom_inner_main">
            <div className="sumNode_bottom_inner">
              <div className="handleDiv" style={{ position: "relative" }}>
                <div className="handleDivPHandle">
                  {/* <div className="border_Div firstBorder_Div"></div> */}
                  <NumberHandle
                    type="target"
                    id={nodeId + "_multiply_input1/number"}
                    style={{ left: "-25px" }}
                    isConnectable={!numberDisabled}
                  />
                </div>
                <p
                  className="HandleDivP"
                  style={{
                    fontFamily: "Techead_Bold",
                    color: "white",
                    width: "5.5rem",
                  }}
                >
                  Value 1
                </p>
                <input
                  id="value1"
                  className="input_field"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  disabled={numberDisabled}
                />
              </div>
            </div>
            <div className="sumNode_bottom_inner">
              <div className="handleDiv" style={{ position: "relative" }}>
                <div className="handleDivPHandle">
                  {/* <div className="border_Div "></div> */}
                  <NumberHandle
                    type="target"
                    id={nodeId + "_multiply_input2/number"}
                    style={{ left: "-25px" }}
                    isConnectable={!numberDisabled2}
                  />
                </div>
                <p
                  className="HandleDivP"
                  style={{
                    fontFamily: "Techead_Bold",
                    color: "white",
                    width: "5.5rem",
                  }}
                >
                  Value 2
                </p>
                <input
                  id="value2"
                  className="input_field"
                  type="number"
                  value={number2}
                  onChange={(e) => setNumber2(e.target.value)}
                  disabled={numberDisabled2}
                />
              </div>
            </div>
            <div
              className="handleDiv"
              style={{ position: "relative", marginTop: "1rem" }}
            >
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_multiply_output/number"}
                  style={{ right: "-25px" }}
                />
              </div>{" "}
              <p
                className="HandleDivP"
                style={{
                  width: "3.2rem",
                }}
              >
                Result
              </p>
              <input
                id="value2"
                className="input_field"
                type="number"
                value={parseFloat(number) * parseFloat(number2)}
                disabled={numberDisabled2}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default MultiplyNode;

import { NodesActions } from "./NodesReducer";

export const nodeDataUpdate = (nodeId, data) => async (dispatch) => {
  dispatch(NodesActions.addNodeData({ nodeId, data }));
};
export const nodePropUpdate = (nodeId, prop) => async (dispatch) => {
  dispatch(NodesActions.addNodeProp({ nodeId, prop }));
};

//clear all data from a node
export const nodeDataClear = () => async (dispatch) => {
  dispatch(NodesActions.clearNodeData());
};
export const deleteNodeProp = (key) => async (dispatch) => {
  dispatch(NodesActions.deleteNodeProp({key}));
};

export const nodePropDataUpdate = (nodeId, key, data) => async (dispatch) => {
  dispatch(NodesActions.updataNodeProp({ nodeId, key,data }));
};
import man from "../../Assets/Images/man.png";

export const BBData = {
  type: "Indicator",
  desc: "Bollinger Bands (BB) are a volatility indicator that measures market volatility and provides relative definitions of high and low prices.",
  color: "rgb(239, 171, 255)",

  node: "BB",
  label: "BB Node",
  img: man,
  details:
    "Introducing the BB (Bollinger Bands) Node, your ultimate tool for understanding market volatility and price action. Bollinger Bands help you visualize market trends and identify potential breakouts by plotting bands around a moving average. The bands expand and contract based on market volatility, giving you a dynamic view of the market's highs and lows.",

  inputs: [
    {
      name: "Asset:",
      desc: "Select the asset you want to analyze. The BB Node will wrap its bands around your chosen asset.",
    },
    {
      name: "Timeframe:",
      desc: "Choose the timeframe for the data you want to import. Whether you're looking at daily, weekly, or monthly data, the BB Node has got you covered.",
    },
    {
      name: "Period (default: 20):",
      desc: "Set the period for the moving average calculation. The default is 20, but feel free to tweak it to match your strategy.",
    },
    {
      name: "Standard Deviation (default: 2):",
      desc: "Set the number of standard deviations for the bands. This determines how wide the bands will be.",
    },
  ],

  outputs: [
    {
      name: "Upper Band Value - Live:",
      desc: "Get the latest upper band value, which indicates the higher boundary of typical price movement.",
    },
    {
      name: "Lower Band Value - Live:",
      desc: "Get the latest lower band value, showing the lower boundary of typical price movement.",
    },
    {
      name: "Middle Band Value - Live:",
      desc: "The current value of the middle band, which is a simple moving average.",
    },
    {
      name: "BB Values - History:",
      desc: "An array of historical Bollinger Bands values, perfect for spotting trends and analyzing past market behavior.",
    },
    {
      name: "Trigger Breakout Above:",
      desc: "A signal generated when the price crosses above the upper band, indicating a potential breakout.",
    },
    {
      name: "Trigger Breakout Below:",
      desc: "A signal generated when the price crosses below the lower band, indicating a potential breakdown.",
    },
  ],

  examples: [
    {
      name: "The BB Node is like your market detective, constantly analyzing price movements and volatility. Here’s how you can use it to stay ahead of the market curve:",

      steps: [
        "Upper Band Breakout: When the price crosses above the upper band, it’s a signal that the asset might be overbought. Time to consider selling or shorting.",
        "Lower Band Breakout: When the price crosses below the lower band, it’s a signal that the asset might be oversold. Time to consider buying.",
        "Middle Band: The middle band acts as a baseline, helping you understand the average price movement over your chosen period.",
      ],
    },
    {
      name: "By incorporating the BB Node into your trading strategy, you can better anticipate market movements and make more informed trading decisions. Whether you’re looking for breakout opportunities or trying to gauge market volatility, the BB Node is your trusty companion. Get ready to geek out on market analysis and trade like a pro!",
    },
  ],
};

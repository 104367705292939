import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createBotLoading: false,
  createBotError: "",
  createBotSuccess: false,

  bots: [],
  botsLoading: false,
  botsError: "",

  bot: {},
  getSingleBotLoading: false,
  getSingleBotError: "",

  editBotLoading: false,
  editBotError: "",
  editBotSuccess: false,

  getAllTradesLoading: false,
  getAllTradesError: "",
  trades: [],
};

const HomeSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    createBotRequest: (state) => {
      state.createBotLoading = true;
      state.createBotError = "";
      state.createBotSuccess = false;
    },

    createBotSuccess: (state) => {
      state.createBotLoading = false;
      state.createBotError = "";
      state.createBotSuccess = true;
    },

    createBotError: (state, action) => {
      state.createBotLoading = false;
      state.createBotError = action.payload;
      state.createBotSuccess = false;
    },

    getBotRequest: (state) => {
      state.botsLoading = true;
      state.botsError = "";
    },

    getBotSuccess: (state, action) => {
      state.botsLoading = false;
      state.botsError = "";
      state.bots = action.payload;
    },

    getBotError: (state, action) => {
      state.botsLoading = false;
      state.botsError = action.payload;
    },

    getSingleBotRequest: (state) => {
      state.getSingleBotLoading = true;
      state.getSingleBotError = "";
    },

    getSingleBotSuccess: (state, action) => {
      state.getSingleBotLoading = false;
      state.getSingleBotError = "";
      state.bot = action.payload;
    },

    getSingleBotError: (state, action) => {
      state.getSingleBotLoading = false;
      state.getSingleBotError = action.payload;
    },

    editBotRequest: (state) => {
      state.editBotLoading = true;
      state.editBotError = "";
      state.editBotSuccess = false;
    },

    editBotSuccess: (state) => {
      state.editBotLoading = false;
      state.editBotError = "";
      state.editBotSuccess = true;
    },

    editBotError: (state, action) => {
      state.editBotLoading = false;
      state.editBotError = action.payload;
      state.editBotSuccess = false;
    },

    getAllTradesRequest: (state) => {
      state.getAllTradesLoading = true;
      state.getAllTradesError = "";
    },

    getAllTradesSuccess: (state, action) => {
      state.getAllTradesLoading = false;
      state.getAllTradesError = "";
      state.trades = action.payload;
    },

    getAllTradesError: (state, action) => {
      state.getAllTradesLoading = false;
      state.getAllTradesError = action.payload;
    },

    clearStates: (state) => {
      state.createBotError = "";
      state.createBotSuccess = false;

      state.botsError = "";

      state.getSingleBotError = "";

      state.editBotError = "";
      state.editBotSuccess = false;
    },
  },
});

export const homeActions = HomeSlice.actions;

export default HomeSlice.reducer;

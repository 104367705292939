import React, { useState } from "react";
import "./MeetAi.scss";
import logo_ai from "../../../Assets/Images/logo_ai.svg";
import bot1 from "../../../Assets/Images/bot1.png";
import bot2 from "../../../Assets/Images/bot2.png";
import bot3 from "../../../Assets/Images/bot3.png";
import Loader from "../../../Components/Loader/Loader";

function MeetAi() {
  const [loading, setLoading] = useState(false);
  const bots = [
    {
      title:
        "Create a bot that buys 2 Lots of XAUUSD when 14 Period RSI Goes Below 30",
      image: bot1,
    },
    {
      title:
        "A bot that uses 50 & 200 SMA Cross to Buy 1 EURUSD Lot and that uses MACD cross to exit positions",
      image: bot2,
    },
    {
      title:
        "Create a SWAP Arbitrage Bot that Buys 2 Lots of XAUUSD in one account and sells 2 Lots in another account",
      image: bot3,
    },
  ];

  const [index, setIndex] = useState(0);
  return (
    <div className="meet_ai_main_div">
      <div className="meet_ai_title">
        Meet
        <img src={logo_ai} className="meet_ai_logo" />
        Oriqon AI
      </div>
      <div className="meet_ai_input_div">
        <img src={logo_ai} className="meet_ai_logo_small" />
        <div className="meet_ai_text_info">{bots[index].title}</div>
        <button
          className="meet_ai_button"
          onClick={() => {
            setLoading(true);
            if (index === bots.length - 1) {
              setIndex(0);
            } else setIndex(index + 1);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
        >
          Generate
        </button>
      </div>
      <div className="meet_at_bot_generation">
        {loading ? (
          <Loader />
        ) : (
          <img src={bots[index].image} className="meet_ai_image" />
        )}
      </div>
    </div>
  );
}

export default MeetAi;

import React, { FC, useState, useEffect } from "react";
import "./AbsoluteNode.scss";
import {
  Edge,
  Handle,
  Position,
  getConnectedEdges,
  useNodeId,
  useReactFlow,
} from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NodeBox from "../../Components/NodeBox/NodeBox";

function AbsoluteNode() {
  const dispatch = useDispatch();

  const [number, setNumber] = useState(0);
  const [title, setTitle] = useState("Absolute");
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [numberDisabled2, setNumberDisabled2] = useState(false);

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();

  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);

  const nodeId = useNodeId();

  const nodes = getNodes();

  const node = getNode(nodeId);
  //get the connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //get the number from the connected node
  useEffect(() => {
    const foundNumber1 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_absolute_input1/number"
    );
    if (foundNumber1) {
      let numberTarget1 = foundNumber1.target;
      let numberHandle1 = foundNumber1.targetHandle;
      if (nodeData[numberTarget1]) {
        {
          setNumber(nodeData[numberTarget1].data[numberHandle1]);
        }
      }
      setNumberDisabled(true);
    } else {
      setNumberDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    const findNumber = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_absolute_output/number"
    );
    if (findNumber && findNumber.length > 0) {
      findNumber.map((el) => {
        let numberHandle = el.targetHandle;
        let numberTarget = el.target;

        dispatch(
          nodeDataUpdate(numberTarget, {
            [numberHandle]: parseFloat(Math.abs(number)),
          })
        );
      });
    }
  }, [connectedEdges, number]);

  //   //setting the props
  //   useEffect(() => {
  //     var element1 = document.getElementById("value1");
  //     var html1 = element1?.outerHTML;
  //     dispatch(
  //       nodePropUpdate(nodeId, {
  //         html: {
  //           value1: html1,
  //         },
  //         hide: true,
  //         value1: number,
  //         label: title,
  //       })
  //     );
  //     dispatch(
  //       nodeDataUpdate(nodeId, {
  //         [nodeId + "_absolute_output/number"]: parseFloat(Math.abs(number)),
  //       })
  //     );
  //   }, [number, title]);
  //getting the props
  //   useEffect(() => {
  //     if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
  //       if (nodeProps[nodeId]?.prop?.value1 != number) {
  //         setNumber(nodeProps[nodeId].prop.value1);
  //       }
  //       if (nodeProps[nodeId]?.prop?.label != title) {
  //         setTitle(nodeProps[nodeId].prop.label);
  //       }
  //     }
  //   }, [nodeProps]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      selected={node?.selected}
      color="var(--fituki)"
      body={
        <div className="sumNode_bottom ">
          <div className="sumNode_bottom_inner_main">
            <div className="sumNode_bottom_inner">
              <div className="handleDiv" style={{ position: "relative" }}>
                <div className="handleDivPHandle">
                  {/* <div className="border_Div firstBorder_Div"></div> */}
                  <NumberHandle
                    type="target"
                    id={nodeId + "_absolute_input1/number"}
                    style={{ left: "-25px" }}
                    isConnectable={!numberDisabled}
                  />
                </div>
                <p
                  className="HandleDivP"
                  style={{
                    fontFamily: "Techead_Bold",
                    color: "white",
                    width: "5.5rem",
                  }}
                >
                  Value 1
                </p>
                <input
                  id="value1"
                  className="input_field"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  disabled={numberDisabled}
                />
              </div>
            </div>
            <div
              className="handleDiv"
              style={{ position: "relative", marginTop: "1rem" }}
            >
              <div className="handleDivPHandle">
                <div className="border_Div"></div>

                <NumberHandle
                  type="source"
                  id={nodeId + "_absolute_output/number"}
                  style={{ right: "-25px" }}
                />
              </div>{" "}
              <p
                className="HandleDivP"
                style={{
                  width: "3.2rem",
                }}
              >
                Result
              </p>
              <input
                id="value2"
                className="input_field"
                type="number"
                value={parseFloat(Math.abs(number))}
                disabled={numberDisabled2}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default AbsoluteNode;

import React, { useEffect, useState, useRef } from "react";
import "./NodeBox.scss";
import { NodeResizer, useNodeId } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { nodePropDataUpdate } from "../../Redux/Nodes/NodesActions";
import { all } from "axios";
import burger_settings from "../../Assets/Images/burger_settings.svg";

export default function NodeBox({
  title,
  color,
  upper,
  middle,
  body,
  selected,
  setTitle,
}) {
  const allNodes = useSelector((state) => state?.flow?.nodes);

  const { nodeProps } = useSelector((state) => state.nodes);
  const nodeId = useNodeId();

  const [clicked, setClicked] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  let newcolor;
  useEffect(() => {
    if (clicked) {
      inputRef.current.focus();
    }
  }, [clicked]);
  newcolor = getComputedStyle(document.documentElement).getPropertyValue(
    color?.split("var(")[1]?.split(")")[0]
  );
  let R;
  let G;
  let B;
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  let rgb = hexToRgb(newcolor);

  R = 0.33 * rgb?.r + (1 - 0.33) * 255;
  G = 0.33 * rgb?.g + (1 - 0.33) * 255;
  B = 0.33 * rgb?.b + (1 - 0.33) * 255;
  const boxShadowStyle = {
    boxShadow: `21px 28px 27px 1px ${hexToRgb(color)}`,
    WebkitBoxShadow: `21px 28px 27px 1px ${color}`,
    MozBoxShadow: `21px 28px 27px 1px ${color}`,
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (allNodes && allNodes.length > 0) {
      if (!allNodes.filter((el) => el.id == nodeId)[0]?.selected)
        setOpen(false);
    }
  }, [allNodes]);

  useEffect(() => {
    if (nodeId) dispatch(nodePropDataUpdate(nodeId, "openSettings", open));
  }, [open]);
  return (
    <div
      className="mainCustomNode"
      style={{
        boxShadow: !selected
          ? "none"
          : `0.5px 0.5px 50px -5px ${newcolor + 60}`,
      }}
    >
      {/* <NodeResizer color="white" isVisible={selected} /> */}
      <div className="MainUpperCustomDiv" style={{ backgroundColor: color }}>
        {upper}
      </div>

      <div
        className={
          selected == true
            ? "MiddleCustomDiv nodeSelected"
            : "MiddleCustomDiv nodeNotSelected"
        }
      >
        {middle}

        <div
          className={!clicked ? "NodeTitle" : "NodeTitle openInput"}
          onDoubleClick={(e) => {
            e.stopPropagation();
            setClicked(true);
          }}
          style={{ color: `rgb(${R} , ${G}, ${B})` }}
        >
          {!clicked ? (
            title
          ) : (
            <input
              onClick={() => setClicked(true)}
              ref={inputRef}
              value={title}
              onChange={(e) => setTitle && setTitle(e.target.value)}
              onBlur={() => {
                setClicked(false);
                if (title === "") {
                  setTitle("Untitled Node");
                }
              }}
              disabled={!clicked}
              style={{ color: `rgb(${R} , ${G}, ${B})` }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setClicked(false);
                  if (title === "") {
                    setTitle("Untitled Node");
                  }
                }
              }}
            />
          )}
        </div>
        {!nodeProps[nodeId]?.prop?.hide && (
          <span
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={burger_settings} alt="burger" />
          </span>
        )}
      </div>
      <div
        className={
          selected == true
            ? "MainLowerCustomDiv nodeSelected"
            : "MainLowerCustomDiv nodeNotSelected"
        }
      >
        {body}
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  atrValues: {},
  atrValuesLoading: false,
  atrValuesError: "",
  atrValuesSuccess: false,

  atrLiveLoading: false,
  atrLiveError: "",
  atrLiveSuccess: false,
  atrLiveValue: {},
};

const ATRNodeSlice = createSlice({
  name: "ATR Node",
  initialState,
  reducers: {
    //get atr values
    getatrValuesRequest: (state) => {
      state.atrValuesLoading = true;
      state.atrValuesError = "";
      state.atrValuesSuccess = false;
    },

    getatrValuesSuccess: (state, action) => {
      state.atrValuesLoading = false;
      state.atrValuesError = "";
      state.atrValuesSuccess = true;
      state.atrValues[action.payload?.nodeId] = action.payload?.data;
      state.atrLiveValue = {};
    },

    getatrValuesError: (state, action) => {
      state.atrValuesLoading = false;
      state.atrValuesError = action.payload;
      state.atrValuesSuccess = false;
    },

    //get atr live values
    getatrLiveValuesRequest: (state) => {
      state.atrLiveLoading = true;
      state.atrLiveError = "";
      state.atrLiveSuccess = false;
    },

    getatrLiveValuesSuccess: (state, action) => {
      state.atrLiveLoading = false;
      state.atrLiveError = "";
      state.atrLiveSuccess = true;
      state.atrLiveValue[action.payload.nodeId] = action.payload?.data;
    },

    getatrLiveValuesError: (state, action) => {
      state.atrLiveLoading = false;
      state.atrLiveError = action.payload;
      state.atrLiveSuccess = false;
    },
  },
});
export default ATRNodeSlice.reducer;
export const ATRNodeActions = ATRNodeSlice.actions;

import React, { useEffect, useState } from "react";
import "./Popup.scss";
import { motion } from "framer-motion";

function Popup({ children, show, setShow }) {
  return (
    <motion.div
      className="popup_main"
      initial={{
        display: "none",
      }}
      animate={{
        display: !show ? "none" : "block",
      }}
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: show ? 0.7 : 0,
        }}
        className="popup_background"
        onClick={() => setShow(!show)}
      ></motion.div>
      <motion.div
        className="popup_body"
        initial={{
          position: "absolute",
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "30%",
          backgroundColor: "var(--black)",
          padding: "3rem",
          borderRadius: "1rem",
          scale: 0,
          opacity: 0,
        }}
        animate={{
          scale: show ? 1 : 0,
          opacity: show ? 1 : 0,
          filter: show ? "blur(0px)" : "blur(5px)",
          translateY: show ? "-50%" : "30%",
          transition: {
            scale: {
              duration: 0.5, // duration in seconds for scale
            },
            opacity: {
              duration: 0.3, // duration in seconds for opacity
            },
            translateY: {
              duration: 0.5, // duration in seconds for translateY
            },
            filter: {
              duration: 0.3, // duration in seconds for filter
            },
          },
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
}

export default Popup;

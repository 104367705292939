import React, { useEffect, useState } from "react";
import "./FAQs.scss";
import FaqBox from "./FaqBox/FaqBox";
import gsap from "gsap";
function FAQs() {
  const faqData = [
    {
      title: "What is a node-based trading bot building tool?",
      subTitle:
        "It's a platform that allows users to create trading bots by connecting different nodes (functional blocks) together. Instead of writing code, users design bots visually, making the process more intuitive and user-friendly.",
    },
    {
      title: "Do I need coding experience to use this platform?",
      subTitle:
        "No, our platform is designed for both beginners and experienced traders. The node-based system ensures that you can build bots without any coding knowledge. However, those with technical backgrounds may find advanced features beneficial.",
    },
    {
      title: "How secure is my trading data and strategy?",
      subTitle:
        "We prioritize user security. All data, including trading strategies, is encrypted and stored securely. We never share your information or strategies with third parties.",
    },
    {
      title: "Can I test my bot before deploying it live?",
      subTitle:
        "Absolutely! We provide a simulation environment where you can test your bots using historical data to ensure they operate as intended before live trading.",
    },
    {
      title: "Which trading platforms or exchanges can I connect my bot to?",
      subTitle: `Our tool is designed to integrate with a variety of major exchanges and platforms. Check our "Integrations" page for a detailed list and guides on how to connect.`,
    },
    {
      title: " Are there any costs involved?",
      subTitle: `We offer a free tier with basic functionalities suitable for beginners. Advanced features and higher processing capabilities are available in our premium packages. See our "Pricing" page for more details.`,
    },
    {
      title: "How do I get support if I face issues?",
      subTitle: `Our dedicated support team is here to assist you. Simply visit the "Contact Support" section, or for quick queries, check our community forums where fellow users and our team regularly share insights.`,
    },
    {
      title: "Can I share or sell my bot designs?",
      subTitle:
        "es, we have a marketplace feature that allows users to share or monetize their bot strategies if they wish. Ensure you're aware of our terms and conditions before doing so.",
    },
    {
      title: "What if I want to cancel or change my subscription?",
      subTitle: `You can modify your subscription level or cancel anytime from your account dashboard. For step-by-step guidance, visit our "Help" section.`,
    },
    {
      title: "How often do you update the platform or add new features?",
      subTitle:
        "We're committed to innovation. Our team regularly rolls out updates, new features, and improvements based on user feedback and industry trends. Join our newsletter to stay updated!",
    },
  ];
  const [clicked, setClicked] = useState(0);

  return (
    <div className="faqs_main_div">
      <div id="sec_2_title" className="waitList_sec3_title">
        FAQ
      </div>
      <div className="sec2_info_title">Frequently Asked Questions</div>

      <div className="faqBoxs_div">
        {faqData.map((item, index) => (
          <FaqBox
            key={index}
            id={index}
            title={index + 1 + ". " + item.title}
            subTile={item.subTitle}
            clicked={clicked}
            setClicked={setClicked}
          />
        ))}
      </div>
    </div>
  );
}

export default FAQs;

import { axiosReq } from "../../Utils";
import { OrderNodeActions } from "./OrderNodeReducer";

export const executrOrder = (data) => async (dispatch) => {
  dispatch(OrderNodeActions.executeOrderRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/execute-trade",
      {},
      {
        symbol: data?.symbol,
        volume: parseFloat(data?.volume),
        actionType: data?.actionType,
        stopLoss: parseFloat(data?.stopLoss),
        takeProfit: parseFloat(data?.takeProfit),
      },
      {}
    )
  ).then((res) => {
    if (res?.success) {
      dispatch(OrderNodeActions.executeOrderSuccess(res.data));
    } else {
      dispatch(OrderNodeActions.executeOrderError(res?.data?.message));
    }
  });
};

import React, { useEffect, useState } from "react";
import "./Bots.scss";
import logo from "../../../../Assets/Images/logo.svg";
import Button from "../../../../Components/Button/Button";
import add from "../../../../Assets/Images/Add.svg";
import BotCard from "./BotComponents/BotCard/BotCard";
import Loader from "../../../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getBots } from "../../../../Redux/Home/HomeActions";

function Bots() {
  const [noBots, setNoBots] = useState(true);
  const dispatch = useDispatch();

  const { botsLoading, bots } = useSelector((state) => state.HomeReducer);

  useEffect(() => {
    if (document.querySelector(".bots_main")) {
      var botsMain = document.querySelector(".bots_main");

      if (botsMain.scrollHeight > botsMain.clientHeight) {
        botsMain.style.paddingRight = "10px"; // Adjust this value as per your needs
      }
    }
  }, [bots]);

  useEffect(() => {
    dispatch(getBots());
  }, []);

  return (
    <>
      {botsLoading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Loader width={"7rem"} />
        </div>
      ) : (
        <div className="bots_main">
          {bots?.length <= 0 ? (
            <div className="not_bots">
              <img src={logo} alt="logo" className="logo" />
              <div className="no_bot_text">
                <div className="title">You haven't Created a Bot Yet</div>
                <div className="sub_title">
                  Once you create one, you will see it here
                </div>
              </div>
              <Button
                onClick={() => {
                  document.getElementById("new_button").click();
                }}
              >
                <div className="buttun_inner">
                  <img src={add} alt="add_img" className="add_img" />
                  <div className="button_text"> Create A New Bot</div>
                </div>
              </Button>
            </div>
          ) : (
            <div className="bots">
              {bots?.map((bot, index) => (
                <BotCard key={index} bot={bot} />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Bots;

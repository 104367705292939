import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactFlow } from "reactflow";

import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import "./FlowPanel.scss";

import { useHotkeys } from "react-hotkeys-hook";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import "reactflow/dist/style.css";
import AssetData from "../../Nodes/AssetData/AssetData";
import AssetsNode from "../../Nodes/AssetsNode/AssetsNode";
import BiggerNode from "../../Nodes/BiggerNode/BiggerNode";
import BooleanNode from "../../Nodes/BooleanNode/BooleanNode";
import ConditionNode from "../../Nodes/ConditionNode/ConditionNode";
import EqualNode from "../../Nodes/EqualNode/EqualNode";
import LowerNode from "../../Nodes/LowerNode/LowerNode";
import Moving_Average from "../../Nodes/Moving_Average/Moving_Average";
import NumberNode from "../../Nodes/NumberNode/NumberNode";
import OrderNode from "../../Nodes/OrderNode/OrderNode";
import RSINode from "../../Nodes/RSINode/RSINode";
import SumNode from "../../Nodes/SumNode/SumNode";
import NodesPopUp, { setId } from "../../PageComponents/NodesPopUp/NodesPopUp";
import {
  handleCleanFlow,
  handleConnect,
  handleEdgesChange,
  handleNodesChange,
} from "../../Redux/FlowPanel/FlowActions";
import { nodeDataClear } from "../../Redux/Nodes/NodesActions";
import NodeInformation from "../../PageComponents/NodeInformation/NodeInformation";
import { getSingleBot } from "../../Redux/Home/HomeActions";
import AndNode from "../../Nodes/AndNode/AndNode";
import DivideNode from "../../Nodes/DivideNode/DivideNode";
import MultiplyNode from "../../Nodes/MultiplyNode/MultiplyNode";
import SubtractNode from "../../Nodes/Subtract/Subtract";
import AbsoluteNode from "../../Nodes/AbsoluteNode/AbsoluteNode";
import SMANode from "../../Nodes/SMANode/SMANode";
import BBNode from "../../Nodes/BBNode/BBNode";
import ATRNode from "../../Nodes/ATRNode/ATRNode";
import { NodesActions } from "../../Redux/Nodes/NodesReducer";

const nodeTypes = {
  Number: NumberNode,
  RSI: RSINode,
  Asset: AssetsNode,
  SMA: SMANode,
  AssetData: AssetData,
  Bigger: BiggerNode,
  Sum: SumNode,
  Moving_Average: Moving_Average,
  Condition: ConditionNode,
  Lower: LowerNode,
  Equal: EqualNode,
  Boolean_Node: BooleanNode,
  Execute_Order: OrderNode,
  And: AndNode,
  Divide: DivideNode,
  Multiply: MultiplyNode,
  Subtract: SubtractNode,
  Absolute: AbsoluteNode,
  BB: BBNode,
  ATR: ATRNode,
};

const initialNodes = [];

const initialEdges = [];

function FlowPanel() {
  const dispatch = useDispatch();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [rfInstance, setRfInstance] = useState();
  const flow = rfInstance?.toObject();

  const { id } = useParams();

  localStorage.setItem("bot_id", id);

  const reactFlowWrapper = useRef(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback((params) => {
    setEdges((els) => addEdge({ ...params, animated: true }, els));
    dispatch(handleConnect(params));
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const proOptions = { hideAttribution: true };
  useEffect(() => {
    localStorage.setItem("test", JSON.stringify(flow));
  }, [nodes]);
  function download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }
  const reduxNodes = useSelector((state) => state?.flow?.nodes);

  function onDownload() {
    download(JSON.stringify(flow), "yourfile.json", "text/plain");
  }

  const { addNodes, addEdges } = useReactFlow();

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(handleCleanFlow());
    dispatch(nodeDataClear());
    setNodes([]);
    setEdges([]);
    // if (pathname.includes("RSI-Bot")) {
    //   addNodes(RsiNodes);
    //   addEdges(RsiEdges);
    // } else if (pathname.includes("Threshold-Bot")) {
    //   addNodes(ThresholdNodes);
    //   addEdges(ThresholdEdges);
    // } else if (pathname.includes("Moving-Average-Bot")) {
    //   addNodes(MovingAvgNodes);
    //   addEdges(MovingAvgEdges);
    // }
  }, [pathname]);

  const [copiedNodes, setCopiedNodes] = useState([]);

  const handleCopy = () => {
    const selectedNodes = reduxNodes.filter((element) => element?.selected);
    setCopiedNodes(selectedNodes);
  };

  const handlePaste = () => {
    const pastedNodes = copiedNodes.map((node) => ({
      ...node,
      id: `${node.id}-copy`,
      position: {
        x: node.position.x + 50,
        y: node.position.y + 50,
      },
      selected: true,
    }));
    pastedNodes.forEach((node) => {
      addNodes(node);
    });
  };

  // const handleKeyDown = (event) => {
  //   if (event.ctrlKey && event.key === "c") {
  //     handleCopy();
  //   }
  //   if (event.ctrlKey && event.key === "v") {
  //     handlePaste();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [reduxNodes]);

  const [show, setShow] = useState(false);

  useHotkeys("n", () => setShow(!show), [show]);
  useHotkeys("ctrl + c", () => handleCopy(), [reduxNodes]);
  useHotkeys("ctrl + v", () => handlePaste(), [reduxNodes]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("show") === "true") {
      setShow(true);
    }
  }, [searchParams.get("show")]);

  useEffect(() => {
    if (!show) {
      //remove show from search params
      setSearchParams({});
    }
  }, [show]);

  useEffect(() => {
    dispatch(getSingleBot(id));
  }, [id]);

  const { bot } = useSelector((state) => state.HomeReducer);

  useEffect(() => {
    if (bot?.bot && typeof bot?.bot != "object") {
      addNodes(JSON.parse(bot?.bot)?.flow?.nodes);
      const nodesN = JSON.parse(bot?.bot)?.flow?.nodes;
      let id = 0;
      nodesN.map((node) => {
        id =
          parseInt(node.id.split("_")[1]) > id
            ? parseInt(node.id.split("_")[1])
            : id;
      });
      setId(++id);
      addEdges(
        JSON.parse(bot?.bot)?.flow?.edges?.map((edge) => ({
          ...edge,
          animated: true,
        }))
      );
      dispatch(NodesActions.setNodeData(JSON.parse(bot?.bot)?.nodes?.nodeData));
      dispatch(
        NodesActions.setNodeProps(JSON.parse(bot?.bot)?.nodes?.nodeProps)
      );
    }
  }, [bot]);

  return (
    <div className="dndflow">
      <div
        className="reactflow-wrapper"
        style={{ width: "100vw", height: "100vh" }}
        ref={reactFlowWrapper}
      >
        <NodeInformation />
        <NodesPopUp show={show} setShow={setShow} />
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={(e) => {
            onNodesChange(e);
            dispatch(handleNodesChange(e));
          }}
          onEdgesChange={(e) => {
            onEdgesChange(e);
            dispatch(handleEdgesChange(e, edges));
          }}
          // onEdgeUpdate={onEdgeUpdate}
          // onEdgeUpdateStart={onEdgeUpdateStart}
          // onEdgeUpdateEnd={onEdgeUpdateEnd}
          onConnect={onConnect}
          onInit={setRfInstance}
          onDragOver={onDragOver}
          nodeTypes={nodeTypes}
          // selectNodesOnDrag={false}
          fitViewOptions={{
            padding: 0,
            includeHiddenNodes: false,
          }}
          proOptions={proOptions}
        >
          <MiniMap
            style={{ background: "var(--background_node)" }}
            maskColor="rgba(69, 69, 69, 0.7)"
            pannable={true}
            zoomable={true}
          />
          <Background
            style={{ background: "var(--background_panel)" }}
            size={3}
            color="#333333"
          />
          {/* <Controls /> */}
        </ReactFlow>
      </div>
    </div>
  );
}

export default FlowPanel;

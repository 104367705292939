import React, { useEffect } from "react";
import "./Trades.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllTrades } from "../../../../Redux/Home/HomeActions";

function Trades() {
  const dispatch = useDispatch();

  useEffect(() => {
    var botsMain = document.querySelector(".trades_main");

    if (botsMain.scrollHeight > botsMain.clientHeight) {
      botsMain.style.paddingRight = "10px"; // Adjust this value as per your needs
    }
    dispatch(getAllTrades());
    let a = setInterval(() => {
      dispatch(getAllTrades());
    }, 15000);
    return () => {
      clearInterval(a);
    };
  }, []);

  const data = [
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
    {
      symbol: "XAUUSD",
      ticket: "1233991",
      time: "2024.03.01 15:07:23",
      Type: "Buy",
      volume: "70",
      Price: "$2,122.50",
      tp: null,
      sl: null,
      currentPrice: "$2.140.00",
      profit: "+$5,780.21",
    },
  ];

  const { trades, getAllTradesLoading } = useSelector(
    (state) => state.HomeReducer
  );

  return (
    <div className="trades_main">
      <div className="trades_top">
        <div className="trades_title">Trades</div>
        <div className="trades_number">{trades?.length} Total Trades</div>
      </div>
      {trades?.length > 0 ? (
        <div className="table_continer">
          <table className="table_trades_main">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Ticket</th>
                <th>Time</th>
                <th>Type</th>
                <th>Volume</th>
                <th>Price</th>
                <th>TP</th>
                <th>SL</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {trades?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.symbol || "-"}</td>
                    <td>{item.ticket || "-"}</td>
                    <td>{new Date(item.time).toLocaleString() || "-"}</td>
                    <td>{item.type || "-"}</td>
                    <td>{item.volume || "-"}</td>
                    <td>{item.price || "-"}</td>
                    <td>{item.takeProfit || "-"}</td>
                    <td>{item.stopLoss || "-"}</td>
                    <td>
                      {item.profit.toLocaleString("currency", {
                        style: "currency",
                        currency: "USD",
                      }) || "-"}{" "}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="table_total">Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="table_total_number">
                  {trades
                    ?.reduce((sum, item) => sum + (item.profit || 0), 0)
                    .toLocaleString("currency", {
                      style: "currency",
                      currency: "USD",
                    }) || "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no_data">No Current Trades</div>
      )}
    </div>
  );
}

export default Trades;

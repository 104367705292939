import man from "../../Assets/Images/man.png";

export const SMAData = {
  type: "Indicator",
  desc: "The Simple Moving Average (SMA) is a trend indicator that calculates the average price of an asset over a specific number of periods to identify trends.",
  color: "rgb(239, 171, 255)",

  node: "SMA",
  label: "SMA Node",
  details:
    "Welcome to the SMA (Simple Moving Average) Node, your best friend for spotting trends in the market. The SMA is a straightforward yet powerful tool that smooths out price data, helping you identify the general direction of an asset over time. Think of it as your market compass, guiding you through the ups and downs of price movements.",

  img: man,

  inputs: [
    {
      name: "Asset:",
      desc: "Select the asset you want to analyze. The SMA Node will do the heavy lifting of calculating the average price for you.",
    },
    {
      name: "Timeframe:",
      desc: "Choose the timeframe for the data you want to import. Whether you’re interested in daily, weekly, or monthly trends, the SMA Node has you covered.",
    },
    {
      name: "Period (default: 20):",
      desc: "Set the number of periods for the SMA calculation. The default is 20, but you can adjust it to fit your trading strategy.",
    },
  ],

  outputs: [
    {
      name: "SMA Value - Live:",
      desc: "Get the latest SMA value, giving you the current average price over your chosen period.",
    },
    {
      name: "SMA Value - History:",
      desc: "An array of historical SMA values, perfect for trend analysis and backtesting.",
    },
    {
      name: "Trigger Above:",
      desc: "A signal generated when the price crosses above the SMA, indicating a potential upward trend.",
    },
    {
      name: "Trigger Below:",
      desc: "A signal generated when the price crosses below the SMA, indicating a potential downward trend.",
    },
  ],

  examples: [
    {
      name: "The SMA Node is like your market trend radar, constantly scanning price data to help you make informed trading decisions. Here’s how you can use it to stay on top of the market:",

      // Above SMA: When the price crosses above the SMA, it’s a signal that the asset might be entering an upward trend. Time to consider buying or going long.
      // Below SMA: When the price crosses below the SMA, it’s a signal that the asset might be entering a downward trend. Time to consider selling or going short.
      // Smooth Sailing: The SMA smooths out the noise in price data, giving you a clearer view of the overall trend.

      steps: [
        "Above SMA: When the price crosses above the SMA, it’s a signal that the asset might be entering an upward trend. Time to consider buying or going long.",
        "Below SMA: When the price crosses below the SMA, it’s a signal that the asset might be entering a downward trend. Time to consider selling or going short.",
        "Smooth Sailing: The SMA smooths out the noise in price data, giving you a clearer view of the overall trend.",
      ],
    },
    {
      name: "By incorporating the SMA Node into your trading strategy, you’ll be able to better understand market trends and make more informed decisions. Whether you’re a trend follower or just looking to get a clearer picture of price movements, the SMA Node is your trusty guide. Get ready to geek out on trend analysis and navigate the market like a pro!",
    },
  ],
};

import React, { FC, useState, useEffect } from "react";
import "./BooleanNode.scss";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import NodeBox from "../../Components/NodeBox/NodeBox";

function BooleanNode() {
  const [value, setValue] = useState();
  const [number, setNumber] = useState();
  const [valueDisabled, setValueDisabled] = useState(false);

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes } = useReactFlow();

  const [title, setTitle] = useState("Boolean Node");
  const { edges } = useSelector((state) => state.flow);
  const reduxNodes = useSelector((state) => state.flow.nodes);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);

  const nodeId = useNodeId();

  const node = getNode(nodeId);

  const dispatch = useDispatch();
  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //get the number from the connected node
  useEffect(() => {
    const foundNumber = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_bool_input/boolean"
    );
    if (foundNumber) {
      let numberTarget = foundNumber.target;
      let numberHandle = foundNumber.targetHandle;
      if (nodeData[numberTarget]) {
        {
          setValue(nodeData[numberTarget].data[numberHandle]);
          setNumber(nodeData[numberTarget].data[numberHandle]?.number);
        }
      }
      setValueDisabled(true);
    } else {
      setValueDisabled(false);
    }
  }, [connectedEdges, nodeData]);

  //to set data to the connected edges
  useEffect(() => {
    const findNumber = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_bool_output/boolean"
    );
    if (findNumber && findNumber.length > 0) {
      findNumber.map((el) => {
        let numberHandle = el.targetHandle;
        let numberTarget = el.target;

        dispatch(
          nodeDataUpdate(numberTarget, {
            [numberHandle]: value,
          })
        );
      });
    }
  }, [connectedEdges, value, number]);

  //setting the props
  useEffect(() => {
    var element = document.getElementById("value");
    var html = element?.outerHTML;
    dispatch(
      nodePropUpdate(nodeId, {
        html: {
          value: html,
        },
        hide: true,
        value: value,
        label: title,
      })
    );
  }, [value, title]);

  //getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.value != value) {
        setValue(nodeProps[nodeId].prop.value);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
    }
  }, [nodeProps]);

  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--dark-purple)"
      selected={node?.selected}
      body={
        <div className="booleanNode_bottom">
          <BooleanHandle
            type="target"
            id={nodeId + "_bool_input/boolean"}
            style={{ left: "-22px" }}
            isConnectable={!valueDisabled}
            required={true}
          />
          <div className="booleanNode_bottom_inner">
            <div
              className="title"
              style={{ color: value && "var(--handleGreen)" }}
            >
              {value ? "True" : "False"}
            </div>
            <div className="checkbox_label">
              <input
                id="value"
                className="input checkbox-label"
                type="checkbox"
                checked={value}
                onChange={() => setValue(!value)}
                disabled={valueDisabled}
              />
            </div>
          </div>

          <BooleanHandle
            type="source"
            id={nodeId + "_bool_output/boolean"}
            style={{ right: "-22px" }}
          />
        </div>
      }
    />
  );
}

export default BooleanNode;

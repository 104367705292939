import React, { FC, useCallback } from "react";
import "./ObjectHandle.scss";
import {
  OnConnect,
  Handle,
  Position,
  IsValidConnection,
  HandleType,
  Connection,
  getConnectedEdges,
  useNodeId,
  useStore,
} from "reactflow";

function ObjectHandle({
  id,
  type,
  isConnectable,
  onConnect,
  style,
  isValidConnection,
  required,
}) {
  return (
    <Handle
      isConnectable={isConnectable}
      isConnectableStart={type == "target" ? false : true}
      type={type}
      position={type == "target" ? Position.Left : Position.Right}
      id={`${id}`}
      style={style}
      className="object_handle"
      onConnect={onConnect}
      isValidConnection={(connection) => {
        return connection.targetHandle?.includes("/object") ?? false;
      }}
    >
      <div className="ObjectHandle_background_Div">
        <div
          className={
            required == true
              ? "ObjectHandle_triangleDivRequired"
              : "ObjectHandle_triangleDiv"
          }
        ></div>
      </div>
    </Handle>
  );
}

export default ObjectHandle;

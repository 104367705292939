import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import HomeReducer from "./Redux/Home/HomeReducer";
import FlowReducer from "./Redux/FlowPanel/FlowReducer";
import NodesReducer from "./Redux/Nodes/NodesReducer";
import AssetNodeReducer from "./Redux/AssetNode/AssetNodeReducer";
import RSINodeReducer from "./Redux/RSINode/RSINodeReducer";
import SMANodeReducer from "./Redux/SMANode/SMANodeReducer";
import BBNodeReducer from "./Redux/BBNode/BBNodeReducer";
import ATRNodeReducer from "./Redux/ATRNode/ATRNodeReducer";
import OrderNodeReducer from "./Redux/OrderNode/OrderNodeReducer";

const RootReducer = combineReducers({
  // Add reducers here
  HomeReducer: HomeReducer,
  flow: FlowReducer,
  nodes: NodesReducer,
  assetNodeReducer: AssetNodeReducer,
  RSINodeReducer: RSINodeReducer,
  smaNodeReducer: SMANodeReducer,
  BBNodeReducer: BBNodeReducer,
  OrderNodeReducer: OrderNodeReducer,
  ATRNodeReducer: ATRNodeReducer,
});

export const Store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: () => [thunk],
});

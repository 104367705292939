import React from "react";
import "./RadioButton.scss";
import { motion } from "framer-motion";

function RadioButton({ checked, setChecked }) {
  return (
    <motion.div
      initial={{
        backgroundColor: checked ? "#FFC6A254" : "#757575",
      }}
      animate={{
        backgroundColor: checked ? "#FFC6A254" : "#757575",
      }}
      className="radioButton_main"
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <motion.div
        initial={{
          x: !checked ? 0 : 15,
          opacity: !checked ? 1 : 0,
          filter: !checked ? "blur(0px)" : "blur(2px)",
        }}
        animate={{
          x: !checked ? 0 : 15,
          opacity: !checked ? 1 : 0,
          filter: !checked ? "blur(0px)" : "blur(2px)",
        }}
        className="first_circle"
      ></motion.div>
      <div className="break"></div>
      <motion.div
        initial={{
          x: checked ? 0 : -15,
          opacity: checked ? 1 : 0,
          filter: checked ? "blur(0px)" : "blur(2px)",
        }}
        animate={{
          x: checked ? 0 : -15,
          opacity: checked ? 1 : 0,
          filter: checked ? "blur(0px)" : "blur(2px)",
        }}
        className="first_circle second_circle"
      ></motion.div>
    </motion.div>
  );
}

export default RadioButton;

import React, { useState } from "react";
import "./HomePopup.scss";
import { motion } from "framer-motion";
import Popup from "../../../../Components/Popup/Popup";
import { useNavigate } from "react-router-dom";
import patch_node from "../../../../Assets/Images/patch_node.svg";
import order_node from "../../../../Assets/Images/order_node.svg";
import { useDispatch, useSelector } from "react-redux";
import { createBot } from "../../../../Redux/Home/HomeActions";
import Loader from "../../../../Components/Loader/Loader";

function HomePopup({ show, setShow }) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { createBotLoading } = useSelector((state) => state.HomeReducer);

  return (
    <Popup show={show} setShow={setShow}>
      <div className="popup_title">Create New</div>
      <div className="popup_sub_title">
        Select the type of nodes you would like to create. The project type
        cannot be modified after it has been created.
      </div>
      {createBotLoading ? (
        <div style={{ height: "10rem" }}>
          <Loader />
        </div>
      ) : (
        <motion.div className="popup_options">
          <motion.div
            className="left"
            animate={{
              backgroundColor: hover ? "var(--borderBlack)" : "var(--greyDark)",
            }}
            onHoverStart={() => {
              setHover(true);
            }}
            onHoverEnd={() => {
              setHover(false);
            }}
            onClick={() => {
              // navigate("/flowpanel");
              // setShow(!show);
              dispatch(createBot(navigate, setShow));
            }}
          >
            <motion.img
              animate={{
                userSelect: "none",
                pointerEvents: "none",
                scale: hover ? 1.1 : 1,
                transition: {
                  type: "spring",
                  stiffness: 600,
                  damping: 15,
                },
              }}
              src={order_node}
              alt="order_node"
              className="node_img"
            />
            <motion.div
              animate={{
                color: hover ? "var(--red)" : "white",
              }}
              className="option_title"
            >
              Trading Bot
            </motion.div>
            <div className="option_description">
              A trading bot allows you to build complicated logic based triggers
              to automate complex trading strategies
            </div>
          </motion.div>
          <div className="right">
            <div className="right_inner">
              <motion.img
                src={patch_node}
                alt="patch_node"
                className="node_img"
                initial={{
                  userSelect: "none",
                  pointerEvents: "none",
                  scale: 0.5,
                }}
              />
              <div className="option_title">Node Patch</div>
              <div className="option_description">
                A node patch allows you to build scalable, reusable and
                shareable node patches, that you can later on use in your own
                trading bots
              </div>
            </div>
            <div className="soon">Coming Soon</div>
          </div>
        </motion.div>
      )}
    </Popup>
  );
}

export default HomePopup;

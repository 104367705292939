import React, { useCallback, useEffect, useState } from "react";
import "./OrderNode.scss";
import { getConnectedEdges, useNodeId, useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import BooleanHandle from "../../Components/CustomHandles/BooleanHandle/BooleanHandle";
import NumberHandle from "../../Components/CustomHandles/NumberHandle/NumberHandle";
import DropDown from "../../Components/DropDown/DropDown";
import NodeBox from "../../Components/NodeBox/NodeBox";
import { nodeDataUpdate, nodePropUpdate } from "../../Redux/Nodes/NodesActions";
import { addOrders } from "../../Redux/FlowPanel/FlowActions";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import arrowDown from "../../Assets/Images/whiteArrowDown.svg";
import { getAllAssets } from "../../Redux/AssetNode/AssetNodeActions";
import ObjectHandle from "../../Components/CustomHandles/ObjectHandle/ObjectHandle";
import { executrOrder } from "../../Redux/OrderNode/OrderNodeActions";

function OrderNode({ data }) {
  const [lot, setLot] = useState();
  const [order, setOrder] = useState(false);
  const [boolDisabled, setBoolDisabled] = useState(false);
  const [lotDisabled, setLotDisabled] = useState(false);
  const [choosenOrder, setChoosenOrder] = useState("");
  const [title, setTitle] = useState("Execute");
  const [asset, setAsset] = useState("");
  const [assets, setAssets] = useState([]);
  const [tp, settp] = useState();
  const [sl, setsl] = useState();
  const [tpDisabled, setTpDisabled] = useState(false);
  const [slDisabled, setSlDisabled] = useState(false);
  const [execute, setExecute] = useState(false);
  const [assetDisabled, setAssetDisabled] = useState(false);
  useEffect(() => {
    dispatch(getAllAssets());
  }, []);

  const { allAssets } = useSelector((state) => state.assetNodeReducer);

  useEffect(() => {
    if (allAssets && allAssets.length > 0) {
      allAssets.map((el) => {
        setAssets((prev) => [...prev, { value: el.id, label: el.name }]);
      });
    }
  }, [allAssets]);

  useEffect(() => {
    if (data) {
      setLot(data?.lot);
      setChoosenOrder(data?.order);
    }
  }, [data]);

  const [connectedEdges, setConnectedEdges] = useState([]);

  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();

  const { edges } = useSelector((state) => state.flow);
  const { nodeData, nodeProps } = useSelector((state) => state.nodes);
  const reduxNodes = useSelector((state) => state.flow.nodes);

  const nodeId = useNodeId();

  const node = getNode(nodeId);
  const dispatch = useDispatch();

  //to get connected edges
  useEffect(() => {
    setConnectedEdges(getConnectedEdges([node], edges));
  }, [edges, reduxNodes]);

  //step 1 setting the props
  useEffect(() => {
    dispatch(
      nodePropUpdate(nodeId, {
        hide: true,
        lot: lot,
        asset: asset,
        order: choosenOrder,
        label: title,
        sl: sl,
        tp: tp,
        execute: execute,
      })
    );
  }, [choosenOrder, lot, title, asset, sl, tp, execute]);
  //step 2 getting the props
  useEffect(() => {
    if (nodeProps[nodeId] && Object.keys(nodeProps[nodeId]?.prop).length > 0) {
      if (nodeProps[nodeId]?.prop?.order != choosenOrder) {
        setChoosenOrder(nodeProps[nodeId].prop.order);
      }
      if (nodeProps[nodeId]?.prop?.asset != asset) {
        setAsset(nodeProps[nodeId].prop.asset);
      }
      if (nodeProps[nodeId]?.prop?.lot != lot) {
        setLot(nodeProps[nodeId].prop.lot);
      }
      if (nodeProps[nodeId]?.prop?.label != title) {
        setTitle(nodeProps[nodeId].prop.label);
      }
      if (nodeProps[nodeId]?.prop?.sl != sl) {
        setsl(nodeProps[nodeId].prop.sl);
      }
      if (nodeProps[nodeId]?.prop?.tp != tp) {
        settp(nodeProps[nodeId].prop.tp);
      }
      if (nodeProps[nodeId]?.prop?.execute != execute) {
        setExecute(nodeProps[nodeId].prop.execute);
      }
    }
  }, [nodeProps]);

  //step3 getting data
  useEffect(() => {
    const found = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_order_input_order/boolean"
    );
    if (found) {
      let Target = found.target;
      let Handle = found.targetHandle;
      if (nodeData?.[Target]?.data?.[Handle] != execute) {
        console.log(nodeData?.[Target]?.data?.[Handle]);
        setExecute(nodeData?.[Target]?.data?.[Handle]);
      }
      setBoolDisabled(true);
    } else {
      setBoolDisabled(false);
    }

    const found2 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_order_input_lot/int"
    );
    if (found2) {
      let Target = found2.target;
      let Handle = found2.targetHandle;
      if (nodeData[Target] && nodeData[Target]?.data[Handle] != lot) {
        setLot(nodeData[Target].data[Handle]);
      }
      setLotDisabled(true);
    } else {
      setLotDisabled(false);
    }

    const found3 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_order_input_asset/object"
    );
    if (found3) {
      let Target = found3.target;
      let Handle = found3.targetHandle;
      if (nodeData[Target] && nodeData[Target].data[Handle] != asset) {
        setAsset(nodeData[Target].data[Handle]);
      }
      setAssetDisabled(true);
    } else {
      setAssetDisabled(false);
    }

    const found4 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_order_input_tp/number"
    );
    if (found4) {
      let Target = found4.target;
      let Handle = found4.targetHandle;
      if (nodeData[Target] && nodeData[Target].data[Handle] != tp) {
        settp(nodeData[Target].data[Handle]);
      }
      setTpDisabled(true);
    } else {
      setTpDisabled(false);
    }

    const found5 = connectedEdges.find(
      (el) => el.targetHandle === nodeId + "_order_input_sl/number"
    );
    if (found5) {
      let Target = found5.target;
      let Handle = found5.targetHandle;

      if (nodeData[Target] && nodeData[Target].data[Handle] != sl) {
        setsl(nodeData[Target].data[Handle]);
      }
      setSlDisabled(true);
    } else {
      setSlDisabled(false);
    }
  }, [connectedEdges, nodeData, sl, tp, lot, order, asset]);

  //step 4 set data to connected nodes
  useEffect(() => {
    const findPeriod = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_order_output_tp/number"
    );
    if (findPeriod && findPeriod.length > 0) {
      findPeriod.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: tp,
          })
        );
      });
    }

    const findPeriod2 = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_order_output_sl/number"
    );
    if (findPeriod2 && findPeriod2.length > 0) {
      findPeriod2.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: sl,
          })
        );
      });
    }

    const findPeriod3 = connectedEdges.filter(
      (edge) => edge.sourceHandle === nodeId + "_order_output_lot/int"
    );
    if (findPeriod3 && findPeriod3.length > 0) {
      findPeriod3.map((el) => {
        let PeriodHandle = el.targetHandle;
        let PeriodTarget = el.target;

        dispatch(
          nodeDataUpdate(PeriodTarget, {
            [PeriodHandle]: lot,
          })
        );
      });
    }
  }, [connectedEdges, sl, tp, lot]);

  useEffect(() => {
    if (execute && asset && lot && choosenOrder) {
      dispatch(
        executrOrder({
          symbol: assets.filter((el) => el.value == asset)[0].label,
          volume: lot,
          actionType: choosenOrder,
          stopLoss: sl,
          takeProfit: tp,
        })
      );
    }
  }, [execute, asset, lot, choosenOrder, sl, tp]);
  return (
    <NodeBox
      title={title}
      setTitle={setTitle}
      color="var(--yellow)"
      selected={node?.selected}
      middle={
        <BooleanHandle
          id={nodeId + "_order_input_order/boolean"}
          type={"target"}
          style={{
            left: "-7px",
            top: "30px",
          }}
          isConnectable={!boolDisabled}
          required={execute}
        />
      }
      body={
        <div className="orderNode_main_body">
          <div className="top">
            <ObjectHandle
              id={nodeId + "_order_input_asset/object"}
              type={"target"}
              style={{ left: "-25px" }}
              isConnectable={!assetDisabled}
            />
            <div className="inner">
              <div className="innerDivImgP">
                <p className="innerDivP">Asset</p>
              </div>
              <DropDown
                id="order"
                options={assets}
                disabled={assetDisabled}
                value={asset}
                onChange={(e) => setAsset(e.target.value)}
                style={{
                  width: "5rem",
                  padding: "0.2rem 0.1rem",
                  color: "var(--text-color2)",
                  fontSize: "0.8rem",
                  textAlign: "center",
                  border: "none",
                  borderRadius: "5px",
                  outline: "none",
                  background: "var(--background_input)",
                }}
              />
            </div>
          </div>
          <div
            className="top"
            style={{
              marginTop: "1rem",
            }}
          >
            <NumberHandle
              id={nodeId + "_order_input_lot/int"}
              type={"target"}
              style={{ left: "-25px" }}
              isConnectable={!lotDisabled}
            />
            <div className="inner">
              <NumberHandle
                id={nodeId + "_order_output_lot/int"}
                type={"source"}
                style={{ right: "-25px" }}
                isConnectable={true}
              />
              <div className="innerDivImgP">
                <p className="innerDivP">Lot Size</p>
              </div>
              <input
                id="period"
                type="number"
                className={
                  lotDisabled
                    ? "input_field input_field_disabled"
                    : "input_field"
                }
                value={lot}
                disabled={lotDisabled}
                onChange={(e) => setLot(e.target.value)}
              />
            </div>
          </div>
          <div
            className="top"
            style={{
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            <div className="inner">
              <div className="innerDivImgP">
                <p className="innerDivP">Type</p>
              </div>
              <DropDown
                id="order"
                options={[
                  { value: "ORDER_TYPE_SELL", label: "Sell" },
                  { value: "ORDER_TYPE_BUY", label: "Buy" },
                ]}
                value={choosenOrder}
                onChange={(e) => setChoosenOrder(e.target.value)}
                style={{
                  width: "5rem",
                  padding: "0.2rem 0.1rem",
                  color: "var(--text-color2)",
                  fontSize: "0.8rem",

                  textAlign: "center",
                  border: "none",
                  borderRadius: "5px",
                  outline: "none",
                  background: "var(--background_input)",
                }}
              />
            </div>
          </div>

          <div className="bottom">
            <div className="border_Div" style={{ marginLeft: "0.28rem" }}></div>

            <NumberHandle
              id={nodeId + "_order_input_tp/number"}
              type={"target"}
              style={{ left: "-25px", top: "40px" }}
              isConnectable={!tpDisabled}
            />
            <div className="inner">
              <div className="inner_lot">TP</div>
              <NumberHandle
                id={nodeId + "_order_output_tp/number"}
                type={"source"}
                style={{ right: "-25px", top: "35px" }}
                isConnectable={true}
              />
              <input
                id="lot"
                className={
                  tpDisabled
                    ? "input_field input_field_disabled"
                    : "input_field"
                }
                value={tp}
                disabled={tpDisabled}
                onChange={(e) => {
                  if (e.target.value >= 0) settp(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="bottom">
            <div
              className="border_Div"
              style={{ marginLeft: "0.28rem", marginTop: "-0.5rem" }}
            ></div>

            <NumberHandle
              id={nodeId + "_order_input_sl/number"}
              type={"target"}
              style={{ left: "-25px", top: "30px" }}
              isConnectable={!sl}
            />

            <div className="inner">
              <div className="inner_lot">SL</div>
              <NumberHandle
                id={nodeId + "_order_output_sl/number"}
                type={"source"}
                style={{ right: "-25px", top: "30px" }}
                isConnectable={true}
              />
              <input
                id="lot"
                className={
                  slDisabled
                    ? "input_field input_field_disabled"
                    : "input_field"
                }
                value={sl}
                disabled={slDisabled}
                onChange={(e) => {
                  if (e.target.value >= 0) setsl(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default OrderNode;
